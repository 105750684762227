<template>
	<b-modal
		v-model="open"
		:title="FormMSG(1, 'Send to payroll option')"
		header-class="header-class-modal-doc-package"
		hide-header-close
		no-close-on-backdrop
		no-close-on-esc
		@ok.prevent="handleSave"
		@cancel.prevent="emitEventClose"
		size="lg"
		dialog-class="dialog-custom-payroll-config"
		modal-class="mui-animation"
		:fade="false"
	>
		<div class="d-flex flex-column flex-1 w-100 gap-25">
			<div class="d-flex flex-row">
				<div class="w-50">
					<b-form-radio v-model="typeContract" :value="0" name="type-contract"> {{ FormMSG(7, 'Long contract') }} </b-form-radio>
				</div>
				<div class="w-50">
					<b-form-radio v-model="typeContract" :value="1" name="type-contract"> {{ FormMSG(5, 'Short contract') }} </b-form-radio>
				</div>
			</div>
			<div class="d-flex flex-row flex-1 w-100">
				<div class="w-50 check-disabled">
					<b-form-checkbox
						id="lock-planning"
						v-model="lockPlanning"
						name="lock-planning"
						:value="true"
						:unchecked-value="false"
						:disabled="isActiveLockPlanning"
						:class="{ 'disabled-checkbox': isActiveLockPlanning }"
					>
						{{ FormMSG(6, 'Lock planning until:') }}
					</b-form-checkbox>
				</div>
				<div class="w-50">
					<v-date-picker
						v-model="payrollDate"
						:available-dates="{
							start: new Date(isDatePlanning),
							end: null
						}"
						:locale="lang"
						:masks="masks"
						@dayclick="handleChangeDate"
						:model-config="{ type: 'string', mask: masks, timezone: 'UTC' }"
					>
						<template v-slot="{ inputValue, togglePopover }">
							<b-input-group>
								<b-form-input :value="inputValue" class="br-none" readonly />
								<b-input-group-append>
									<b-input-group-text class="cursor-pointer bg-color-white input-group-text-bg-white" @click="togglePopover()">
										<component :is="getLucideIcon('Calendar')" color="rgba(6, 38, 62, .6)" :size="18" :stroke-width="2.25" />
									</b-input-group-text>
								</b-input-group-append>
							</b-input-group>
						</template>
					</v-date-picker>
				</div>
			</div>
		</div>

		<template #modal-footer>
			<div class="w-100 d-flex justify-content-end align-items-center">
				<b-button size="md" variant="custom-outline-gray" style="margin-top: 5px" class="w-138-px mr-3" @click="closeModalPayrollConfig" block>
					{{ FormMSG(2, 'Cancel') }}
				</b-button>
				<b-button size="md" variant="primary" class="w-138-px" @click="handleSave" block>
					<div class="d-flex justify-content-center align-items-center">
						<div style="margin-top: 1px">{{ FormMSG(3, 'Send') }}</div>
					</div>
				</b-button>
			</div>
		</template>

		<b-modal
			title="Information"
			class="modal-danger"
			v-model="openModalInfoPayroll"
			@cancel.prevent="closeInfoModalPayroll"
			no-close-on-backdrop
			ok-variant="danger"
			ok-only
			modal-class="mui-animation"
			:fade="false"
		>
			<div v-html="info"></div>
			<template #modal-footer>
				<div class="w-100">
					<b-button @click="closeInfoModalPayroll" size="sm" class="float-right"> OK </b-button>
				</div>
			</template>
		</b-modal>
	</b-modal>
</template>

<script>
import languageMessages from '@/mixins/languageMessages';
import globalMixin from '@/mixins/global.mixin';
import DatePicker from 'v-calendar/lib/components/date-picker.umd';
import { exportTsDayDetails } from '@/cruds/timesheet.crud';
import { formatDatesInString, STATUS_VALIDATE_PLANNING } from '@/shared/constants';

export default {
	name: 'PayrollConfig',
	props: {
		open: { type: Boolean, default: false, required: false },
		dateDefaultSelect: {
			type: Object,
			required: false
		},
		planningOption: {
			type: Object || null,
			required: false,
			default: () => []
		},
		statusPlanningValidateAll: {
			type: Number,
			required: false
		}
	},

	mixins: [languageMessages, globalMixin],

	components: {
		'v-date-picker': DatePicker
	},

	data() {
		return {
			payrollDate: new Date(),
			lockPlanning: false,
			typeContract: 0,
			masks: {
				input: 'DD/MM/YYYY'
			},
			openModalInfoPayroll: false,
			info: ''
		};
	},

	computed: {
		isDatePlanning() {
			if (this.dateDefaultSelect) {
				return formatDatesInString('01/' + this.dateDefaultSelect.value, 'YYYY-MM-DD');
			}
			return formatDatesInString(new Date(), 'YYYY-MM-DD');
		},
		isActiveLockPlanning() {
			const status = this.statusPlanningValidateAll;
			if (status) {
				const { CLOSED_PAYROLL } = STATUS_VALIDATE_PLANNING;
				const checkED = [CLOSED_PAYROLL].includes(status);
				return !checkED;
			}
			return true;
		}
	},

	watch: {
		dateDefaultSelect: {
			handler(newVal) {
				if (newVal) {
					const [month, year] = this.dateDefaultSelect.value.split('/');
					const lastDay = `${new Date(year, month, 0).getDate()}`.padStart(2, '0');
					this.payrollDate = formatDatesInString(`${lastDay}/` + this.dateDefaultSelect.value, 'YYYY-MM-DD');
					return newVal;
				}
				return newVal;
			},
			immediate: true,
			deep: true
		}
	},

	async created() {},

	methods: {
		closeInfoModalPayroll() {
			this.openModalInfoPayroll = false;
			this.emitEventClose();
		},
		handleChangeDate(payload) {
			this.payrollDate = payload.date;
		},
		async handleSave() {
			const date = formatDatesInString(this.payrollDate, 'YYYY-MM-DD') + 'T00:00:00Z';
			const typeContract = this.typeContract;
			const lockPlanning = this.lockPlanning;
			const result = await exportTsDayDetails(date, typeContract, lockPlanning);
			if (result) {
				this.openModalInfoPayroll = true;
				this.info = result;
				if (this.info.toString().includes('<head>')) {
					this.info = `<div>${this.FormMSG(8, 'Send to payroll with success')}</div>`;
				}
			} else {
				this.$bvToast.toast('Error to send', {
					title: 'Error',
					variant: 'error',
					solid: true,
					toaster: 'b-toaster-top-center',
					autoHideDelay: 3000
				});
			}
		},
		emitEventClose() {
			this.$emit('actionModal');
			this.$emit('is-success-to-refresh');
			this.info = '';
		},
		closeModalPayrollConfig() {
			this.openModalInfoPayroll = false;
			this.$emit('actionModal');
			this.info = '';
		}
	}
};
</script>
<style scoped lang="scss">
.dialog-custom-payroll-config {
	width: 40svw !important;
}
.gap-25 {
	gap: 25px;
}
</style>
<style lang="scss">
.check-disabled {
	.disabled-checkbox .custom-control-input:disabled ~ .custom-control-label::before {
		color: #fff !important;
		background: #a1acbb !important;
		cursor: pointer !important;
	}
}
</style>
