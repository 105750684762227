<template>
	<b-modal
		v-model="isOpen"
		:title="FormMSG(173, 'Manage users\'s roles')"
		header-class="header-class-modal-doc-package"
		size="xl"
		hide-header-close
		no-close-on-backdrop
		no-close-on-esc
		@ok.prevent="onSubmitted"
		@show="handleShow"
		@cancel="emitEventClose"
		modal-class="mui-animation"
		:fade="false"
	>
		<div style="min-height: 150px">
			<b-row class="mb-2">
				<b-col>
					<b-input-group>
						<b-form-input
							id="filterInput-1"
							type="text"
							v-model="filter"
							:placeholder="FormMSG(65, 'Type to search')"
							@keyup.enter="handleKeyUpEnter"
						/>
						<b-input-group-append class="cursor-pointer">
							<b-input-group-text class="btn-search">
								<component :is="getLucideIcon('Search')" color="#FFFFFF" :size="16" :stroke-width="2.5" v-if="filter.length === 0" />
								<component :is="getLucideIcon('X')" color="#FFFFFF" :size="16" :stroke-width="2.5" @click="resetFilter" v-else />
							</b-input-group-text>
						</b-input-group-append>
					</b-input-group>
					<b-tooltip target="filterInput-1" placement="top" triggers="focus">
						{{ this.FormMSG(1, 'You must type more than 3 characters followed by enter to activate the search') }}
					</b-tooltip>
				</b-col>
			</b-row>

			<b-row>
				<b-col id="containerManageUsersRoles" ref="containerManageUsersRoles">
					<b-table
						v-if="$screen.width >= 992"
						selected-variant="primary"
						hover
						selectable
						select-mode="single"
						responsive="sm"
						ref="preferencesTable"
						id="preferencesTable"
						sticky-header="500px"
						:items="mapList"
						style="text-align: left"
						:fields="mapFields"
						bordered
						striped
						small
						head-variant="dark"
						:empty-text="FormMSG(250, 'No data found')"
						show-empty
					>
						<template #cell(action)="{ item, index }">
							<div>
								<b-form-select v-model="item.role" :options="rolesOptions" @change="handleChangeAction($event, item, index)" />
							</div>
						</template>
					</b-table>
				</b-col>
			</b-row>
		</div>

		<template #modal-footer="{ ok, cancel }">
			<div class="w-100 d-flex justify-content-end align-items-center">
				<b-button size="md" variant="custom-outline-gray" style="margin-top: 5px" class="w-138-px mr-3" :disabled="loadingSubmit" @click="cancel" block>
					{{ FormMSG(43, 'Cancel') }}
				</b-button>
				<b-button size="md" variant="primary" class="w-138-px" :disabled="checkForm" @click="ok" block>
					<div class="d-flex justify-content-center align-items-center">
						<b-spinner v-if="loadingSubmit" small />
						<div :class="`${loadingSubmit ? 'pl-2' : ''}`" style="margin-top: 1px">{{ FormMSG(42, 'Save') }}</div>
					</div>
				</b-button>
			</div>
		</template>
	</b-modal>
</template>

<script>
import languageMessages from '@/mixins/languageMessages';
import globalMixin from '@/mixins/global.mixin';
import { getUsersForManageRole } from '@/cruds/users.crud';
import { hasUnsubmittedTsdayAndNoManager } from '@/cruds/fimalac.crud';
import { updateUserEfcRole } from '@/cruds/timesheets-efc.crud';

export default {
	name: 'ManageUsersRoles',

	props: {
		open: {
			type: Boolean,
			default: false,
			required: false
		},
		refreshModal: {
			type: Boolean,
			default: false,
			required: false
		}
	},

	mixins: [languageMessages, globalMixin],

	data() {
		return {
			loadingSubmit: false,
			activeShowingAction: false,
			dataList: [],
			globalDataList: [],
			filter: '',
			userSelected: [],
			rolesToRemove: [],
			alreadyLastData: false,
			recordLimit: 20,
			recordOffset: 0,
			choiceRoleToShow: null,
			globalAllUsers: []
		};
	},

	watch: {
		isOpen: {
			handler(newVal) {
				if (newVal) {
					this.getUsers();
				}
				return newVal;
			},
			immediate: true,
			deep: true
		},
		refreshModal: {
			async handler(newVal) {
				if (newVal === true) {
					await this.getUsers();

					this.$emit('manage-users-roles:refresh-end');
				}
			},
			deep: true
		},
		filter: {
			handler(newVal) {
				if (newVal.toString().trim().length <= 2) {
					this.dataList = this.globalDataList;
					this.globalAllUsers = this.globaDataListManger;
				}
				return newVal;
			},
			immediate: true,
			deep: true
		}
	},

	computed: {
		mapList() {
			return this.generateList(this.dataList);
		},
		isOpen: {
			get() {
				return this.open;
			},
			set(val) {
				return val;
			}
		},
		rolesOptions() {
			return [
				{ value: -1, text: this.FormMSG(616, 'No role') },
				{ value: 0, text: this.FormMSG(617, 'Regissor') }
			];
		},
		mapFields() {
			let fields = [
				{
					key: 'fullName',
					label: this.FormMSG(48, 'Name'),
					formatter: (value, key, item) => {
						return `${item.name} ${item.firstName}`;
					},
					class: 'text-left w-140-px',
					sortable: true
				},
				{
					key: 'functionName',
					label: this.FormMSG(94, 'Function'),
					class: 'text-left w-40',
					formatter: (value, key, item) => {
						return `${item.departmentName} - ${item.functionName}`;
					},
					sortable: true
				},
				{
					key: 'action',
					label: 'Role',
					class: 'text-center w-300-px',
					sortable: true
				}
			];
			return fields;
		},
		checkForm() {
			if (this.activeShowingAction) {
				return false;
			} else {
				const checked = this.loadingSubmit || (this.userSelected.length === 0 && this.rolesToRemove.length === 0);
				return checked;
			}
		}
	},

	methods: {
		onChangeTab(key) {
			for (let item in this.TAB) {
				if (item === key) {
					this.TAB[key] = true;
				} else {
					this.TAB[item] = false;
				}
			}
		},
		setFilterName(field, payload) {
			let obj = {};
			obj[field] = payload;
			return obj;
		},
		handleChangeShowing(payload, item, index, field) {
			this.activeShowingAction = true;
			this.dataList[index] = {
				...this.dataList[index],
				...this.setFilterName(field, payload)
			};
		},
		getUniqueCombination(data) {
			let uniqueCombinations = new Set();
			let uniqueData = data.filter((item) => {
				const { id, department, firstName, fullName, function: functionValue, functionName } = item;
				let combination = `${id}-${department}-${firstName}-${fullName}-${functionValue}-${functionName}`;
				if (!uniqueCombinations.has(combination)) {
					uniqueCombinations.add(combination);
					return true;
				}
				return false;
			});
			return uniqueData;
		},
		generateList(dataList) {
			return dataList;
		},
		handleChangeAction(payload, item, index) {
			this.deleteUserSelected(item);
			if (payload === 0) {
				if (item.oldRole === 1) {
					this.rolesToRemove.push({
						userId: item.id,
						oldRole: item.oldRole
					});
				}
				this.userSelected.push({
					userId: item.id,
					isProd: false,
					isRegissor: true
				});
			} else if (payload === -1) {
				if (item.oldRole === 0 || item.oldRole === 1) {
					this.rolesToRemove.push({
						userId: item.id,
						oldRole: item.oldRole
					});
				}
			}
		},
		deleteUserSelected(item, payload) {
			const findIndex = this.userSelected.findIndex((option) => +option.userId === +item.id);
			if (findIndex > -1) {
				this.userSelected.splice(findIndex, 1);
			}
		},
		generateListRole(data) {
			if (Array.isArray(data)) {
				return data.map((option) => ({
					...option,
					id: +option.id,
					oldRole: option.role
				}));
			}
			return [];
		},
		async resetFilter() {
			const actionForLoader = async () => {
				this.recordOffset = 0;
				this.recordLimit = 20;
				this.filter = '';
				this.alreadyLastData = false;
				this.userSelected = [];
				this.rolesToRemove = [];
				const responseLimit = this.fromDB([...(await getUsersForManageRole(this.recordLimit, this.recordOffset, this.filter, true, true))]);
				const responseGlobal = this.fromDB([...(await getUsersForManageRole(null, null, null, true, true))]);
				this.mapInitSalary(responseLimit, 'dataList', false);
				this.mapInitSalary(responseGlobal, 'globalAllUsers', false);
			};
			await actionForLoader();
		},
		fromDB(data) {
			return data.map((item) => ({ ...item, fromDB: true }));
		},
		async handleKeyUpEnter() {
			if (this.filter.toString().trim().length > 2) {
				const actionForLoader = async () => {
					this.recordOffset = null;
					this.recordLimit = null;
					this.alreadyLastData = false;
					this.userSelected = [];
					this.rolesToRemove = [];
					this.dataList = [];
					this.globalAllUsers = [];
					const responseLimit = this.fromDB([...(await getUsersForManageRole(this.recordLimit, this.recordOffset, this.filter, true, true))]);
					const responseGlobal = this.fromDB([...(await getUsersForManageRole(null, null, this.filter, true, true))]);
					this.mapInitSalary(responseLimit, 'dataList', true);
					this.mapInitSalary(responseGlobal, 'globalAllUsers', true);
				};
				await actionForLoader();
			}
		},
		async handleShow() {
			setTimeout(() => {
				const element = document.querySelector('#containerManageUsersRoles .b-table-sticky-header');

				element.addEventListener('scroll', async () => {
					const scrollTop = element.scrollTop;
					const scrollHeight = element.scrollHeight;
					const clientHeight = element.clientHeight;

					if (scrollTop + clientHeight >= scrollHeight) {
						if (this.alreadyLastData === false) {
							this.recordOffset += 1;
							await this.getUsers(true);
						}
					}
				});
			}, 1000);
		},
		mapInitSalary(data, stateName, onSearch = false) {
			if (onSearch) {
				if (Array.isArray(data)) {
					const _data = data.map((option) => {
						return {
							...option,
							id: +option.id,
							oldRole: option.role
						};
					});
					this[`${stateName}`] = _data;
					return this[`${stateName}`];
				}
				return this[`${stateName}`];
			} else {
				if (Array.isArray(data)) {
					return data.map((option) => {
						if (this[`${stateName}`] !== undefined) {
							this[`${stateName}`].push({
								...option,
								id: +option.id,
								oldRole: option.role
							});
						}
					});
				}
				return this[`${stateName}`];
			}
		},
		async getUsers(toPush = false, executeLoader = true) {
			const actionForLoader = async () => {
				if (toPush === false) {
					this.userSelected = [];
					this.rolesToRemove = [];
					this.dataList = [];
					this.globalAllUsers = [];
					const responseLimit = this.fromDB([...(await getUsersForManageRole(this.recordLimit, this.recordOffset, this.filter, true, true))]);
					const responseGlobal = this.fromDB([...(await getUsersForManageRole(null, null, null, true, true))]);
					this.mapInitSalary(responseLimit, 'dataList');
					this.mapInitSalary(responseGlobal, 'globalAllUsers');
				} else {
					const responseLimit = await getUsersForManageRole(this.recordLimit, this.recordOffset, this.filter, true, true);
					if (responseLimit.length > 0) {
						this.mapInitSalary(responseLimit, 'dataList');
					} else {
						this.alreadyLastData = true;
					}
				}
				this.globalDataList = this.dataList;
				this.globaDataListManger = this.globalAllUsers;
			};

			if (executeLoader === true) {
				await actionForLoader();
			} else {
				await actionForLoader();
			}
		},
		async onActionSubmitted(resultPrepareData, isExistActionManager) {
			if (resultPrepareData) {
				this.loadingSubmit = true;
				if (resultPrepareData.deleteValue.length > 0) {
					await updateUserEfcRole(resultPrepareData.deleteValue, false, false, isExistActionManager);
				}
				if (resultPrepareData.prod.length > 0) {
					await updateUserEfcRole(resultPrepareData.prod, false, true);
				}
				if (resultPrepareData.regissor.length > 0) {
					await updateUserEfcRole(resultPrepareData.regissor, true, false);
				}
				this.createToastForMobile(this.FormMSG(847, 'Success'), this.FormMSG(84, 'The modification was well done'));
				this.loadingSubmit = false;
				this.emitEventClose();
				return true;
			}
			return false;
		},
		async onSubmitted() {
			this.loadingSubmit = true;
			const resultPrepareData = this.prepareDataToSend();
			let isExistActionManager = false;
			if (resultPrepareData.deleteValue.length > 0) {
				const userIds = resultPrepareData.deleteValue;
				isExistActionManager = await hasUnsubmittedTsdayAndNoManager(userIds);
			}
			if (isExistActionManager) {
				this.loadingSubmit = false;
				let message = this.FormMSG(20, 'Attention, do you want to delete the non-sumbitted tsDays, because there are no more managers');
				let result = this.$bvModal
					.msgBoxConfirm([message], {
						title: 'Warning',
						size: 'sm',
						buttonSize: 'sm',
						okVariant: 'danger',
						cancelVariant: 'primary',
						okTitle: this.FormMSG(21, 'YES'),
						cancelTitle: this.FormMSG(22, 'NO'),
						footerClass: 'p-2',
						hideHeaderClose: false,
						centered: false,
						modalClass: 'mui-animation'
					})
					.then(async (v) => {
						if (!v) return;
						else {
							console.log('chacked: ', isExistActionManager);
							return this.onActionSubmitted(resultPrepareData, isExistActionManager);
						}
					})
					.catch(() => {
						return false;
					});
				return result;
			}
			return this.onActionSubmitted(resultPrepareData, isExistActionManager);
		},
		prepareDataToSend() {
			let result = {
				prod: [],
				regissor: [],
				deleteValue: []
			};

			this.userSelected.map((option) => {
				if (option.isProd === true) {
					result.prod.push(option.userId);
				} else if (option.isRegissor === true) {
					result.regissor.push(option.userId);
				}
			});

			this.rolesToRemove.map((option) => {
				result.deleteValue.push(option.userId);
			});

			return result;
		},
		emitEventClose() {
			this.userSelected = [];
			this.rolesToRemove = [];
			this.alreadyLastData = false;
			this.recordLimit = 20;
			this.recordOffset = 0;
			this.getUsers(false, false);
			this.activeShowingAction = false;

			this.$emit('manage-users-roles:close');
			this.$emit('set-exist-regissor:event');
		}
	}
};
</script>
