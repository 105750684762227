<template>
	<b-modal
		v-model="open"
		:title="FormMSG(1, 'Information') + ': ' + formattedDate(date)"
		size="md"
		hide-header-close
		no-close-on-backdrop
		no-close-on-esc
		@cancel.prevent="emitEventClose"
		@hidden="emitEventClose"
		modal-class="mui-animation"
		:fade="false"
	>
		<div class="d-flex flex-column gap-1">
			<b-alert show variant="warning" dismissible>{{ FormMSG(3, 'Some days are incomplete and need to be finalized') }}</b-alert>
			<div
				v-if="groupByData.length !== 0"
				class="d-flex flex-row align-items-center w-100 border-rd p-2"
				v-for="(item, index) of groupByData"
				:key="index"
			>
				<div class="w-50">
					<label>{{ item.fullName }}</label>
				</div>
				<div class="d-flex flex-column border-lf w-50">
					<div class="text-1" v-for="(children, index) of item.data" :key="index">{{ formatDatePlanning(children.date) }}</div>
				</div>
			</div>
		</div>
		<template #modal-footer>
			<div class="w-100 d-flex justify-content-end align-items-center">
				<b-button size="sm" variant="light" class="d-flex w-auto w-138-px" @click="emitEventClose" block>
					<div class="d-flex justify-content-end align-items-center">
						<div style="margin-top: 1px">{{ FormMSG(2, 'Close') }}</div>
					</div>
				</b-button>
			</div>
		</template>
	</b-modal>
</template>

<script>
import languageMessages from '@/mixins/languageMessages';
import globalMixin from '@/mixins/global.mixin';
import moment from 'moment';

export default {
	name: 'Incomplete',

	props: {
		open: { type: Boolean, default: false, required: false },
		data: {
			type: Array,
			default: () => [],
			required: false
		},
		date: {
			type: Object,
			default: () => ({}),
			required: false
		}
	},

	mixins: [languageMessages, globalMixin],

	components: {},

	data() {
		return {};
	},

	computed: {
		groupByData() {
			let result = this.data.reduce((accumulator, current) => {
				let existingItem = accumulator.find((item) => +item.userId === +current.user.id);
				if (existingItem) {
					existingItem.data.push(current);
				} else {
					accumulator.push({
						data: [current],
						userId: +current.user.id,
						fullName: current.user.name
					});
				}
				return accumulator;
			}, []);
			return result.sort((a, b) => a.userId - b.userId);
		}
	},

	watch: {},

	async created() {},

	methods: {
		formatDatePlanning(date) {
			if (date !== undefined) {
				const langCurrent = this.currentLang;
				return moment(date).locale(`${langCurrent}`).format('DD dddd YYYY');
			}
		},
		emitEventClose() {
			this.$emit('modal-incomplete:close');
		},
		formattedDate(date) {
			let value = '01/' + date.value;
			const langCurrent = this.currentLang;
			let _date = moment(value, 'DD/MM/YYYY').locale(langCurrent).format('MMMM YYYY');
			return _date;
		}
	}
};
</script>
<style scoped lang="scss">
.border-rd {
	border: 2px solid #dfdfdf;
	border-radius: 3px;
}
.border-lf {
	border-left: 2px solid #dfdfdf;
	padding: 0px 10px;
}
.gap-1 {
	gap: 10px;
}
</style>
