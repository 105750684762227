<template>
	<b-modal
		v-model="isOpen"
		:title="titleCaption"
		header-class="header-class-modal-doc-package"
		size="md"
		hide-header-close
		no-close-on-backdrop
		no-close-on-esc
		@cancel="emitEventClose"
		@show="handleShow"
		@ok.prevent="emitEventClose"
		modal-class="mui-animation"
		:fade="false"
	>
		<b-collapse :visible="addDecor === false">
			<b-row>
				<b-col>
					<b-form-group :label="FormMSG(215, 'Decors')">
						<div class="d-flex">
							<v-select
								id="supplier-selector"
								v-model="locationIdSelected"
								:options="locationList"
								close-on-select
								label="setName"
								:reduce="(option) => option.id"
								:placeholder="FormMSG(452, 'Add or select a decor')"
								class="grow-flex-auto"
								:clearable="false"
							>
							</v-select>
							<div class="d-flex flex-row icon-group-border-append">
								<div
									:style="`width: 34px`"
									class="cursor-pointer d-inline-flex justify-content-center align-items-center"
									v-b-tooltip.hover.top
									:title="FormMSG(157, 'Add new decor')"
									@click="handleClickAddNewDecor"
								>
									<component :is="getLucideIcon('PlusCircle')" color="#00A09C" :size="18" :stroke-width="2.25" />
								</div>
							</div>
						</div>
					</b-form-group>
				</b-col>
			</b-row>
		</b-collapse>
		<b-collapse :visible="addDecor === true">
			<b-form-group :label="FormMSG(198, 'Name of decor')" :state="!$v.decorName.$error">
				<div class="d-flex flex-row w-100">
					<div class="d-flex flex-grow-1 flex-column">
						<div class="d-flex flex-row">
							<b-form-input v-model="$v.decorName.$model" :placeholder="FormMSG(199, 'Enter name')" :state="!$v.decorName.$error" />
							<div class="d-flex flex-row flex-grow-0">
								<div
									:style="`width: 34px`"
									class="cursor-pointer d-inline-flex justify-content-center align-items-center"
									v-b-tooltip.hover.top
									:title="FormMSG(137, 'Add from list')"
									@click="handleClickAddNewDecor"
								>
									<component :is="getLucideIcon('Undo2')" color="#00A09C" :size="20" :stroke-width="2.25" />
								</div>
							</div>
						</div>
						<div v-if="$v.decorName.$error" class="invalid-feedback">
							{{ FormMSG(487, 'Decor name is required') }}
						</div>
					</div>
				</div>
			</b-form-group>
		</b-collapse>

		<template #modal-footer="{ ok, cancel }">
			<div class="d-flex align-items-center">
				<div v-if="addDecor === true" class="w-100 d-flex justify-content-end align-items-center">
					<b-button
						size="md"
						variant="custom-outline-gray"
						style="margin-top: 5px"
						class="w-138-px mr-3"
						:disabled="loadingSubmit"
						@click="cancel"
						block
					>
						{{ FormMSG(135, 'Cancel') }}
					</b-button>
					<b-button
						size="md"
						variant="primary"
						style="margin-top: 5px"
						class="w-138-px mr-2"
						:disabled="$v.decorName.$model.length !== 0 ? false : true"
						@click="handleClickSave"
						block
					>
						{{ FormMSG(200, 'Add To List') }}
					</b-button>
				</div>
				<div v-else class="w-100 d-flex justify-content-end align-items-center">
					<b-button
						size="md"
						variant="custom-outline-gray"
						style="margin-top: 5px"
						class="w-138-px mr-2"
						:disabled="loadingSubmit"
						@click="cancel"
						block
					>
						{{ FormMSG(128, 'Close') }}
					</b-button>
					<b-button
						size="md"
						variant="primary"
						style="margin-top: 5px"
						class="w-138-px"
						:disabled="locationIdSelected ? false : true"
						@click="handleSetLocation"
						block
					>
						{{ FormMSG(136, 'Save') }}
					</b-button>
				</div>
			</div>
		</template>
	</b-modal>
</template>

<script>
import languageMessages from '@/mixins/languageMessages';
import globalMixin from '@/mixins/global.mixin';
import { mapGetters, mapActions } from 'vuex';
import _ from 'lodash';
import { newLocation, updateLocation, updOrDeleteLocation } from '@/cruds/locations.crud';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import { formatDatesInString } from '@/shared/constants';
import moment from 'moment';

export default {
	name: 'EditLocation',

	mixins: [languageMessages, globalMixin, validationMixin],

	props: {
		open: { type: Boolean, default: false, required: true },
		dataForEdit: { type: Object, default: () => ({}), required: true }
	},

	data() {
		return {
			loadingSubmit: false,
			locationIdSelected: 0,

			locationList: [],
			addDecor: false,
			decorName: ''
		};
	},

	validations: {
		decorName: { required }
	},

	computed: {
		...mapGetters({
			projectLocationsList: 'locations/projectLocationsList'
		}),

		titleCaption() {
			if (JSON.stringify(this.dataForEdit) !== '{}') {
				const dateLocations = moment(new Date(this.dataForEdit.locations.date)).utc().format('YYYY-MM-DD');
				return this.FormMSG(59, 'Manage decor') + ' (' + formatDatesInString(dateLocations, 'YYYY-MM-DD') + ')';
			}
		},

		isOpen: {
			get() {
				return this.open;
			},
			set(val) {
				return val;
			}
		}
	},

	watch: {
		dataForEdit: {
			handler(newVal) {
				if (JSON.stringify(newVal) !== '{}') {
					this.locationIdSelected = +newVal.locations.id === 0 ? null : newVal.locations.id;
				}
			},
			immediate: true,
			deep: true
		},
		projectLocationsList: {
			handler(newVal) {
				this.locationList = [{ id: '0', setName: 'Off' }, ..._.cloneDeep(newVal)]; //_.cloneDeep(newVal);
			},
			deep: true
		}
	},

	methods: {
		...mapActions({
			getProjectLocationList: 'locations/getProjectLocationList'
		}),
		async handleSetLocation() {
			const id = this.locationIdSelected;
			const locationDate = moment(new Date(this.dataForEdit.locations.date)).utc().format('YYYY-MM-DD');
			const date = formatDatesInString(locationDate, 'YYYY-MM-DD') + 'T00:00:00Z';
			let result = await updOrDeleteLocation(id, date);
			if (result) {
				this.$emit('edit-location:add-success');
				this.createToastForMobile(this.FormMSG(451, 'Success'), this.FormMSG(452, 'Decor added successfully!'));
			} else {
				this.createToastForMobile(this.FormMSG(138, 'Error'), this.FormMSG(139, 'Error to added Decor.'));
			}
			this.emitEventClose();
		},
		async handleClickSave() {
			let display = false;
			this.$v.$touch();

			if (this.$v.$invalid) return;
			this.loadingSubmit = true;
			if (this.addDecor && this.$v.decorName.$model.length !== 0) {
				display = true;
			} else {
				display = false;
			}
			const resultNewLocation = await newLocation();
			const locationDate = moment(new Date(this.dataForEdit.locations.date)).utc().format('YYYY-MM-DD');
			const date = formatDatesInString(locationDate, 'YYYY-MM-DD') + 'T00:00:00Z';
			const resultUpdLocation = await updateLocation(resultNewLocation.id, {
				setName: this.decorName,
				getInDate: date,
				getOutDate: date,
				locationDates: {
					date,
					display
				}
			});

			this.$emit('edit-location:add-success');
			this.createToastForMobile(this.FormMSG(451, 'Success'), this.FormMSG(452, 'Decor added successfully!'));

			this.locationList.push(resultUpdLocation);

			this.$nextTick(() => {
				this.locationIdSelected = resultUpdLocation.id;
			});

			this.addDecor = false;
			this.loadingSubmit = false;
			// this.emitEventClose();
		},

		handleClickAddNewDecor() {
			this.addDecor = !this.addDecor;
			this.$v.decorName.$model = '';
		},

		async handleShow() {
			await this.getProjectLocationList();
		},
		emitEventClose() {
			this.locationIdSelected = 0;
			this.addDecor = false;

			this.$emit('edit-location:close');
		}
	}
};
</script>
<style lang="scss">
#supplier-selector {
	width: 100px;
	.vs__dropdown-toggle {
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
	}
}
</style>
