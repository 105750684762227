<template>
	<div
		:id="isRefCellInfo"
		:class="{
			'd-flex flex-column content-item-cell-fixed position-relative': true,
			'content-item-cell-is-prod': isProduction && isCheckCell && isShowBarStatus,
			[`${setClassIsAskToDelete()}`]: true,
			'dashed-border': isHasAskToDeleteInDetails
		}"
		:style="{ width: `${mapResizeCell}px` }"
		v-cell-resize
	>
		<div
			v-if="isCheckCell"
			:class="{
				'd-flex flex-column justify-content-start cell-info': true,
				'w-default': cellLoading
			}"
		>
			<section v-if="cellLoading" class="loader-content">
				<div class="loader"></div>
			</section>
			<section v-else>
				<div class="d-flex flex-row">
					<div class="d-flex flex-row justify-content-start ml-1 mr-1 flex-grow-1">
						<div v-if="isRest() !== '+0'" class="mr-1" v-b-tooltip.hover.left.html="tooltipContentOvertime" style="font-size: 12px">
							<span class="badge badge-info">{{ isRest() }}</span>
						</div>
					</div>
					<div class="d-flex flex-row justify-content-end flex-grow-1">
						<button
							v-if="getStatusItemSendToPaie().status"
							id="is-send-to-paie"
							v-b-tooltip.hover.left.html="`${getLabelStatusSendToPaie()}`"
							class="btn-transparent text-color-rhapsody-in-blue pop-info"
						>
							<div class="refused-content">
								<div v-html="getStatusItemSendToPaie().icon"></div>
								<div class="refused-badge">
									<b-badge class="badge-send-paie-item">
										<component :is="getLucideIcon(ICONS.SEND.name)" :color="'#9404d1'" :size="8" />
									</b-badge>
								</div>
							</div>
						</button>
						<button
							v-else
							class="btn-transparent text-color-rhapsody-in-blue pop-info"
							v-b-tooltip.hover.left.html="`${getLabelStatus(labelStatus)}`"
						>
							<div class="refused-content">
								<div v-html="getStatusItem()"></div>
								<div v-if="checkTypeRefused()" class="refused-badge">
									<b-badge class="badge-item">{{ typeRefused() }}</b-badge>
								</div>
							</div>
						</button>
					</div>
				</div>
				<div v-if="isPerDiem">
					<div class="d-flex flex-column mt-0 pr-1 pl-1" style="gap: 3px">
						<div
							v-for="(item, index) of formatDefaultContentCells(fimalacCellData())"
							:key="index"
							class="d-flex justify-content-center align-items-center ml-rem-1 mr-rem-1 text-black"
							:style="`background-color: ${item.color}; border-radius: 4px;height: 20px;font-size: 10px`"
							:id="`popover-${item.id}-${index}`"
						>
							<span> <div :class="{ [generateAnimation(item)]: item.hasChangesAfterPay }"></div> </span>&nbsp;
							<span
								:id="`item-cell-info-${indexField}`"
								:style="`text-align: center; text-overflow: ellipsis; overflow: hidden; white-space: nowrap; width:${
									widthCell - 10
								}px; color: ${isColorLight(item.color) ? 'black' : 'white'}; font-weight: bold`"
								class="flex-grow-1"
								>{{ labelTextPerDiem(item) }}</span
							>
							<span class="flex-grow-0" v-if="actionTypeRowRemove(item).isShowIcon">
								<button class="btn-custom-ask-detail" @click.prevent.stop="handleDeleteItemRowDetail(item)">
									<component
										v-if="actionTypeRowRemove(item).isRemove"
										:is="getLucideIcon(isGestionnaryOfPaie ? ICONS.TRASH.name : ICONS.X_CIRCLE.name)"
										:color="setColorType(typeAskLevel(item).type, item.color)"
										:size="25"
									/>
									<component
										v-if="actionTypeRowRemove(item).isUndo"
										:is="getLucideIcon(ICONS.UNDO_2.name)"
										:color="setColorType(typeAskLevel(item).type, item.color)"
										:size="18"
										:stroke-width="3.8"
									/>
								</button>
							</span>
							<b-popover custom-class="v-bootstrap-popover-custom" :target="`popover-${item.id}-${index}`" triggers="hover focus">
								<div @mouseenter="(e) => genereateEvent(e, item)" @mouseleave="removeGenerateEvent" v-html="tooltipPerDiemLabel(item)"></div>
							</b-popover>
						</div>
					</div>
				</div>
				<div v-if="isAnalyTicalCode">
					<div class="d-flex flex-column mt-0 pr-1 pl-1" style="gap: 3px">
						<div
							v-for="(item, index) of formatDefaultContentCells(fimalacCellData())"
							:key="index"
							class="d-flex justify-content-center align-items-center ml-rem-1 mr-rem-1 text-black content-row-detail"
							:style="`background-color: ${item.color}; border-radius: 4px;height: 20px;font-size: 10px`"
							:id="`popover-${item.id}-${index}`"
						>
							<span> <div :class="{ [generateAnimation(item)]: item.hasChangesAfterPay }"></div> </span>&nbsp;
							<span
								:id="`item-cell-info-${indexField}`"
								class="row-info-content flex-grow-1"
								:style="`text-align: center; text-overflow: ellipsis; overflow: hidden; white-space: nowrap; width:${
									widthCell - 10
								}px; color: ${isColorLight(item.color) ? 'black' : 'white'}; font-weight: bold`"
								>{{ labelTextAnalitical(item) }}</span
							>
							<span class="flex-grow-0" v-if="actionTypeRowRemove(item).isShowIcon">
								<button class="btn-custom-ask-detail" @click.prevent.stop="handleDeleteItemRowDetail(item)">
									<component
										v-if="actionTypeRowRemove(item).isRemove"
										:is="getLucideIcon(iconRemoveType(item).icon)"
										:color="setColorType(typeAskLevel(item).type, item.color)"
										:size="25"
									/>
									<component
										v-if="actionTypeRowRemove(item).isUndo"
										:is="getLucideIcon(ICONS.UNDO_2.name)"
										:color="setColorType(typeAskLevel(item).type, item.color)"
										:size="18"
										:stroke-width="3.8"
									/>
								</button>
							</span>
							<b-popover
								ref="popover-info-cell-row"
								custom-class="v-bootstrap-popover-custom"
								:target="`popover-${item.id}-${index}`"
								triggers="hover focus"
							>
								<div @mouseenter="(e) => genereateEvent(e, item)" @mouseleave="removeGenerateEvent" v-html="tooltipAnaliticalLabel(item)"></div>
							</b-popover>
						</div>
					</div>
				</div>
				<div v-if="isPrestations">
					<div class="d-flex flex-column mt-0 pr-1 pl-1" style="gap: 3px">
						<div
							v-for="(item, index) of formatDefaultContentCells(fimalacCellData())"
							:key="index"
							class="d-flex justify-content-center align-items-center ml-rem-1 mr-rem-1 text-black"
							:style="`background-color: ${item.color}; border-radius: 4px;height: 20px;font-size: 10px`"
							:id="`popover-${item.id}-${index}`"
						>
							<span> <div :class="{ [generateAnimation(item)]: item.hasChangesAfterPay }"></div> </span>&nbsp;
							<span
								:id="`item-cell-info-${indexField}`"
								:style="`text-align: center; text-overflow: ellipsis; overflow: hidden; white-space: nowrap; width:${
									widthCell - 10
								}px; color: ${isColorLight(item.color) ? 'black' : 'white'}; font-weight: bold;`"
								class="flex-grow-1"
								>{{ labelTextPrestation(item) }}</span
							>
							<span class="flex-grow-0" v-if="actionTypeRowRemove(item).isShowIcon">
								<button class="btn-custom-ask-detail" @click.prevent.stop="handleDeleteItemRowDetail(item)">
									<component
										v-if="actionTypeRowRemove(item).isRemove"
										:is="getLucideIcon(isGestionnaryOfPaie ? ICONS.TRASH.name : ICONS.X_CIRCLE.name)"
										:color="setColorType(typeAskLevel(item).type, item.color)"
										:size="25"
									/>
									<component
										v-if="actionTypeRowRemove(item).isUndo"
										:is="getLucideIcon(ICONS.UNDO_2.name)"
										:color="setColorType(typeAskLevel(item).type, item.color)"
										:size="18"
										:stroke-width="3.8"
									/>
								</button>
							</span>
							<b-popover custom-class="v-bootstrap-popover-custom" :target="`popover-${item.id}-${index}`" triggers="hover focus">
								<div
									@mouseenter="(e) => genereateEvent(e, item)"
									@mouseleave="removeGenerateEvent"
									v-html="tooltipPrestationsLabel(item)"
								></div>
							</b-popover>
						</div>
					</div>
				</div>
			</section>
		</div>
	</div>
</template>
<script>
import languageMessages from '@/mixins/languageMessages';
import globalMixin from '@/mixins/global.mixin';
import isSingleProjectMixin from '@/mixins/isSingleProject.mixin';
import { getStatus } from '@/utils/utils';
import { rendCurrency } from '~helpers';
import { formatDateConverted, CELL, VALIDATION_TYPE, TYPE_ASK_TO_DELETE, INTERNAL_STATUS, PLANNING_LEVEL, ROW_ASK_TO_DELETE } from '@/shared/constants';
import { delTsdayDetails } from '@/cruds/timesheets-efc.crud';
import * as _ from 'lodash';

export class StatePersistence {
	static data = [];
	static setState(newIndex, items) {
		if (newIndex === -1 && items === null) {
			this.data = [];
		} else {
			const convertItem = JSON.stringify(items);
			const isFind = this.data.find(({ index }) => index === newIndex);
			if (isFind) {
				isFind.items.push(convertItem);
			} else {
				this.data = [...this.data, { index: newIndex, items: [convertItem] }];
			}
		}
	}
	static getState(newIndex) {
		return this.data.filter(({ index }) => index === newIndex);
	}
}

export default {
	name: 'CellInfo',
	components: {},
	mixins: [languageMessages, isSingleProjectMixin, globalMixin],
	props: {
		isGestionnaryOfPaie: {
			type: Boolean,
			required: false,
			default: () => false
		},
		generateElementHeaderRow: {
			type: Number || null,
			required: false,
			default: () => CELL.WIDTH
		},
		setTextInfoBullWorkFlow: {
			type: Function,
			required: false,
			default: () => ''
		},
		sizeCell: {
			type: Number,
			required: false
		},
		isRefCellInfo: {
			type: String,
			required: false
		},
		isCheckCell: {
			type: Boolean,
			required: false
		},
		keyCell: {
			type: String,
			required: false,
			default: ''
		},
		arrayTsDays: {
			type: Array,
			required: false,
			default: () => []
		},
		labelStatus: {
			type: String,
			required: false
		},
		labelStatusColor: {
			type: String,
			required: false
		},
		isPerDiem: {
			type: Boolean,
			required: false
		},
		isAnalyTicalCode: {
			type: Boolean,
			required: false
		},
		isPrestations: {
			type: Boolean,
			required: false
		},
		currentValidationLevel: {
			type: Number,
			required: false
		},
		isProduction: {
			type: Boolean,
			required: false
		},
		isShowBarStatus: {
			type: Boolean,
			required: false
		},
		fimalacCellItem: {
			type: Object,
			required: false,
			default: null
		},
		widthCell: {
			type: Number,
			required: false
		},
		indexField: {
			type: Number,
			required: false,
			default: 0
		},
		uniqueID: {
			type: String,
			required: false,
			default: ''
		}
	},
	data: () => {
		return {
			getStatus,
			resizeObserver: null,
			cellWidth: 0,
			rendCurrency,
			validated: null,
			cellLoading: false,
			cellTooltipContentActions: [],
			hasAskToDeleteInDetails: false,
			isSendToPaie: false
		};
	},
	watch: {
		isRefCellInfo: {
			handler(newVal) {
				return newVal;
			},
			immediate: true,
			deep: true
		},
		cellWidth: {
			handler(newVal) {
				return newVal;
			},
			immediate: true,
			deep: true
		},
		isCheckCell: {
			async handler(newVal) {
				return newVal;
			},
			immediate: true,
			deep: true
		}
	},
	computed: {
		mapResizeCell() {
			return this.generateElementHeaderRow;
		},
		isHasAskToDeleteInDetails() {
			return this.hasAskToDeleteInDetails;
		}
	},
	directives: {
		'cell-resize': {
			bind(el, _, vnode) {
				if (el) {
					el.__resizeObserver__ = new ResizeObserver((entries) => {
						for (let entry of entries) {
							const { width } = entry.contentRect;
							vnode.context.handleResize(width);
						}
					});
					el.__resizeObserver__.observe(el);
				}
			},
			unbind(el) {
				if (el.__resizeObserver__) {
					el.__resizeObserver__.disconnect();
					delete el.__resizeObserver__;
				}
			}
		}
	},
	mounted() {
		this.initialize();
	},
	methods: {
		async initialize() {
			if (this.isCheckCell) {
				StatePersistence.setState(this.uniqueID, this.fimalacCellData());
				const newData = StatePersistence.getState(this.uniqueID);
				const size = newData.length;
				const newItems = newData[size - 1];
				const { items: defaultItems } = newItems;
				const sizeItems = defaultItems.length;
				const prevItem = defaultItems[sizeItems - 2];
				const currentItem = defaultItems[sizeItems - 1];
				if (prevItem && currentItem) {
					this.cellLoading = !(prevItem === currentItem);
				} else {
					this.cellLoading = true;
				}
				setTimeout(() => {
					this.cellLoading = false;
				}, 400);
				return true;
			}
		},
		iconRemoveType(item) {
			if (item) {
				const currentValidationLevel = this.currentValidationLevel;
				const { MANAGER, CHARG_PROD } = PLANNING_LEVEL;
				const { VALIDATED_MANAGER, REJECTED_LVL_ONE, REJECTED_LVL_TWO } = ROW_ASK_TO_DELETE;
				const { askToDelete } = item;
				const isAskFromManager = [VALIDATED_MANAGER].includes(askToDelete);
				const isRefused = [REJECTED_LVL_ONE, REJECTED_LVL_TWO].includes(askToDelete);
				if (isRefused) {
					return {
						icon: this.ICONS.ALERT_TRIANGLE.name,
						color: this.ICONS.ALERT_TRIANGLE.color,
						accept: false,
						text: null,
						isRefused
					};
				}
				if (this.isGestionnaryOfPaie) {
					return {
						icon: this.ICONS.TRASH.name,
						color: this.ICONS.TRASH.color,
						accept: false,
						text: null,
						isRefused
					};
				} else {
					if (this.isSendToPaie) {
						const options =
							currentValidationLevel === MANAGER
								? { icon: this.ICONS.X_CIRCLE.name, color: this.ICONS.X_CIRCLE.color, accept: false, text: null }
								: currentValidationLevel === CHARG_PROD
								? {
										icon: this.ICONS.X_CIRCLE.name,
										color: this.ICONS.X_CIRCLE.color,
										accept: isAskFromManager,
										text: null,
										isRefused
								  }
								: { icon: this.ICONS.CHECK_CIRCLE.name, color: this.ICONS.X_CIRCLE.color, accept: false, text: null, isRefused };
						return {
							...options
						};
					}
					return {
						icon: this.ICONS.TRASH.name,
						color: this.ICONS.TRASH.color,
						accept: false,
						text: null,
						isRefused
					};
				}
			}
			return {
				icon: this.ICONS.TRASH.name,
				color: this.ICONS.TRASH.color,
				accept: false,
				text: null,
				isRefused
			};
		},
		getIconSVGHtml(item, color) {
			const strokeColor = this.setColorType(this.typeAskLevel(item).type, color);
			const isRefused = this.iconRemoveType(item).isRefused;
			if (isRefused) {
				return `
					<svg data-v-c4f36e3c="" 
					xmlns="http://www.w3.org/2000/svg" 
					width="20" 
					height="20" 
					viewBox="0 0 24 24" 
					fill="none" 
					stroke="${strokeColor}" 
					stroke-width="2" 
					stroke-linecap="round" 
					stroke-linejoin="round" 
					class="lucide-icon lucide lucide-alert-triangle">
						<path data-v-c4f36e3c="" d="m21.73 18-8-14a2 2 0 0 0-3.48 0l-8 14A2 2 0 0 0 4 21h16a2 2 0 0 0 1.73-3Z"></path>
						<line data-v-c4f36e3c="" x1="12" y1="9" x2="12" y2="13"></line>
						<line data-v-c4f36e3c="" x1="12" y1="17" x2="12.01" y2="17"></line>
					</svg>
				`;
			}
			return `<svg data-v-c4f36e3c=""
					xmlns="http://www.w3.org/2000/svg"
					width="20"
					height="20"
					viewBox="0 0 24 24"
					fill="none"
					stroke="${strokeColor}"
					stroke-width="2"
					stroke-linecap="round"
					stroke-linejoin="round"
					class="lucide-icon lucide lucide-xcircle"
				>
					<circle data-v-c4f36e3c="" cx="12" cy="12" r="10"></circle>
					<line data-v-c4f36e3c="" x1="15" y1="9" x2="9" y2="15"></line>
					<line data-v-c4f36e3c="" x1="9" y1="9" x2="15" y2="15"></line>
				</svg>`;
		},
		genereateEvent(e, item) {
			e.preventDefault();
			const { id } = item;
			const contentTooltip = document.getElementById(`row-ask-detail-${id}`);
			if (contentTooltip) {
				const onGenerateEvent = () => this.handleDeleteItemRowDetail(item);
				this.cellTooltipContentActions = [...this.cellTooltipContentActions, { element: contentTooltip, onGenerateEvent }];
				contentTooltip.addEventListener('click', onGenerateEvent);
			}
		},
		removeGenerateEvent(e) {
			e.preventDefault();
			const popoverActive = this.$refs['popover-info-cell-row'];
			if (popoverActive) {
				popoverActive.forEach((rowPopover) => rowPopover.doClose());
				this.cellTooltipContentActions.forEach(({ element, onGenerateEvent }) => element.removeEventListener('click', onGenerateEvent));
				this.cellTooltipContentActions = [];
			}
		},
		actionTypeRowRemove(item) {
			const { DEFAULT_ASK, FOR_MANAGER, FOR_LVL_ONE } = TYPE_ASK_TO_DELETE;
			const { MANAGER, CHARG_PROD, ADM_PROD } = PLANNING_LEVEL;
			const currentValidationLevel = this.currentValidationLevel;
			if (item) {
				const { askToDelete: status } = item;
				const isNoAction = status === DEFAULT_ASK;
				const mapAuthLevel = (level) => {
					const cond = {
						[MANAGER]: [FOR_MANAGER],
						[CHARG_PROD]: [FOR_LVL_ONE],
						[ADM_PROD]: []
					};
					const isAuthRemove = cond[level] ? cond[level].includes(status) : false;
					const option = {
						isRemove: isNoAction ? false : !isAuthRemove,
						isUndo: isNoAction ? false : isAuthRemove
					};
					const { isRemove, isUndo } = option;
					return {
						...option,
						isShowIcon: [ADM_PROD].includes(level) ? false : [isRemove, isUndo].includes(true)
					};
				};
				if (this.isGestionnaryOfPaie) {
					const isAuthRemove = [FOR_LVL_ONE].includes(status); // if action charg prod active
					const option = {
						isRemove: isNoAction ? false : isAuthRemove,
						isUndo: false
					};
					const { isRemove, isUndo } = option;
					return {
						isRemove,
						isUndo,
						isShowIcon: [isRemove, isUndo].includes(true)
					};
				}
				return mapAuthLevel(currentValidationLevel);
			}
			return { isRemove: false, isUndo: false, isShowIcon: false };
		},
		async handleDeleteItemRowDetail(item) {
			if (item) {
				const { isUndo } = this.actionTypeRowRemove(item);
				const { id } = item;
				const onActionDelete = async () => {
					const uniqueID = +id;
					const result = await delTsdayDetails(uniqueID);
					if (result) {
						if (isUndo) {
							this.$toast({
								message: 'Data undo with success',
								title: this.FormMSG(110, 'Success'),
								variant: 'success'
							});
						} else {
							this.$toast({
								message: this.isGestionnaryOfPaie
									? this.FormMSG(114, 'Data remove successfully!')
									: this.FormMSG(115, 'Data change in ask to delete'),
								title: this.FormMSG(110, 'Success'),
								variant: 'success'
							});
						}
					} else {
						this.$toast({
							message: this.FormMSG(369, 'Error to delete'),
							title: this.FormMSG(370, 'Error'),
							variant: 'danger'
						});
					}
					this.$emit('on-refresh-planning');
				};
				let messageConfirm = '';
				if (isUndo) {
					messageConfirm = this.FormMSG(17, 'Please confirm if you want to undo the item selected');
				} else {
					messageConfirm = this.isGestionnaryOfPaie
						? this.FormMSG(116, 'Please confirm if you want to remove the item selected')
						: this.FormMSG(117, 'Please confirm if you want to change in ask to delete the item selected');
				}
				this.confirmModal(onActionDelete, messageConfirm);
			}
		},
		changeToBooleanStatusAskToDelete(status) {
			if (status && status !== -1) {
				return true;
			}
			return false;
		},
		getStatusColor(validated) {
			const { SUBMITTED, VALIDATED_LEVEL_1, REFUSED_LEVEL_1, VALIDATED_LEVEL_2, REFUSED_LEVEL_2 } = VALIDATION_TYPE;
			const statusMap = {
				[SUBMITTED]: 'info',
				[VALIDATED_LEVEL_1]: 'pending',
				[VALIDATED_LEVEL_2]: 'validated',
				[REFUSED_LEVEL_1]: 'refused',
				[REFUSED_LEVEL_2]: 'refused'
			};
			return statusMap[validated] || 'not-submitted';
		},
		getStatusItemSendToPaie() {
			const { DEFAULT_INTERNAL } = INTERNAL_STATUS;
			const fimalacCellItem = this.fimalacCellItem;
			const { validated, internalStatus, hasChangesAfterPay } = fimalacCellItem;
			const newStatusLabel = this.getStatusColor(validated);
			const label = `status-${newStatusLabel}`;
			const isSendToPaie = !!(internalStatus || DEFAULT_INTERNAL);
			const boolean = isSendToPaie ? hasChangesAfterPay : false;
			return {
				status: boolean,
				icon: getStatus(label, fimalacCellItem, boolean),
				label: label
			};
		},
		getStatusItem() {
			const label = `status-${this.labelStatusColor}`;
			const fimalacCellItem = this.fimalacCellItem;
			return getStatus(label, fimalacCellItem, false);
		},
		handleResize(newWidth) {
			this.cellWidth = newWidth;
		},
		formatDefaultContentCells(data) {
			if (!_.isNil(data)) {
				return data.slice(0, 3);
			}
			return [];
		},
		isRest() {
			if (this.fimalacCellData()) {
				const data = this.fimalacCellData().slice(3) || [];
				if (!_.isNil(data)) {
					return '+' + data.length;
				}
			}
		},
		rendAmountCurrency(hideSalary, amount) {
			if (!hideSalary) {
				return amount;
			} else {
				if (this.isProduction) {
					return amount;
				}
				const unite = `${amount}`.replaceAll('K', '').split(' ')[1];
				return `Xx.xx ${unite}`;
			}
		},
		labelTextPerDiem(item) {
			if (!_.isNil(item)) {
				const hideSalary = item.hideSalary;
				const message = item.projectFlagItem.short.toString().toUpperCase();
				const sizeContent = message.length || 0;
				const totalAmount = +item.quantity * +item.amount;
				const amount = rendCurrency(totalAmount, 3);
				const sizeAmount = amount.split(' ')[0].toString().length;
				const checkAmount = sizeAmount - 3 <= 0 ? 0 : sizeAmount - 3;
				const stay = amount.toString().length;
				if (this.cellWidth !== 0) {
					const charWidth = message.length;
					const widthResize = Math.floor(this.cellWidth / charWidth);
					const changeWidth = Math.floor((this.cellWidth - this.widthCell) / charWidth);
					const modulo = widthResize === 0 ? 0 : 4 + changeWidth;
					const positionText = widthResize + modulo - checkAmount;
					const size = positionText <= 0 ? 1 : positionText;
					if (widthResize < sizeContent - stay) {
						if (size >= charWidth) {
							return (
								message +
								(this.isProduction && +item.activity.id !== 0 && +item.category !== 0
									? ' (' + this.rendAmountCurrency(hideSalary, amount) + ')'
									: '')
							);
						}
						return (
							message.substr(0, size) +
							' ...' +
							(this.isProduction && +item.activity.id !== 0 && +item.category !== 0
								? ' (' + this.rendAmountCurrency(hideSalary, amount) + ')'
								: '')
						);
					}
					return (
						message +
						(this.isProduction && +item.activity.id !== 0 && +item.category !== 0 ? ' (' + this.rendAmountCurrency(hideSalary, amount) + ')' : '')
					);
				}
			}
		},
		changeToBooleanStatusAskToDelete(status) {
			const { DEFAULT_ASK } = TYPE_ASK_TO_DELETE;
			if (status !== DEFAULT_ASK) {
				return true;
			}
			return false;
		},
		isAskItemTsDetailDelete(item) {
			if (item) {
				const { askToDelete: status } = item;
				const isConvertedAsk = this.changeToBooleanStatusAskToDelete(status);
				return isConvertedAsk;
			}
			return false;
		},
		labelTextAnalitical(item) {
			if (!_.isNil(item)) {
				let trimestre = '';
				const hideSalary = item.hideSalary;
				const projet = item.costCenter.split`$`[0];
				const activity = item.activity.short;
				const checkTrimestre = item.costCenter.split`$`[1];
				if (checkTrimestre !== undefined) {
					trimestre = checkTrimestre.substring(0, 4);
				}
				let message = projet + activity + trimestre;
				if (message.length === 0) {
					message = 'none';
				}
				let totalAmount = +item.quantity * +item.amount;
				const amount = rendCurrency(totalAmount, 3);
				const sizeAmount = amount.split(' ')[0].toString().length;
				const checkAmount = sizeAmount - 3 <= 0 ? 0 : sizeAmount - 3;
				const sizeContent = (message + ' | ' + rendCurrency(totalAmount, 3)).length;
				const stay = amount.toString().length;
				if (this.cellWidth !== 0) {
					const charWidth = message.length;
					const widthResize = Math.floor(this.cellWidth / charWidth);
					const changeWidth = Math.floor((this.cellWidth - this.widthCell) / charWidth);
					const modulo = widthResize === 0 ? 0 : 4 + changeWidth;
					const positionText = widthResize + modulo - checkAmount;
					const size = positionText <= 0 ? 1 : positionText;
					if (widthResize < sizeContent - stay) {
						if (size >= charWidth) {
							return message + ' | ' + this.rendAmountCurrency(hideSalary, amount);
						}
						return message.substr(0, size) + ' ...' + ' ' + this.rendAmountCurrency(hideSalary, amount);
					}
					return message + ' | ' + this.rendAmountCurrency(hideSalary, amount);
				}
			}
		},
		labelTextPrestation(item) {
			if (!_.isNil(item)) {
				let trimestre = '';
				const hideSalary = item.hideSalary;
				const projet = item.costCenter.split`$`[0];
				const short = item.projectFlagItem.short.toString().toUpperCase();
				const activity = item.activity.short;
				const checkTrimestre = item.costCenter.split`$`[1];
				if (checkTrimestre !== undefined) {
					trimestre = checkTrimestre.substring(0, 4);
				}
				const message = projet + activity + trimestre;
				const totalAmount = +item.quantity * +item.amount;
				const amount = rendCurrency(totalAmount, 3);
				const sizeAmount = amount.split(' ')[0].toString().length;
				const checkAmount = sizeAmount - 3 <= 0 ? 0 : sizeAmount - 3;
				const sizeContent = ('(' + short + ') - ' + message + ' | ' + amount).length;
				const stay = amount.toString().length;
				if (this.cellWidth !== 0) {
					const charWidth = message.length;
					const widthResize = Math.floor(this.cellWidth / charWidth);
					const changeWidth = Math.floor((this.cellWidth - this.widthCell) / charWidth);
					const modulo = widthResize === 0 ? 0 : 1 + changeWidth;
					const positionText = widthResize + modulo - checkAmount;
					const size = positionText <= 0 ? 1 : positionText - 1;
					const _short = short.length > 3 ? short.substr(0, 3) : short + '...';
					if (widthResize < sizeContent - stay) {
						if (size >= charWidth) {
							return '(' + short + ') - ' + message + ' | ' + this.rendAmountCurrency(hideSalary, amount);
						}
						return '(' + _short + ') - ' + message.substr(0, size) + ' ...' + ' | ' + this.rendAmountCurrency(hideSalary, amount);
					}
					return '(' + short + ') - ' + message + ' | ' + this.rendAmountCurrency(hideSalary, amount);
				}
			}
		},
		tooltipPerDiemLabel(item) {
			let message = item.costCenter;
			if (message.length === 0) {
				message = this.FormMSG(3003, 'None');
			}
			const totalAmount = +item.quantity * +item.amount;
			// const unique = message + ' | ' + rendCurrency(totalAmount, 3);
			const isConvertedAsk = this.isAskItemTsDetailDelete(item);
			return `${this.renderTooltipGlobal(item, isConvertedAsk)}`;
		},
		tooltipAnaliticalLabel(item) {
			if (!_.isNil(item)) {
				let trimestre = '';
				const projet = item.costCenter.split`$`[0];
				const activity = item.activity.short;
				const checkTrimestre = item.costCenter.split`$`[1];
				const totalAmount = +item.quantity * +item.amount;
				if (checkTrimestre !== undefined) {
					trimestre = checkTrimestre.substring(0, 4);
				}
				let message = projet + activity + trimestre;
				if (message.length === 0) {
					message = this.FormMSG(3003, 'None');
				}
				// const unique = message + ' | ' + rendCurrency(totalAmount, 3);
				const isConvertedAsk = this.isAskItemTsDetailDelete(item);
				return `${this.renderTooltipGlobal(item, isConvertedAsk)}`;
			}
		},
		tooltipPrestationsLabel(item) {
			if (!_.isNil(item)) {
				let trimestre = '';
				const projet = item.costCenter.split`$`[0];
				const short = item.projectFlagItem.short.toString().toUpperCase();
				const activity = item.activity.short;
				let checkTrimestre = item.costCenter.split`$`[1];
				if (checkTrimestre !== undefined) {
					trimestre = checkTrimestre.substring(0, 4);
				}
				const message = projet + activity + trimestre;
				const totalAmount = +item.quantity * +item.amount;
				// const unique = '(' + short + ') - ' + message + ' | ' + rendCurrency(totalAmount, 3);
				const isConvertedAsk = this.isAskItemTsDetailDelete(item);
				return `${this.renderTooltipGlobal(item, isConvertedAsk)}`;
			}
		},
		renderTooltipGlobal(item, isConvertedAsk) {
			const { ADM_PROD } = PLANNING_LEVEL;
			const currentValidationLevel = this.currentValidationLevel;
			const short = item.activity.short;
			const costCenter = (item.activity.name + ` (${short})`).trim();
			const rubrique = item.projectFlagItem.name.toString().trim();
			const color = item.color;
			const projectPhase = item.projectPhase;
			const { isUndo, isRemove } = this.actionTypeRowRemove(item);
			const isAuthorizeLevel = (this.isGestionnaryOfPaie ? true : currentValidationLevel === ADM_PROD ? false : true) && (isRemove || isUndo);
			return `<div style="display: flex; algin-items: center; justify-content: start; flex-direction: column">
							${
								projectPhase.length > 2
									? `<div style="display: flex;flex-direction: row; align-items: center; gap: 6px; padding: 2px;text-align: start;width: 100%;">
											<div style="width: 5px; height: 5px; margin-left: 5px; background-color: ${color}; border-radius: 50%; padding: 2px;"></div>
											<div>${projectPhase}</div>
										</div>`
									: ''
							}
							${
								costCenter.length > 2
									? `<div style="display: flex;flex-direction: row; align-items: center; gap: 6px; padding: 2px;text-align: start;width: 100%;">
											<div style="width: 5px; height: 5px; margin-left: 5px; background-color: ${color}; border-radius: 50%; padding: 2px;"></div>
											<div>${costCenter}</div>
										</div>`
									: ''
							}
							${
								rubrique.length > 2
									? `<div style="display: flex;flex-direction: row; align-items: center; gap: 6px; padding: 2px;text-align: start;width: 100%;">
											<div style="width: 5px; height: 5px; margin-left: 5px; background-color: ${color}; border-radius: 50%; padding: 2px;"></div>
											<div>${rubrique}</div>
										</div>`
									: ''
							}
							${
								isConvertedAsk && isAuthorizeLevel
									? `<div id="row-ask-detail-${
											item.id
									  }" style="display: flex;flex-direction: row; align-items: center; gap: 6px; padding: 2px;text-align: start; width: 100%; border: 2px solid ${this.setColorType(
											this.typeAskLevel(item).type,
											color
									  )}; border-radius: 4px; cursor: pointer;" class="custom-popover-ask-hover-${this.setHover(
											this.setColorType(this.typeAskLevel(item).type, color)
									  )}">
											<div style="width: 20px; height: 25px; margin-left: 2px; border-radius: 50%; padding: 2px;">

											${
												isUndo
													? `
												<svg data-v-c4f36e3c=""
												xmlns="http://www.w3.org/2000/svg"
												width="15"
												height="15"
												viewBox="0 0 24 24"
												fill="none"
												stroke="${this.setColorType(this.typeAskLevel(item).type, color)}"
												stroke-width="2"
												stroke-linecap="round"
												stroke-linejoin="round"
												class="lucide-icon lucide lucide-undo2">
													<path data-v-c4f36e3c="" d="M9 14 4 9l5-5"></path>
													<path data-v-c4f36e3c="" d="M4 9h10.5a5.5 5.5 0 0 1 5.5 5.5v0a5.5 5.5 0 0 1-5.5 5.5H11"></path>
												</svg>
											`
													: this.isGestionnaryOfPaie && isRemove
													? `
													<svg data-v-c4f36e3c=""
													xmlns="http://www.w3.org/2000/svg"
													width="20"
													height="20"
													viewBox="0 0 24 24"
													fill="none"
													stroke="${this.setColorType(this.typeAskLevel(item).type, color)}"
													stroke-width="2"
													stroke-linecap="round"
													stroke-linejoin="round"
													class="lucide-icon lucide lucide-trash2">
														<path data-v-c4f36e3c="" d="M3 6h18"></path>
														<path data-v-c4f36e3c="" d="M19 6v14c0 1-1 2-2 2H7c-1 0-2-1-2-2V6"></path>
														<path data-v-c4f36e3c="" d="M8 6V4c0-1 1-2 2-2h4c1 0 2 1 2 2v2"></path>
														<line data-v-c4f36e3c="" x1="10" y1="11" x2="10" y2="17"></line>
														<line data-v-c4f36e3c="" x1="14" y1="11" x2="14" y2="17"></line>
													</svg>
													`
													: `
												${isRemove ? this.getIconSVGHtml(item, color) : ''}
											`
											}
											</div>
											<div style="font-size: 13px; font-weight: bold; letter-spacing: 1px;">${this.typeAskLevel(item).message}</div>
										</div>`
									: ''
							}
						</div>`;
		},
		setColorType(type, backgGroundColor) {
			const { FOR_MANAGER, FOR_LVL_ONE } = TYPE_ASK_TO_DELETE;
			const newBackgGroundColor = backgGroundColor ? backgGroundColor : '#000000';
			const mapColorStatus = {
				[FOR_MANAGER]: '#EA4E2C',
				[FOR_LVL_ONE]: '#025755'
			};
			const genearteNewColor = this.isColorLight(newBackgGroundColor) ? mapColorStatus[type] : '#FFFFFF';
			return genearteNewColor;
		},
		setHover(color) {
			if (color) {
				switch (color) {
					case '#EA4E2C':
						return 'for-manager';
					case '#025755':
						return 'for-level-one';
					default:
						return 'default';
				}
			}
			return 'default';
		},
		messageStart(status) {
			const messageStatus = {
				['create']: this.FormMSG(13, 'Ask to delete create by'),
				['default']: this.FormMSG(5, 'Ask to delete by'),
				['afterRegisseur']: this.FormMSG(14, 'Ask to delete sent by the manager, the production manager must validate this element'),
				['afterChargeProd']: this.FormMSG(15, 'Ask to delete validated by the production manager, the payroll manager must validate this element'),
				['paie']: this.FormMSG(16, 'Ask to delete validated by the production manager')
			};
			return messageStatus[status];
		},
		typeAskLevel(item) {
			const { VALIDATED_MANAGER, VALIDATED_LVL_ONE, REJECTED_LVL_ONE, REJECTED_LVL_TWO } = ROW_ASK_TO_DELETE;
			const { MANAGER, CHARG_PROD, ADM_PROD } = PLANNING_LEVEL;
			const currentValidationLevel = this.currentValidationLevel;
			const option = this.actionTypeRowRemove(item);
			const { isUndo } = option;
			if (item) {
				const { askToDelete } = item;
				const commonMessage = isUndo ? this.FormMSG(9, ', click to undo') : this.FormMSG(10, ', you can validate it');
				const clickUndo = this.FormMSG(9, ', click to undo');
				const mssgFOR_MANAGER = {
					[MANAGER]: this.messageStart('afterRegisseur') + ' ' + commonMessage,
					[CHARG_PROD]: this.messageStart('create') + ' ' + this.FormMSG(6, 'Manager') + commonMessage,
					[ADM_PROD]: this.messageStart('create') + ' ' + this.FormMSG(6, 'Manager') + commonMessage
				};
				const mssgFOR_LVL_ONE = {
					[MANAGER]: this.messageStart('afterChargeProd') + ' ' + clickUndo,
					[CHARG_PROD]: this.messageStart('afterChargeProd') + ' ' + clickUndo,
					[ADM_PROD]: this.isGestionnaryOfPaie
						? this.messageStart('paie') + this.FormMSG(12, ', you can only delete the item')
						: this.messageStart('afterChargeProd')
				};
				switch (askToDelete) {
					case VALIDATED_MANAGER:
						return {
							type: VALIDATED_MANAGER,
							message: mssgFOR_MANAGER[currentValidationLevel]
						};
					case VALIDATED_LVL_ONE:
						return {
							type: VALIDATED_LVL_ONE,
							message: mssgFOR_LVL_ONE[currentValidationLevel]
						};
					case REJECTED_LVL_ONE:
						return {
							type: REJECTED_LVL_ONE,
							message: this.FormMSG(371, 'Reject by level 1') + ' ' + clickUndo
						};
					case REJECTED_LVL_TWO:
						return {
							type: REJECTED_LVL_TWO,
							message: this.FormMSG(372, 'Reject by level 2') + ' ' + clickUndo
						};
					default:
						return {
							type: null,
							message: ''
						};
				}
			}
			return '';
		},
		typeRefused() {
			const { REFUSED_LEVEL_1, REFUSED_LEVEL_2 } = VALIDATION_TYPE;
			const item = this.fimalacCellItem;
			if (!_.isNil(item)) {
				const { validated: v } = item;
				if (v === REFUSED_LEVEL_2) {
					return 2;
				}
				if (v === REFUSED_LEVEL_1) {
					return 1;
				}
			}
		},
		checkTypeRefused() {
			const { REFUSED_LEVEL_1, REFUSED_LEVEL_2 } = VALIDATION_TYPE;
			const conditions = [REFUSED_LEVEL_1, REFUSED_LEVEL_2];
			const item = this.fimalacCellItem;
			if (!_.isNil(item)) {
				const { validated: v } = item;
				if (conditions.includes(v)) {
					return true;
				}
				return false;
			}
			return false;
		},
		setTooltipStatusInformation(status, message, item) {
			const { NOT_SUBMITTED, SUBMITTED, VALIDATED_LEVEL_1, VALIDATED_LEVEL_2, REFUSED_LEVEL_1, REFUSED_LEVEL_2 } = VALIDATION_TYPE;
			let messageStatusType = '';
			let isShowComment = false;
			if (status !== REFUSED_LEVEL_2 && status !== REFUSED_LEVEL_1) {
				messageStatusType = message;
				isShowComment = false;
			} else {
				isShowComment = true;
				if (status === REFUSED_LEVEL_2) {
					messageStatusType = this.FormMSG(1, 'Refused Production Admin');
				}
				if (status === REFUSED_LEVEL_1) {
					messageStatusType = this.FormMSG(2, 'Refused Production Manager');
				}
			}
			const setColor = () => {
				if (status === NOT_SUBMITTED) {
					return '#fcede9';
				}
				if (status === SUBMITTED) {
					return '#225cbd';
				}
				if (status === VALIDATED_LEVEL_1) {
					return '#edbe13';
				}
				if (status === VALIDATED_LEVEL_2) {
					return '#2df52a';
				}
				if (status === REFUSED_LEVEL_2 || status === REFUSED_LEVEL_1) {
					return '#ea4e2c';
				}
			};
			const messageStatusAuth = `${
				this.getStatusItemSendToPaie().status
					? `<div style="display: flex; flex-direction: row;align-items: center; gap: 7px; width: 100%; text-align: start">
											<div style="width: 5px; height: 5px; margin-left: 5px;background-color: #bf55ec; border-radius: 50%"></div>
											<div class="d-flex align-items-center justify-content-start p-1">${this.FormMSG(20, 'This prestation has already been sent to pyroll')}</div>
										</div>`
					: ''
			}`;
			if (isShowComment) {
				return `<div style="display: flex; flex-direction: column; flex-wrap: wrap; align-items: start; justify-content: center; width: auto;" class="v-bootstrap-tooltip-custom-info">
							<div style="display: flex; flex-direction: row;align-items: center; gap: 7px; width: 100%">
								<div style="width: 5px; height: 5px; margin-left: 5px;background-color: ${setColor()}; border-radius: 50%"></div>
								<div class="d-flex align-items-center justify-content-start p-1">${messageStatusType}</div>
							</div>
							<div style="display: flex; flex-direction: row;align-items: start; gap: 2px; height: auto;  text-align: left; border-top: 1px solid #ffffff">
								<div style="display: flex; align-items: center; justify-content: start; width: 20px; margin-top: 4px">
									<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-message-circle-more">
										<path d="M7.9 20A9 9 0 1 0 4 16.1L2 22Z"/><path d="M8 12h.01"/><path d="M12 12h.01"/>
										<path d="M16 12h.01"/>
									</svg>
								</div>
								<div style="text-transform: initial; width: 175px;" class="p-1">${item.comment}</div>
							</div>
							${messageStatusAuth}
						</div>`;
			} else {
				return `<div style="display: flex; flex-direction: column; flex-wrap: wrap; align-items: start; justify-content: center; width: auto;" class="v-bootstrap-tooltip-custom-info">
							<div style="display: flex; flex-direction: row;align-items: center; gap: 7px;">
								<div style="width: 5px; height: 5px; margin-left: 5px;background-color:  ${setColor()}; border-radius: 50%"></div>
								<div class="d-flex align-items-center justify-content-start p-1">${messageStatusType}</div>
							</div>
							${messageStatusAuth}
						</div>`;
			}
		},
		getLabelStatus(labelStatus) {
			const item = this.fimalacCellItem;
			const status = item ? +item.validated : null;
			return this.setTooltipStatusInformation(status, labelStatus, item);
		},
		getLabelStatusSendToPaie() {
			const fimalacCellItem = this.fimalacCellItem;
			const { validated } = fimalacCellItem;
			const item = fimalacCellItem;
			const text = this.GetTextFromMenuNumberAndMenuValue(1008, validated);
			const newLabelStatus = this.setTextInfoBullWorkFlow(text);
			const status = validated;
			return this.setTooltipStatusInformation(status, newLabelStatus, item);
		},
		fimalacCellData() {
			const { SENT_TO_PAY } = INTERNAL_STATUS;
			const key = this.keyCell;
			const _data = this.arrayTsDays;
			if (_data !== undefined) {
				const result = _data.filter((item) => {
					const isValidCell = formatDateConverted(item.date) === key;
					if (isValidCell) {
						return true;
					}
					return false;
				});
				if (result[0] !== undefined) {
					const { hasAskToDeleteInDetails, internalStatus } = result[0];
					this.hasAskToDeleteInDetails = hasAskToDeleteInDetails;
					this.isSendToPaie = internalStatus === SENT_TO_PAY;
					const response = result[0].tsDayDetails.map((option) => {
						return { ...option, color: '#' + option.projectFlagItem.color };
					});
					return response;
				}
			}
		},
		tooltipContentOvertime() {
			const data = this.fimalacCellData();
			let flagProject =
				data &&
				data.slice(3).map((item) => {
					if (this.isPerDiem) {
						return `<div class="d-flex flex-row align-items-center p-1" style="gap: 10px">
									<div style="width: 5px; height: 5px; background-color: ${item.color}; border-radius: 50%"></div>
									<div>${this.labelTextPerDiem(item)}</div>
									<div class="${item.hasChangesAfterPay ? this.generateAnimation(item) : ''}"></div>
								</div>`;
					}
					if (this.isAnalyTicalCode) {
						return `<div class="d-flex flex-row align-items-center p-1" style="gap: 10px">
									<div style="width: 5px; height: 5px; background-color: ${item.color}; border-radius: 50%"></div>
									<div>${this.labelTextAnalitical(item)}</div>
									<div class="${item.hasChangesAfterPay ? this.generateAnimation(item) : ''}"></div>
								</div>`;
					}
					if (this.isPrestations) {
						return `<div class="d-flex flex-row align-items-center p-1" style="gap: 10px">
									<div style="width: 5px; height: 5px; background-color: ${item.color}; border-radius: 50%"></div>
									<div>${this.labelTextPrestation(item)}</div>
									<div class="${item.hasChangesAfterPay ? this.generateAnimation(item) : ''}"></div>
								</div>`;
					}
				}).join``;
			return `${flagProject}`;
		},
		setClassIsAskToDelete() {
			const status = this.isAskToDeleteStatus();
			if (status === 0) {
				return 'vertical-lines-manager';
			} else if (status === 1) {
				return 'vertical-lines-prod';
			}
			return '';
		},
		isAskToDeleteStatus() {
			const key = this.keyCell;
			const { DEFAULT_ASK } = TYPE_ASK_TO_DELETE;
			const _data = this.arrayTsDays;
			if (_data !== undefined) {
				let result = _data.filter((item) => {
					const isValidCell = formatDateConverted(item.date) === key;
					if (isValidCell) {
						return true;
					}
					return false;
				});
				if (result.length !== 0) {
					const status = result[0].askToDeletes;
					return status;
				}
				return DEFAULT_ASK;
			}
		},
		generateAnimation(item) {
			if (item) {
				const color = item.color;
				const isColorLight = this.isColorLight(color);
				return isColorLight ? 'rounded-sonar-light-animation' : 'rounded-sonar-not-light-animation';
			}
			return '';
		},
		isColorLight(color) {
			const hex = color.replace('#', '');
			const red = parseInt(hex.substring(0, 0 + 2), 16);
			const green = parseInt(hex.substring(2, 2 + 2), 16);
			const blue = parseInt(hex.substring(4, 4 + 2), 16);
			const brightness = (red * 299 + green * 587 + blue * 114) / 1000;
			return brightness > 155;
		}
	}
};
</script>
<style scoped lang="scss">
section {
	height: 100%;
}
@keyframes sonar {
	0% {
		transform: scale(0.6);
		opacity: 1;
	}
	100% {
		transform: scale(1.2);
		opacity: 0;
	}
}
@keyframes pulse {
	0% {
		transform: scale(1);
	}
	20% {
		transform: scale(0.9);
	}
	50% {
		transform: scale(1);
	}
	80% {
		transform: scale(0.6);
	}
	100% {
		transform: scale(1);
	}
}
.status-not-submitted {
	width: 12px;
	height: 12px;
	border-radius: 50%;
	background-color: #fcede9;
	border: 1px solid #ea4e2c;
	animation: pulse 1.5s infinite;
}
.status-not-submitted:after {
	width: 12px;
	height: 12px;
	border-radius: 50%;
	background-color: #ea4e2c;
	animation: sonar 1.5s infinite;
}
.status-info {
	width: 12px;
	height: 12px;
	border-radius: 2px;
	background-color: #bdd5f3;
	border: 1px solid #225cbd;
}
.status-pending {
	width: 12px;
	height: 12px;
	border-radius: 2px;
	background-color: #f7eac6;
	border: 1px solid #cd9a16;
}
.status-validated {
	width: 12px;
	height: 12px;
	border-radius: 2px;
	background-color: #caf5ba;
	border: 1px solid #3c7442;
}
.status-refused {
	width: 12px;
	height: 12px;
	border-radius: 2px;
	background-color: #ffb0b0;
	border: 1px solid #980000;
}
.status-sent {
	width: 12px;
	height: 12px;
	border-radius: 2px;
	background-color: #d5d6d7;
	border: 1px solid #687887;
}
.ml-rem-1 {
	padding-left: 0.2rem !important;
}
.mr-rem-1 {
	padding-right: 0.2rem !important;
}
@mixin backgroundImages {
	--c: #ea4e2c; /* color */
	--t: 1.2px; /* thickness of the lines */
	--g: 7px; /* gap between lines */
	--d: 6px; /* size of the dashes*/
	content: '';
	position: absolute;
	z-index: 1;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: conic-gradient(at var(--t) 50%, transparent 75%, var(--c) 0) 0 / var(--g) var(--d);
}
.vertical-lines-manager::before {
	@include backgroundImages();
}
.vertical-lines-prod::before {
	@include backgroundImages();
	background: conic-gradient(at var(--t) 50%, transparent 75%, var(--c) 0) 0 / var(--g) var(--d),
		url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"><line x1="0" y1="0" x2="100%" y2="100%" stroke="%23ea4e2c" stroke-width="2"/></svg>');
}
.content-status {
	background: rebeccapurple;
}
.cell-info {
	position: inherit;
	height: 100%;
}
.w-default {
	height: 100%;
	width: 100%;
}
</style>
<style lang="scss">
$bg-default-popover: #000 !important;

.btn-custom-ask-detail {
	display: flex;
	align-items: center;
	justify-content: center;
	outline: none;
	border: none;
	width: 32px;
	height: 20px;
	background: none;
	svg {
		z-index: 1 !important;
	}
}

.custom-popover-ask-hover-for-manager {
	&:hover {
		background-color: #ea4e2c;
		svg {
			fill: #ffffff;
		}
	}
}

.custom-popover-ask-hover-for-level-one {
	&:hover {
		background-color: #ea4;
		svg {
			fill: #ffffff;
		}
	}
}

.custom-popover-ask-hover-default {
	&:hover {
		background-color: #ffffff;
		color: #000000;
		svg {
			fill: #000000;
		}
	}
}

.v-bootstrap-popover-custom {
	.arrow::after {
		border-right-color: $bg-default-popover;
		border-left-color: $bg-default-popover;
		opacity: 0.9 !important;
		color: #fff !important;
	}
	.popover-body {
		background-color: $bg-default-popover;
		max-width: 450px !important;
		padding: 0.25rem 0.5rem !important;
		text-align: center;
		border-radius: 0.25rem;
		opacity: 0.9 !important;
		color: #fff !important;
	}
}
.tooltip-inner:has(.v-bootstrap-tooltip-custom) {
	max-width: 450px !important;
	padding: 0.25rem 0.5rem !important;
	color: #fff !important;
	text-align: center !important;
	background-color: #000 !important;
	border-radius: 0.25rem !important;
	margin-left: -100px !important;
}
.tooltip-inner:has(.v-bootstrap-tooltip-custom-info) {
	max-width: 250px !important;
	padding: 0.25rem 0.5rem !important;
	color: #fff !important;
	text-align: center !important;
	background-color: #000 !important;
	border-radius: 0.25rem !important;
	margin-left: -100px !important;
	margin-top: -8px !important;
}
.pop-info:has(.arrow) {
	top: -12px !important;
}

.refused-content {
	position: relative;
}
.refused-badge {
	position: absolute !important;
	bottom: 10px;
	left: 18px;
}
.badge-send-paie-item {
	color: #fff;
	border: 1px solid #bf55ec;
	background-color: #fff;
}
.badge-item {
	color: #fff;
	background-color: #ea4e2c;
}
.content-item-cell-fixed {
	display: flex;
	justify-content: start;
	width: 100%;
	height: 100px;
	border-radius: 2px;
	gap: 2px;
}
.content-item-cell-is-prod {
	width: 100%;
	height: 110px;
	border-radius: 4px;
	gap: 2px;
	border: 6.5px solid #ea4e2c;
}
.content-item-cell-is-prod:hover {
	border: none;
}
.loader-content {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
}
/* #273b1f73 */
/* #101010f0 */
/* #07631b73 */
.loader {
	width: 30px;
	--b: 8px;
	aspect-ratio: 1;
	border-radius: 50%;
	padding: 5px;
	background: conic-gradient(#0000 10%, #101010f0) content-box;
	-webkit-mask: repeating-conic-gradient(#0000 0deg, #000 1deg 20deg, #0000 21deg 36deg),
		radial-gradient(farthest-side, #0000 calc(100% - var(--b) - 1px), #000 calc(100% - var(--b)));
	-webkit-mask-composite: destination-in;
	mask-composite: intersect;
	animation: l4 1s infinite steps(10);
}
@keyframes l4 {
	to {
		transform: rotate(1turn);
	}
}

@mixin dash-border-animation($color, $duration) {
	border: 0.4rem dashed $color;
	animation: dash $duration linear infinite;
	@keyframes dash {
		0% {
			border-color: $color;
			border-right-color: transparent;
		}
		25% {
			border-bottom-color: transparent;
		}
		50% {
			border-left-color: transparent;
		}
		75% {
			border-top-color: transparent;
		}
		100% {
			border-color: $color;
		}
	}
}

.dashed-border {
	height: 106px;
	@include dash-border-animation(#025755, 2s);
}

$isLight: #ea4e2c;
$backgroundColrIsLight: #fcede9;
$isNotLight: #e7f2ff;
$backgroundColrIsNotLight: #6c98e2;

@mixin default-option {
	width: 10px;
	height: 10px;
	border-radius: 50%;
}

@mixin sonar-mixin-after($color) {
	background-color: $color;
	animation: sonar 1.5s infinite;
}

@mixin light-animation($color, $backgroundColor) {
	@include default-option;
	background-color: $backgroundColor;
	border: 1px solid $color;
	animation: pulse 1.5s infinite;
}
.rounded-sonar-light-animation {
	@include light-animation($isLight, $backgroundColrIsLight);
	&:after {
		@include default-option;
		@include sonar-mixin-after($isLight);
	}
}

.rounded-sonar-not-light-animation {
	@include light-animation($isNotLight, $backgroundColrIsNotLight);
	&:after {
		@include default-option;
		@include sonar-mixin-after($isNotLight);
	}
}

@keyframes sonar {
	0% {
		transform: scale(0.6);
		opacity: 1;
	}
	100% {
		transform: scale(1.2);
		opacity: 0;
	}
}

@keyframes pulse {
	0% {
		transform: scale(1);
	}
	20% {
		transform: scale(0.9);
	}
	50% {
		transform: scale(1);
	}
	80% {
		transform: scale(0.6);
	}
	100% {
		transform: scale(1);
	}
}
</style>
