<template>
	<div>
		<ContextMenuPlanning ref="menu" :model="mapItems" :baseZIndex="999" />
		<div>
			<b-tooltip custom-class="tooltip-custom-context-menu" :show.sync="showTooltip" :target="`info-delete`" placement="right">{{ message }}</b-tooltip>
		</div>
	</div>
</template>
<script>
import languageMessages from '@/mixins/languageMessages';
import globalMixin from '@/mixins/global.mixin';
import isSingleProjectMixin from '@/mixins/isSingleProject.mixin';
import ContextMenu from 'primevue/contextmenu';
import { OPTION_CONTEXT_MENU, PLANNING_LEVEL, STATUS_VALIDATE_PLANNING, VALIDATION_TYPE, TYPE_ASK_TO_DELETE, INTERNAL_STATUS } from '@/shared/constants';

export default {
	name: 'ContextMenu',
	components: { ContextMenuPlanning: ContextMenu },
	mixins: [languageMessages, isSingleProjectMixin, globalMixin],
	props: {},
	data: () => {
		return {
			optionContextMenu: null,
			contextMenuItemsSingle: [],
			contextMenuItemsMulti: [],
			showTooltip: false,
			messageList: null,
			message: ''
		};
	},
	watch: {
		optionContextMenu: {
			handler(newVal) {
				return newVal;
			},
			immediate: true,
			deep: true
		},
		mapItems: {
			async handler(newVal) {
				if (newVal.length !== 0) {
					this.messageList = {
						0: this.FormMSG(
							16,
							'if you want to delete, some data should not be deleted permanently because in the selected schedule there were items to ask for deletion'
						),
						1: this.FormMSG(
							17,
							'if you want to validate the deletion, some data which has been requested for deletion from the manager must also validate'
						)
					};
					const res = await new Promise((resolve, _) => {
						resolve(newVal);
					});
					await Promise.all(res).then(() => {
						let menuX = 0;
						let menuY = 0;
						const { offsetTop: offsetTopAppFooter } = document.getElementsByClassName('app-footer')[0];
						const { tableRect, event: eventTable, widthCell, isFixedAppBar } = this.optionContextMenu;
						const { width: widthTable, height: heightTable } = tableRect;
						const {
							width: menuWidth,
							height: menuHeight,
							top: topAfterShow,
							bottom: bottomAfterShow
						} = this.$refs['menu'].$el.getBoundingClientRect();
						const clickX = eventTable.clientX;
						const clickY = eventTable.clientY;
						const sideBarShow = document.getElementsByClassName('sidebar-sm-show')[0];
						const { width: sideBarWidthActif } = document.getElementsByClassName('sidebar')[0].getBoundingClientRect();
						if (clickX + menuWidth > widthTable) {
							const left = widthTable - menuWidth - widthCell;
							menuX = left;
						} else {
							if (sideBarShow) {
								const left = clickX - menuWidth;
								menuX = left;
							} else {
								const left = clickX + sideBarWidthActif - menuWidth;
								menuX = left;
							}
						}
						if (isFixedAppBar) {
							if (Math.floor(clickY + menuHeight - 24) > heightTable) {
								menuY = clickY + 80;
							} else {
								const isDiff = topAfterShow - clickY;
								menuY = clickY - isDiff + 10;
							}
						} else {
							if (clickY + menuHeight > offsetTopAppFooter) {
								const stayOfContextMenuMasqued = bottomAfterShow + menuHeight - offsetTopAppFooter - 100;
								menuY = clickY - 120 - stayOfContextMenuMasqued;
							} else {
								const isDiff = topAfterShow - clickY;
								menuY = clickY - isDiff + 10;
							}
						}
						this.$refs['menu'].$el.style.setProperty('left', `${menuX + 1}px`, 'important');
						this.$refs['menu'].$el.style.setProperty('top', `${menuY + 1}px`, 'important');
						const contextHtml = this.$refs['menu'];
						if (contextHtml) {
							const mapOptions = [...contextHtml.$options.propsData.model].filter(({ visible }) => visible);
							const indexContentInfo = mapOptions.findIndex((item) => item && item.info);
							if (indexContentInfo !== -1) {
								const { index } = mapOptions[indexContentInfo];
								this.message = this.messageList[index];
								const newSpan = document.createElement('span');
								newSpan.setAttribute('id', 'info-delete');
								newSpan.classList.add('content-info');
								if (
									contextHtml.$el &&
									contextHtml.$el.children &&
									contextHtml.$el.children[0].children &&
									contextHtml.$el.children[0].children[indexContentInfo]
								) {
									const response = contextHtml.$el.children[0].children[indexContentInfo].children[0];
									response.appendChild(newSpan);
									document.getElementById('info-delete').addEventListener('mouseover', this.onActiveTooltip);
									document.getElementById('info-delete').addEventListener('mouseleave', this.onInactiveTooltip);
								}
							}
						}
					});
				} else {
					this.messageList = null;
					this.message = '';
					document.removeEventListener('mouseover', this.onActiveTooltip);
					document.removeEventListener('mouseleave', this.onInactiveTooltip);
				}
				return newVal;
			},
			immediate: true,
			deep: true
		}
	},
	created() {},
	computed: {
		mapItems() {
			if (this.optionContextMenu) {
				const { showMenu, showMenuMultiple } = this.optionContextMenu;
				if (showMenu) return this.setOptionSingle();
				if (showMenuMultiple) return this.setOptionMulti();
				return [];
			}
			return [];
		}
	},
	methods: {
		onActiveTooltip() {
			this.showTooltip = true;
		},
		onInactiveTooltip() {
			this.showTooltip = false;
		},
		setOptionSingle() {
			const {
				isProduction,
				isSolve,
				isValid,
				isReject,
				isGestionnaryOfPaie,
				onMenuItemClick,
				hideContextMenuStopPropagation,
				isAskToDeleteSelected,
				textToCopy,
				currentValidationLevel,
				checkIsSelectMultipleAndOnly,
				statusPlanningValidateAll,
				checkDeletePlanning,
				isExistCell,
				isDeleting,
				rowItem,
				isAuthTracing,
				isAuthaurizeUpdate,
				defaultCopyReplace
			} = this.optionContextMenu;
			const { FOR_MANAGER } = TYPE_ASK_TO_DELETE;
			const { SENT_TO_PAY } = INTERNAL_STATUS;
			const {
				SOLVE_UPDATE,
				SEE_INFO,
				COPY,
				PASTE,
				CLEAR_COPY,
				TRACE,
				UNTRACE,
				REPLACE,
				DELETE,
				ASK_DELETE,
				UNDELETE,
				SEND_FOR_VALIDATION,
				VALIDATE,
				REJECT
			} = OPTION_CONTEXT_MENU;
			const { ADM_PROD, CHARG_PROD } = PLANNING_LEVEL;
			const { DEFAULT_STATUS } = STATUS_VALIDATE_PLANNING;
			const { NOT_SUBMITTED } = VALIDATION_TYPE;
			const { redoTrace, undoTrace } = isAuthTracing;
			const { DELETE: isActionDelete, UNDELETE: isActionUndelete, ASK_DELETE: isActionAskDelete } = isAskToDeleteSelected;
			const { isCopy, isReplace } = defaultCopyReplace;
			const { tsDay } = rowItem[0];
			let isNotSubmitted = false;
			let isManagerAskToDelete = false;
			if (tsDay) {
				const { validated, internalStatus, askToDeletes } = tsDay;
				isNotSubmitted = validated === NOT_SUBMITTED ? true : false;
				isManagerAskToDelete = currentValidationLevel === CHARG_PROD && internalStatus === SENT_TO_PAY && askToDeletes === FOR_MANAGER ? true : false;
			}
			let option = [
				{
					label: this.FormMSG(8, 'Cancel'),
					icon: 'pi pi-fw pi-power-off custom-icon-power-off',
					visible: true,
					command: () => hideContextMenuStopPropagation()
				}
			];
			const mapRejectValidateProd = () => {
				const isAuthorized = (status) => {
					return status;
				};
				return [
					{
						label: this.FormMSG(14, 'Validate'),
						icon: 'pi pi-fw pi-check-square custom-icon-check-square',
						visible: isExistCell && isAuthorized(isValid),
						command: () => onMenuItemClick(VALIDATE)
					},
					{
						label: this.FormMSG(15, 'Reject'),
						icon: 'pi pi-fw pi-times custom-icon-x',
						visible: isExistCell && isAuthorized(isReject),
						command: () => onMenuItemClick(REJECT)
					}
				];
			};

			const mapActionDeleteManager = () => {
				return [
					{
						label: this.FormMSG(6, 'Delete'),
						icon: 'pi pi-fw pi-trash custom-icon-trash',
						visible: checkDeletePlanning && isExistCell && isActionDelete && currentValidationLevel !== ADM_PROD,
						command: () => onMenuItemClick(DELETE)
					},
					{
						label: this.FormMSG(103, 'Ask for deleting'),
						icon: 'pi pi-fw pi-file-excel custom-icon-signature',
						visible: checkDeletePlanning && isExistCell && isActionAskDelete,
						command: () => onMenuItemClick(ASK_DELETE)
					},
					{
						label: this.FormMSG(104, 'Undelete'),
						icon: 'pi pi-fw pi-undo custom-icon-undo',
						visible: checkDeletePlanning && isExistCell && isActionUndelete,
						command: () => onMenuItemClick(UNDELETE)
					}
				];
			};
			if (isProduction) {
				const mapActionDelete = () => {
					const isAuthToDelete =
						isGestionnaryOfPaie && checkDeletePlanning && isExistCell && isDeleting
							? true
							: checkDeletePlanning && isExistCell && isActionDelete && currentValidationLevel !== ADM_PROD && isDeleting;
					const isAuthAskToDelete = isGestionnaryOfPaie
						? false
						: checkDeletePlanning && isExistCell && isActionAskDelete && currentValidationLevel !== ADM_PROD && isDeleting;
					const isAuthUndeleted = checkDeletePlanning && isExistCell && isActionUndelete;
					return [
						{
							label: this.FormMSG(6, 'Delete'),
							icon: 'pi pi-fw pi-trash custom-icon-trash',
							visible: isAuthToDelete,
							command: () => onMenuItemClick(DELETE)
						},
						{
							label: isManagerAskToDelete ? this.FormMSG(110, 'Valid ask to delete') : this.FormMSG(103, 'Ask for deleting'),
							icon: 'pi pi-fw pi-file-excel custom-icon-signature',
							visible: isAuthAskToDelete,
							command: () => onMenuItemClick(ASK_DELETE)
						},
						{
							label: this.FormMSG(104, 'Undelete'),
							icon: 'pi pi-fw pi-undo custom-icon-undo',
							visible: isAuthUndeleted,
							command: () => onMenuItemClick(UNDELETE)
						}
					];
				};
				option = [
					{
						label: this.FormMSG(22, 'Solve'),
						icon: 'pi pi-fw pi-check-circle custom-icon-check-circle',
						visible: isExistCell && isSolve && isGestionnaryOfPaie === false && isAuthaurizeUpdate && currentValidationLevel !== ADM_PROD,
						command: () => onMenuItemClick(SOLVE_UPDATE)
					},
					{
						label: this.FormMSG(7, 'Update'),
						icon: 'pi pi-fw pi-pencil custom-icon-pencil',
						visible: isExistCell && !isSolve && isAuthaurizeUpdate,
						command: () => onMenuItemClick(SOLVE_UPDATE)
					},
					...mapRejectValidateProd(),
					{
						label: this.FormMSG(11, 'See info'),
						icon: 'pi pi-fw pi-eye custom-icon-eye',
						visible: isExistCell,
						command: () => onMenuItemClick(SEE_INFO)
					},
					{
						label: this.FormMSG(106, 'Copy'),
						icon: 'pi pi-fw pi-copy custom-icon-copy',
						visible: isExistCell && isGestionnaryOfPaie === false && isCopy && currentValidationLevel !== ADM_PROD,
						command: () => onMenuItemClick(COPY)
					},
					{
						label: this.FormMSG(107, 'Paste'),
						icon: 'pi pi-fw pi-clone custom-icon-clone',
						visible: textToCopy.length !== 0,
						command: () => onMenuItemClick(PASTE)
					},
					{
						label: this.FormMSG(108, 'Clear copy'),
						icon: 'pi pi-fw pi-tag custom-icon-clear-copy',
						visible: textToCopy.length !== 0,
						command: () => onMenuItemClick(CLEAR_COPY)
					},
					{
						label: this.FormMSG(109, 'Trace'),
						icon: 'pi pi-fw pi-star-fill custom-icon-trace',
						visible: isExistCell && redoTrace,
						command: () => onMenuItemClick(TRACE)
					},
					{
						label: this.FormMSG(112, 'Un-trace'),
						icon: 'pi pi-fw pi-star-fill custom-icon-untrace',
						visible: isExistCell && undoTrace,
						command: () => onMenuItemClick(UNTRACE)
					},
					{
						label: this.FormMSG(20, 'Replacement'),
						icon: 'pi pi-fw pi-users custom-icon-users',
						visible: isExistCell && isGestionnaryOfPaie === false && isReplace && !isActionUndelete && currentValidationLevel !== ADM_PROD,
						command: () => onMenuItemClick(REPLACE)
					},
					...mapActionDelete(),
					...option
				];
				return option;
			} else {
				const isSendForValidation =
					statusPlanningValidateAll !== DEFAULT_STATUS
						? checkIsSelectMultipleAndOnly && isExistCell && statusPlanningValidateAll !== DEFAULT_STATUS && isDeleting && isNotSubmitted
						: false;
				return [
					{
						label: this.FormMSG(12, 'Send For Validation'),
						icon: 'pi pi-fw pi-send custom-icon-send',
						visible: isSendForValidation,
						command: () => onMenuItemClick(SEND_FOR_VALIDATION)
					},
					{
						label: this.FormMSG(7, 'Update'),
						icon: 'pi pi-fw pi-pencil custom-icon-pencil',
						visible: isExistCell && isAuthaurizeUpdate,
						command: () => onMenuItemClick(SOLVE_UPDATE)
					},
					{
						label: this.FormMSG(11, 'See info'),
						icon: 'pi pi-fw pi-eye custom-icon-eye',
						visible: isExistCell,
						command: () => onMenuItemClick(SEE_INFO)
					},
					{
						label: this.FormMSG(106, 'Copy'),
						icon: 'pi pi-fw pi-copy custom-icon-copy',
						visible: isExistCell && isGestionnaryOfPaie === false && isCopy && currentValidationLevel !== ADM_PROD,
						command: () => onMenuItemClick(COPY)
					},
					{
						label: this.FormMSG(107, 'Paste'),
						icon: 'pi pi-fw pi-clone custom-icon-clone',
						visible: textToCopy.length !== 0,
						command: () => onMenuItemClick(PASTE)
					},
					{
						label: this.FormMSG(108, 'Clear copy'),
						icon: 'pi pi-fw pi-tag custom-icon-clear-copy',
						visible: textToCopy.length !== 0,
						command: () => onMenuItemClick(CLEAR_COPY)
					},
					{
						label: this.FormMSG(109, 'Trace'),
						icon: 'pi pi-fw pi-star-fill custom-icon-trace',
						visible: isExistCell && redoTrace && currentValidationLevel !== ADM_PROD,
						command: () => onMenuItemClick(TRACE)
					},
					{
						label: this.FormMSG(112, 'Un-trace'),
						icon: 'pi pi-fw pi-star-fill custom-icon-untrace',
						visible: isExistCell && undoTrace && currentValidationLevel !== ADM_PROD,
						command: () => onMenuItemClick(UNTRACE)
					},
					{
						label: this.FormMSG(20, 'Replacement'),
						icon: 'pi pi-fw pi-users custom-icon-users',
						visible: isExistCell && isReplace && !isActionUndelete && currentValidationLevel !== ADM_PROD,
						command: () => onMenuItemClick(REPLACE)
					},
					...mapActionDeleteManager(),
					...option
				];
			}
		},
		setOptionMulti() {
			const {
				isProduction,
				onMenuItemClick,
				hideContextMenuStopPropagation,
				isAskToDeleteSelected,
				textToCopy,
				currentValidationLevel,
				checkCellWhenSelected,
				checkDeletePlanning,
				isDeleting,
				checkIsSelectMultipleAndOnly,
				isExistCell,
				statusPlanningValidateAll,
				isGestionnaryOfPaie,
				isValid,
				isReject,
				rowItem,
				isAuthTracing,
				isAuthaurizeUpdate
			} = this.optionContextMenu;
			const { FOR_MANAGER } = TYPE_ASK_TO_DELETE;
			const { SENT_TO_PAY } = INTERNAL_STATUS;
			const {
				SOLVE_UPDATE,
				PASTE,
				CLEAR_COPY,
				TRACE,
				UNTRACE,
				DELETE,
				UNDELETE,
				ASK_DELETE,
				SEND_FOR_VALIDATION,
				REJECT,
				VALIDATE_ALL,
				SEE_INFO_MULTIPLE
			} = OPTION_CONTEXT_MENU;
			const { DEFAULT_STATUS } = STATUS_VALIDATE_PLANNING;
			const { ADM_PROD, MANAGER } = PLANNING_LEVEL;
			const { DELETE: isActionDelete, UNDELETE: isActionUndelete, ASK_DELETE: isActionAskDelete, isHaveContent } = isAskToDeleteSelected;
			const { redoTrace, undoTrace } = isAuthTracing;
			const checkAskStatus = (rowItem, invert = false) => {
				const matches = rowItem
					.map(({ tsDay }) => tsDay)
					.filter(Boolean)
					.every(
						({ internalStatus, askToDeletes }) =>
							internalStatus === SENT_TO_PAY && (invert ? askToDeletes !== FOR_MANAGER : askToDeletes === FOR_MANAGER)
					);
				return { matches };
			};
			const { matches: isAskAllFromManger } = checkAskStatus(rowItem);
			const { matches: isNotAskFromManager } = checkAskStatus(rowItem, true);
			const isAskHaveContent = !isAskAllFromManger && !isNotAskFromManager;
			const isAuthorized = (status) => {
				return status;
			};

			const mapRejectValidateProd = () => {
				return [
					{
						label: this.FormMSG(7, 'Update'),
						icon: 'pi pi-fw pi-pencil custom-icon-pencil',
						visible: checkCellWhenSelected && isAuthaurizeUpdate,
						command: () => onMenuItemClick(SOLVE_UPDATE)
					},
					{
						label: this.FormMSG(21, 'Validate(s)'),
						icon: 'pi pi-fw pi-check-square custom-icon-check-square',
						visible: currentValidationLevel !== MANAGER && isAuthorized(isValid),
						command: () => onMenuItemClick(VALIDATE_ALL)
					},

					{
						label: this.FormMSG(15, 'Reject'),
						icon: 'pi pi-fw pi-times custom-icon-x',
						visible: checkCellWhenSelected && isDeleting && isAuthorized(isReject),
						command: () => onMenuItemClick(REJECT)
					},
					{
						label: this.FormMSG(105, 'See info multiple'),
						icon: 'pi pi-fw pi-eye custom-icon-eye',
						visible: checkCellWhenSelected,
						command: () => onMenuItemClick(SEE_INFO_MULTIPLE)
					}
				];
			};
			const mapDeleteOptionProd = () => {
				const isAuthToDelete =
					isGestionnaryOfPaie && checkDeletePlanning && isDeleting
						? true
						: checkDeletePlanning && isDeleting && isActionDelete && currentValidationLevel !== ADM_PROD;
				return [
					{
						index: 0,
						label: this.FormMSG(6, 'Delete'),
						icon: 'pi pi-fw pi-trash custom-icon-trash',
						info: isGestionnaryOfPaie ? false : isHaveContent,
						visible: isAuthToDelete,
						command: () => onMenuItemClick(DELETE)
					},
					{
						index: 1,
						label: isAskAllFromManger ? this.FormMSG(110, 'Valid ask to delete') : this.FormMSG(103, 'Ask for deleting'),
						icon: 'pi pi-fw pi-file-excel custom-icon-signature',
						info: isAskHaveContent,
						visible: checkDeletePlanning && isExistCell && isActionAskDelete,
						command: () => onMenuItemClick(ASK_DELETE)
					}
				];
			};
			const rejectValidateManager = () => {
				const isSendForValidation = statusPlanningValidateAll !== DEFAULT_STATUS ? checkIsSelectMultipleAndOnly && isExistCell : false;
				return [
					{
						label: this.FormMSG(12, 'Send For Validation'),
						icon: 'pi pi-fw pi-send custom-icon-send',
						visible: isSendForValidation,
						command: () => onMenuItemClick(SEND_FOR_VALIDATION)
					},
					{
						label: this.FormMSG(7, 'Update'),
						icon: 'pi pi-fw pi-pencil custom-icon-pencil',
						visible: checkCellWhenSelected && isAuthaurizeUpdate,
						command: () => onMenuItemClick(SOLVE_UPDATE)
					},
					{
						label: this.FormMSG(15, 'Reject'),
						icon: 'pi pi-fw pi-times custom-icon-x',
						visible: checkCellWhenSelected && isDeleting && isAuthorized(isReject),
						command: () => onMenuItemClick(REJECT)
					},
					{
						label: this.FormMSG(105, 'See info multiple'),
						icon: 'pi pi-fw pi-eye custom-icon-eye',
						visible: checkCellWhenSelected,
						command: () => onMenuItemClick(SEE_INFO_MULTIPLE)
					}
				];
			};
			const mapActionDeleteManager = () => {
				return [
					{
						index: 0,
						label: this.FormMSG(6, 'Delete'),
						icon: 'pi pi-fw pi-trash custom-icon-trash',
						info: isGestionnaryOfPaie ? false : isHaveContent,
						visible: checkDeletePlanning && isActionDelete,
						command: () => onMenuItemClick(DELETE)
					},
					{
						label: this.FormMSG(104, 'Undelete'),
						icon: 'pi pi-fw pi-undo custom-icon-undo',
						visible: checkDeletePlanning && isExistCell && isActionUndelete,
						command: () => onMenuItemClick(UNDELETE)
					},
					{
						label: this.FormMSG(103, 'Ask for deleting'),
						icon: 'pi pi-fw pi-file-excel custom-icon-signature',
						visible: checkDeletePlanning && isExistCell && isActionAskDelete,
						command: () => onMenuItemClick(ASK_DELETE)
					}
				];
			};
			let option = [
				{
					label: this.FormMSG(8, 'Cancel'),
					icon: 'pi pi-fw pi-power-off custom-icon-power-off',
					visible: true,
					command: () => hideContextMenuStopPropagation()
				}
			];
			if (isProduction) {
				option = [
					...mapRejectValidateProd(),
					{
						label: this.FormMSG(107, 'Paste'),
						icon: 'pi pi-fw pi-clone custom-icon-clone',
						visible: textToCopy.length !== 0,
						command: () => onMenuItemClick(PASTE)
					},
					{
						label: this.FormMSG(108, 'Clear copy'),
						icon: 'pi pi-fw pi-tag custom-icon-clear-copy',
						visible: textToCopy.length !== 0,
						command: () => onMenuItemClick(CLEAR_COPY)
					},
					...mapDeleteOptionProd(),
					{
						label: this.FormMSG(109, 'Trace'),
						icon: 'pi pi-fw pi-star-fill custom-icon-trace',
						visible: checkDeletePlanning && redoTrace,
						command: () => onMenuItemClick(TRACE)
					},
					{
						label: this.FormMSG(112, 'Un-trace'),
						icon: 'pi pi-fw pi-star-fill custom-icon-untrace',
						visible: checkDeletePlanning && undoTrace,
						command: () => onMenuItemClick(UNTRACE)
					},
					...option
				];
				return option;
			} else {
				option = [
					...rejectValidateManager(),
					{
						label: this.FormMSG(107, 'Paste'),
						icon: 'pi pi-fw pi-clone custom-icon-clone',
						visible: textToCopy.length !== 0,
						command: () => onMenuItemClick(PASTE)
					},
					{
						label: this.FormMSG(108, 'Clear copy'),
						icon: 'pi pi-fw pi-tag custom-icon-clear-copy',
						visible: textToCopy.length !== 0,
						command: () => onMenuItemClick(CLEAR_COPY)
					},
					...mapActionDeleteManager(),
					{
						label: this.FormMSG(109, 'Trace'),
						icon: 'pi pi-fw pi-star-fill custom-icon-trace',
						visible: isExistCell && redoTrace,
						command: () => onMenuItemClick(TRACE)
					},
					{
						label: this.FormMSG(112, 'Un-trace'),
						icon: 'pi pi-fw pi-star-fill custom-icon-untrace',
						visible: isExistCell && undoTrace,
						command: () => onMenuItemClick(UNTRACE)
					},
					...option
				];
				return option;
			}
		}
	}
};
</script>
<style lang="scss">
.custom-icon-check-circle,
.custom-icon-eye¨,
.custom-icon-copy,
.custom-icon-clone,
.custom-icon-clear-copy,
.custom-icon-users {
	color: #06263e !important;
}
.custom-icon-pencil,
.custom-icon-check-square,
.custom-icon-power-off,
.custom-icon-undo,
.custom-icon-send {
	color: #225cbd !important;
}
.custom-icon-trash,
.custom-icon-x,
.custom-icon-signature {
	color: #ea4e2c !important;
}
.custom-icon-trace::before {
	color: #ffd700;
}
.custom-icon-untrace::before {
	color: #74736b;
}
.p-contextmenu {
	width: auto;
	min-width: 200px;
}
.content-info {
	display: flex !important;
	align-items: center !important;
	justify-content: center !important;
	margin-left: 10px !important;
	margin-top: 2px !important;
	width: 20px !important;
	height: 18px !important;
	background: url('data:image/svg+xml;charset=UTF-8,%3Csvg%20xmlns="http://www.w3.org/2000/svg"%20width="16"%20height="16"%20viewBox="0%200%2024%2024"%20fill="none"%20stroke="%23225CBD"%20stroke-width="2.25"%20stroke-linecap="round"%20stroke-linejoin="round"%3E%3Ccircle%20cx="12"%20cy="12"%20r="10"%3E%3C/circle%3E%3Cline%20x1="12"%20y1="16"%20x2="12"%20y2="12"%3E%3C/line%3E%3Cline%20x1="12"%20y1="8"%20x2="12.01"%20y2="8"%3E%3C/line%3E%3C/svg%3E');
	background-repeat: no-repeat !important;
}
.tooltip-custom-context-menu {
	z-index: 9999 !important;
}
</style>
