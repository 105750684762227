var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.isShowSomme
    ? _c(
        "div",
        {
          ref: "headerRowTable",
          staticClass: "timeline-cursor",
          style: {
            width: _vm.isFixedColumn
              ? `${_vm.sizeCell + 50}px !important`
              : this.changePourcent
              ? "100%"
              : `${_vm.cellWidthResponsive}px`,
            minWidth: `${_vm.widthCell}px`,
            maxWidth: `${600}px`,
          },
        },
        [
          [
            _vm.isFixedStart
              ? _c("div", { staticClass: "w-100" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "d-flex flex-row align-items-center justify-content-center h-50 gap-10",
                    },
                    [
                      _c("div", { staticStyle: { width: "100%" } }, [
                        _vm._v(_vm._s(_vm.FormMSG(1, "Member of team"))),
                      ]),
                      _c(
                        "div",
                        [
                          _c(
                            "button",
                            {
                              staticClass:
                                "btn-transparent text-color-rhapsody-in-blue",
                              attrs: { id: "tooltip-target-7899456" },
                              on: { click: _vm.onAddUsers },
                            },
                            [
                              _c(
                                _vm.getLucideIcon(
                                  _vm.ICONS.PLUS_CIRCLE_USER.name
                                ),
                                {
                                  tag: "component",
                                  attrs: {
                                    color: _vm.ICONS.CHEVRONS_LEFT.color,
                                    size: 18,
                                  },
                                }
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-tooltip",
                            {
                              attrs: {
                                "custom-class": "v-bootstrap-new-user-tooltip",
                                target: "tooltip-target-7899456",
                                triggers: "hover",
                                placement: "top",
                              },
                            },
                            [
                              _vm._v(
                                "\n\t\t\t\t\t\t" +
                                  _vm._s(_vm.FormMSG(2, "Add new user")) +
                                  "\n\t\t\t\t\t"
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "d-flex flex-row align-items-center justify-content-center h-50",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "active-filter-custom",
                          staticStyle: { width: "100%" },
                        },
                        [
                          _c("treeselect", {
                            attrs: {
                              options: _vm.mapCustomFilter,
                              value: _vm.selectedFilter,
                              placeholder: _vm.FormMSG(3, "Select filter"),
                              clearable: false,
                            },
                            on: { select: _vm.handleChangeFilter },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "value-label",
                                  fn: function ({ node }) {
                                    return _c(
                                      "div",
                                      { attrs: { title: node.label } },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "treeselect-label" },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.showLimitText(node.label)
                                              )
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  },
                                },
                                {
                                  key: "option-label",
                                  fn: function ({ node }) {
                                    return _c(
                                      "div",
                                      {
                                        staticClass:
                                          "d-flex align-items-center justify-content-center",
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "label-select treeselect-label",
                                            attrs: { title: node.label },
                                          },
                                          [_vm._v(_vm._s(node.label))]
                                        ),
                                      ]
                                    )
                                  },
                                },
                              ],
                              null,
                              false,
                              2091592026
                            ),
                          }),
                          _vm.checkTypeFilter
                            ? _c("div", { staticClass: "clearable-custom" }, [
                                _c(
                                  "button",
                                  {
                                    directives: [
                                      {
                                        name: "b-tooltip",
                                        rawName:
                                          "v-b-tooltip.hover.bottom.html",
                                        value: _vm.FormMSG(9, "Clear filter"),
                                        expression:
                                          "FormMSG(9, 'Clear filter')",
                                        modifiers: {
                                          hover: true,
                                          bottom: true,
                                          html: true,
                                        },
                                      },
                                    ],
                                    on: { click: _vm.onClearFilter },
                                  },
                                  [_vm._v("x")]
                                ),
                              ])
                            : _vm._e(),
                        ],
                        1
                      ),
                      _vm.selectedFilter === 3
                        ? _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "b-tooltip",
                                  rawName: "v-b-tooltip.hover.bottom.html",
                                  value: _vm.FormMSG(
                                    4,
                                    "Active the last action filter"
                                  ),
                                  expression:
                                    "FormMSG(4, 'Active the last action filter')",
                                  modifiers: {
                                    hover: true,
                                    bottom: true,
                                    html: true,
                                  },
                                },
                              ],
                              staticClass: "timeline-cursor",
                              on: { click: _vm.handleActiveAction },
                            },
                            [
                              _c(_vm.getLucideIcon("GripVertical"), {
                                tag: "component",
                                attrs: { size: 20, "stroke-width": 2.25 },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]
                  ),
                ])
              : _c(
                  "div",
                  {
                    staticClass: "cursor-active",
                    attrs: { id: `cell-item-${_vm.index}` },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "d-flex flex-row justify-content-end w-100",
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "btn-hide-cell-header" },
                          [
                            _c(
                              "button",
                              {
                                staticClass: "btn-close-hide-header",
                                attrs: { id: `popover-${_vm.index}` },
                                on: {
                                  click: function ($event) {
                                    return _vm.hideColumn(_vm.item)
                                  },
                                },
                              },
                              [_vm._v("x")]
                            ),
                            _c(
                              "b-popover",
                              {
                                attrs: {
                                  "custom-class":
                                    "v-bootstrap-popover-header-column",
                                  target: `popover-${_vm.index}`,
                                  placement: "right",
                                  triggers: "hover focus",
                                },
                              },
                              [_vm._v(_vm._s(_vm.getTextHideColumn(_vm.item)))]
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                    _c("div", [_vm._v(_vm._s(_vm.item.label))]),
                    _c(
                      "div",
                      { staticClass: "d-flex flex-row justify-content-center" },
                      [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "b-tooltip",
                                rawName: "v-b-tooltip.hover.bottom.html",
                                value: _vm.setTimeLocation().active
                                  ? _vm.handleShowLocationTooltip()
                                  : _vm.FormMSG(10, "Add location"),
                                expression:
                                  "setTimeLocation().active ? handleShowLocationTooltip() : FormMSG(10, 'Add location')",
                                modifiers: {
                                  hover: true,
                                  bottom: true,
                                  html: true,
                                },
                              },
                            ],
                            class: {
                              "header-flag-status": true,
                              "gris-default": _vm.setTimeLocation().active,
                              "gris-net": !_vm.setTimeLocation().active,
                            },
                          },
                          [
                            _vm.setTimeLocation().active
                              ? _c("span", { staticClass: "custom-text" }, [
                                  _vm._v(_vm._s(_vm.setTimeLocation().message)),
                                ])
                              : _vm._e(),
                            _c(
                              "div",
                              {
                                staticClass: "show-edit-flag",
                                on: {
                                  click: function ($event) {
                                    return _vm.handleOpenFlagHeader()
                                  },
                                },
                              },
                              [
                                _c(
                                  _vm.getLucideIcon(
                                    _vm.ICONS.CHECK_SQUARE.name
                                  ),
                                  {
                                    tag: "component",
                                    attrs: { color: "#06263e", size: 16 },
                                  }
                                ),
                              ],
                              1
                            ),
                          ]
                        ),
                      ]
                    ),
                  ]
                ),
          ],
        ],
        2
      )
    : _c(
        "div",
        {
          staticClass: "timeline-cursor",
          style: {
            width: _vm.showVerified
              ? `${_vm.cellWidthResponsive}px !important`
              : "",
            minWidth: _vm.showVerified ? `${_vm.widthCell + 38}px` : "",
          },
        },
        [
          [
            _vm.showVerified
              ? _c(
                  "div",
                  {
                    staticClass:
                      "d-flex flex-column justify-content-center w-100 transition-collapse-rt-lf",
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "d-flex flex-row justify-content-center" },
                      [_vm._v(_vm._s(_vm.FormMSG(11, "Somme")))]
                    ),
                    _c(
                      "div",
                      { staticClass: "d-flex flex-row justify-content-center" },
                      [
                        _c(
                          "button",
                          {
                            staticClass:
                              "btn-transparent text-color-rhapsody-in-blue",
                            on: { click: _vm.handleShowContentVerified },
                          },
                          [
                            _c(
                              _vm.getLucideIcon(_vm.ICONS.CHEVRONS_RIGHT.name),
                              {
                                tag: "component",
                                attrs: {
                                  color: _vm.ICONS.CHEVRONS_RIGHT.color,
                                  size: 16,
                                },
                              }
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                  ]
                )
              : _c(
                  "div",
                  {
                    staticClass:
                      "d-flex flex-column w-100 transition-collapse-lf-rt",
                    staticStyle: {
                      display: "flex",
                      "background-color": "#78807a",
                    },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "d-flex flex-row justify-content-start" },
                      [
                        _c(
                          "button",
                          {
                            staticClass:
                              "btn-transparent text-color-rhapsody-in-blue",
                            on: { click: _vm.handleShowContentVerified },
                          },
                          [
                            _c(
                              _vm.getLucideIcon(_vm.ICONS.CHEVRONS_LEFT.name),
                              {
                                tag: "component",
                                attrs: {
                                  color: _vm.ICONS.CHEVRONS_LEFT.color,
                                  size: 16,
                                },
                              }
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                  ]
                ),
          ],
        ],
        2
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }