var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: {
        title: _vm.FormMSG(1, "Send to payroll option"),
        "header-class": "header-class-modal-doc-package",
        "hide-header-close": "",
        "no-close-on-backdrop": "",
        "no-close-on-esc": "",
        size: "lg",
        "dialog-class": "dialog-custom-payroll-config",
        "modal-class": "mui-animation",
        fade: false,
      },
      on: {
        ok: function ($event) {
          $event.preventDefault()
          return _vm.handleSave.apply(null, arguments)
        },
        cancel: function ($event) {
          $event.preventDefault()
          return _vm.emitEventClose.apply(null, arguments)
        },
      },
      scopedSlots: _vm._u([
        {
          key: "modal-footer",
          fn: function () {
            return [
              _c(
                "div",
                {
                  staticClass:
                    "w-100 d-flex justify-content-end align-items-center",
                },
                [
                  _c(
                    "b-button",
                    {
                      staticClass: "w-138-px mr-3",
                      staticStyle: { "margin-top": "5px" },
                      attrs: {
                        size: "md",
                        variant: "custom-outline-gray",
                        block: "",
                      },
                      on: { click: _vm.closeModalPayrollConfig },
                    },
                    [
                      _vm._v(
                        "\n\t\t\t\t" +
                          _vm._s(_vm.FormMSG(2, "Cancel")) +
                          "\n\t\t\t"
                      ),
                    ]
                  ),
                  _c(
                    "b-button",
                    {
                      staticClass: "w-138-px",
                      attrs: { size: "md", variant: "primary", block: "" },
                      on: { click: _vm.handleSave },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "d-flex justify-content-center align-items-center",
                        },
                        [
                          _c("div", { staticStyle: { "margin-top": "1px" } }, [
                            _vm._v(_vm._s(_vm.FormMSG(3, "Send"))),
                          ]),
                        ]
                      ),
                    ]
                  ),
                ],
                1
              ),
            ]
          },
          proxy: true,
        },
      ]),
      model: {
        value: _vm.open,
        callback: function ($$v) {
          _vm.open = $$v
        },
        expression: "open",
      },
    },
    [
      _c("div", { staticClass: "d-flex flex-column flex-1 w-100 gap-25" }, [
        _c("div", { staticClass: "d-flex flex-row" }, [
          _c(
            "div",
            { staticClass: "w-50" },
            [
              _c(
                "b-form-radio",
                {
                  attrs: { value: 0, name: "type-contract" },
                  model: {
                    value: _vm.typeContract,
                    callback: function ($$v) {
                      _vm.typeContract = $$v
                    },
                    expression: "typeContract",
                  },
                },
                [_vm._v(" " + _vm._s(_vm.FormMSG(7, "Long contract")) + " ")]
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "w-50" },
            [
              _c(
                "b-form-radio",
                {
                  attrs: { value: 1, name: "type-contract" },
                  model: {
                    value: _vm.typeContract,
                    callback: function ($$v) {
                      _vm.typeContract = $$v
                    },
                    expression: "typeContract",
                  },
                },
                [_vm._v(" " + _vm._s(_vm.FormMSG(5, "Short contract")) + " ")]
              ),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "d-flex flex-row flex-1 w-100" }, [
          _c(
            "div",
            { staticClass: "w-50 check-disabled" },
            [
              _c(
                "b-form-checkbox",
                {
                  class: { "disabled-checkbox": _vm.isActiveLockPlanning },
                  attrs: {
                    id: "lock-planning",
                    name: "lock-planning",
                    value: true,
                    "unchecked-value": false,
                    disabled: _vm.isActiveLockPlanning,
                  },
                  model: {
                    value: _vm.lockPlanning,
                    callback: function ($$v) {
                      _vm.lockPlanning = $$v
                    },
                    expression: "lockPlanning",
                  },
                },
                [
                  _vm._v(
                    "\n\t\t\t\t\t" +
                      _vm._s(_vm.FormMSG(6, "Lock planning until:")) +
                      "\n\t\t\t\t"
                  ),
                ]
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "w-50" },
            [
              _c("v-date-picker", {
                attrs: {
                  "available-dates": {
                    start: new Date(_vm.isDatePlanning),
                    end: null,
                  },
                  locale: _vm.lang,
                  masks: _vm.masks,
                  "model-config": {
                    type: "string",
                    mask: _vm.masks,
                    timezone: "UTC",
                  },
                },
                on: { dayclick: _vm.handleChangeDate },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ inputValue, togglePopover }) {
                      return [
                        _c(
                          "b-input-group",
                          [
                            _c("b-form-input", {
                              staticClass: "br-none",
                              attrs: { value: inputValue, readonly: "" },
                            }),
                            _c(
                              "b-input-group-append",
                              [
                                _c(
                                  "b-input-group-text",
                                  {
                                    staticClass:
                                      "cursor-pointer bg-color-white input-group-text-bg-white",
                                    on: {
                                      click: function ($event) {
                                        return togglePopover()
                                      },
                                    },
                                  },
                                  [
                                    _c(_vm.getLucideIcon("Calendar"), {
                                      tag: "component",
                                      attrs: {
                                        color: "rgba(6, 38, 62, .6)",
                                        size: 18,
                                        "stroke-width": 2.25,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
                model: {
                  value: _vm.payrollDate,
                  callback: function ($$v) {
                    _vm.payrollDate = $$v
                  },
                  expression: "payrollDate",
                },
              }),
            ],
            1
          ),
        ]),
      ]),
      _c(
        "b-modal",
        {
          staticClass: "modal-danger",
          attrs: {
            title: "Information",
            "no-close-on-backdrop": "",
            "ok-variant": "danger",
            "ok-only": "",
            "modal-class": "mui-animation",
            fade: false,
          },
          on: {
            cancel: function ($event) {
              $event.preventDefault()
              return _vm.closeInfoModalPayroll.apply(null, arguments)
            },
          },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function () {
                return [
                  _c(
                    "div",
                    { staticClass: "w-100" },
                    [
                      _c(
                        "b-button",
                        {
                          staticClass: "float-right",
                          attrs: { size: "sm" },
                          on: { click: _vm.closeInfoModalPayroll },
                        },
                        [_vm._v(" OK ")]
                      ),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
          ]),
          model: {
            value: _vm.openModalInfoPayroll,
            callback: function ($$v) {
              _vm.openModalInfoPayroll = $$v
            },
            expression: "openModalInfoPayroll",
          },
        },
        [_c("div", { domProps: { innerHTML: _vm._s(_vm.info) } })]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }