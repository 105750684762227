<template>
	<section class="w-100">
		<div class="d-flex flex-row align-items-center cursor-pointer set-border-info-cell" @click="handleShowContent">
			<div class="d-flex flex-row align-items-center w-90 gap-10">
				<span class="font-weight-bold fs-16">{{ item.fullName }} ({{ item.functionName }})</span>
				<b-badge pill variant="info">{{ getNumber(item.data) }}</b-badge>
			</div>
			<div class="d-flex justify-content-end w-10" @click="handleShowContent">
				<component v-if="!isCollapsed" :is="getLucideIcon(ICONS.CHEVRON_DOWN.name)" color="#06263E" :size="22" />
				<component v-if="isCollapsed" :is="getLucideIcon(ICONS.CHEVRON_UP.name)" color="#06263E" :size="22" />
			</div>
		</div>
		<b-collapse :visible="isCollapsed">
			<b-col class="p-2" v-for="(showDetailData, _key) of shortedByDate(item.data)" :key="_key">
				<row-info-content :showDetailData="showDetailData" :isProduction="isProduction" :isGestionnaryOfPaie="isGestionnaryOfPaie" />
				<b-row v-if="isMultiple" class="divider">
					<div class="border-item"></div>
				</b-row>
			</b-col>
		</b-collapse>
	</section>
</template>
<script>
import RowInfoContent from './RowInfoContent';
import languageMessages from '@/mixins/languageMessages';
import globalMixin from '@/mixins/global.mixin';
import isSingleProjectMixin from '@/mixins/isSingleProject.mixin';
import * as _ from 'lodash';

export default {
	name: 'MultipleRowInfoContent',
	components: { RowInfoContent },
	mixins: [languageMessages, isSingleProjectMixin, globalMixin],
	props: {
		item: {
			type: Object,
			required: false,
			default: () => []
		},
		isMultiple: {
			type: Boolean,
			required: false,
			default: () => false
		},
		isProduction: {
			type: Boolean,
			required: false,
			default: () => false
		},
		isGestionnaryOfPaie: {
			type: Boolean,
			required: false,
			default: () => false
		}
	},
	data: () => ({
		isCollapsed: false
	}),
	methods: {
		handleShowContent() {
			this.isCollapsed = !this.isCollapsed;
		},
		getNumber(total) {
			if (!_.isNil(total)) {
				return total.length.toString().padStart(2, '0');
			}
			return '00';
		},
		shortedByDate(data) {
			const shortEd = data.sort((a, b) => new Date(a.date) - new Date(b.date));
			return shortEd;
		}
	}
};
</script>
<style lang="scss">
.set-border-info-cell {
	background-color: #f5f7f9;
	padding: 16px;
	border-radius: 8px;
	height: 50px;
}
.divider {
	display: flex;
	align-items: center;
	justify-content: center;
}
.border-item {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 10px;
	width: 100%;
	border-bottom: 4px solid #e2e6eb;
}
.gap-10 {
	gap: 10px;
}
</style>
