<template>
	<section>
		<b-row class="d-flex align-items-center">
			<b-col cols="10">
				<h1 class="h-text-color">
					{{ convertTextDate(showDetailData.date) }}
				</h1>
			</b-col>
		</b-row>
		<b-row>
			<b-col cols="6" class="d-flex justify-content-start align-items-center">
				<h6 v-if="!isFilmSingle" class="h-text-color p-1">
					<span>
						{{ FormMSG(257, 'Encoded by:') }}
						<b>
							{{ encoder(showDetailData) }}
						</b>
					</span>
				</h6>
			</b-col>
			<b-col v-if="isAskToDelteItem" cols="2">
				<div class="fs-14 fw-700 ask-to-delete">{{ isAskToDelteItem ? messageAskInformation : '' }}</div>
			</b-col>
			<b-col :cols="isAskToDelteItem ? 4 : 6" class="d-flex justify-content-end align-items-center">
				<h6 v-if="!isFilmSingle" class="p-1">
					<div class="name-title">
						{{ getUserFullName(showDetailData) }}
						<span class="divider-dot"></span>
						{{ getUserFunctionName(showDetailData) }}
					</div>
				</h6>
			</b-col>
		</b-row>
		<b-row>
			<b-col cols="4">
				<h6 class="p-1">
					<div class="name-title">
						{{ FormMSG(13, 'Status') }}
					</div>
				</h6>
			</b-col>
			<b-col cols="8">
				<div class="d-flex flex-row justify-content-end align-items-end p-1 wrap-status gap-1">
					<div :class="`status ${classStatus(showDetailData.validated)}`" style="font-size: 0.675rem">
						{{ getLabelStatus(showDetailData) }}
					</div>
					<div v-if="isAlreadySendToPaie(showDetailData)" class="border-send-to-paie" :id="`send-to-paie-${showDetailData.id}`">
						<component :is="getLucideIcon(ICONS.SEND.name)" :color="'#9404d1'" :size="20" />
					</div>
					<b-tooltip :target="`send-to-paie-${showDetailData.id}`" placement="bottom">
						<div>{{ FormMSG(406, 'Item has already been sent to pay') }}</div>
					</b-tooltip>
				</div>
			</b-col>
		</b-row>
		<b-row style="margin-top: 20px">
			<b-col>
				<div class="details-timesheet">
					<ul>
						<li>
							<div class="column-flag-1">{{ FormMSG(14, 'Rubric') }}</div>
							<div class="column-flag-2">{{ FormMSG(8, 'Category') }}</div>
							<div class="column-flag-3">{{ FormMSG(9, 'Activity') }}</div>
							<div class="column-flag-4">{{ FormMSG(10, 'Decors') }}</div>
							<div class="column-flag-5">{{ FormMSG(11, 'Quantity') }}</div>
							<div class="column-flag-6">{{ FormMSG(12, 'Amount') }}</div>
							<div class="column-flag-7">{{ FormMSG(15, 'Total') }}</div>
						</li>
						<li
							class="timesheet-comment border-0 rounded-0"
							v-if="
								(showDetailData.validated === 4 || showDetailData.validated === 16) &&
								showDetailData.comment &&
								showDetailData.comment.length > 0
							"
						>
							<p>{{ showDetailData.comment }}</p>
						</li>
						<li v-for="(mapInfo, keyInfo) of showDetailData.tsDayDetails" :key="keyInfo" class="row-item">
							<div class="column-flag-1">
								<div class="d-flex flex-row align-items-center gap-1">
									<div
										v-if="setMargin"
										:id="`tooltip-${keyInfo}-${mapInfo.id}`"
										:class="{ 'default-info-size': true, 'border-animation': activeBorderAnimation(mapInfo) }"
									>
										<component
											v-if="isActiveInfo(mapInfo).active"
											:is="getLucideIcon(ICONS.INFO.name)"
											:color="ICONS.INFO.color"
											:size="16"
											:stroke-width="2.25"
										/>
									</div>
									<div
										class="d-flex justify-content-start align-items-center p-1 text-black px-3 mt-1 h-auto w-100"
										:style="{
											backgroundColor: `#${mapInfo.projectFlagItem.color}`,
											borderRadius: '4px'
										}"
									>
										<span :style="`color: ${isColorLight(`#${mapInfo.projectFlagItem.color}`) ? 'black' : 'white'}; font-weight: bold`">{{
											getProjectFlagToText(mapInfo)
										}}</span>
									</div>
									<b-tooltip v-if="isActiveInfo(mapInfo).active" :target="`tooltip-${keyInfo}-${mapInfo.id}`" placement="bottom">
										<section class="d-flex align-items-center flex-column gap-4">
											<div
												class="d-flex flex-row align-items-start w-100"
												:style="{ gap: '2px !important' }"
												v-for="(mssg, index) of isActiveInfo(mapInfo).messages"
												v-bind:key="index"
											>
												<div :style="{ display: 'flex', alignItems: 'center', justifyContent: 'start', width: '12px' }">
													<div
														:style="{
															width: '10px',
															height: '10px',
															marginTop: '4px',
															borderRadius: '50%',
															backgroundColor: `#${mapInfo.projectFlagItem.color}`
														}"
													></div>
												</div>
												<div>{{ mssg }}</div>
											</div>
										</section>
									</b-tooltip>
								</div>
							</div>
							<div class="column-flag-2 mr-1 ml-2">{{ setCategory(mapInfo) }}</div>
							<div class="column-flag-3 mr-1 ml-2">{{ setActivity(mapInfo) }}</div>
							<div class="column-flag-4 mr-1 ml-2">{{ setLocationName(mapInfo) }}</div>
							<div class="column-flag-5 mr-1 ml-2">{{ getQuantity(mapInfo) }}</div>
							<div class="column-flag-6 mr-1 ml-2">{{ formatCurrency(mapInfo) }}</div>
							<div class="column-flag-7 mr-1 ml-2">{{ setTotal(mapInfo) }}</div>
						</li>
					</ul>
				</div>
			</b-col>
		</b-row>
	</section>
</template>
<script>
import languageMessages from '@/mixins/languageMessages';
import globalMixin from '@/mixins/global.mixin';
import isSingleProjectMixin from '@/mixins/isSingleProject.mixin';
import { rendCurrency, rendKgCo2 } from '~helpers';
import { INTERNAL_STATUS, ROW_ASK_TO_DELETE, TYPE_ASK_TO_DELETE } from '@/shared/constants';
import { classStatus } from '@/shared/global-status';
import { Clock10, Sprout, Info } from 'lucide-vue';
import * as _ from 'lodash';
import moment from 'moment';

export default {
	name: 'RowInfoContent',
	components: { Clock10, Sprout, Info },
	mixins: [languageMessages, isSingleProjectMixin, globalMixin],
	props: {
		showDetailData: {
			type: Object,
			required: false,
			default: () => []
		},
		isProduction: {
			type: Boolean,
			required: false,
			default: () => false
		},
		isGestionnaryOfPaie: {
			type: Boolean,
			required: false,
			default: () => false
		}
	},
	watch: {
		showDetailData: {
			handler(newVal) {
				if (!Object.keys(newVal).length) {
					this.setMargin = false;
					this.isAskToDelteItem = false;
				} else {
					const { FOR_MANAGER, FOR_LVL_ONE } = TYPE_ASK_TO_DELETE;
					const { VALIDATED_MANAGER, VALIDATED_LVL_ONE, REJECTED_LVL_ONE, REJECTED_LVL_TWO } = ROW_ASK_TO_DELETE;
					this.isAskToDelteItem = [FOR_MANAGER, FOR_LVL_ONE].includes(newVal.askToDeletes);
					const item = newVal.tsDayDetails;
					this.setMargin = item.some(
						({ askToDelete, hasChangesAfterPay }) =>
							[VALIDATED_MANAGER, VALIDATED_LVL_ONE, REJECTED_LVL_ONE, REJECTED_LVL_TWO].includes(askToDelete) || hasChangesAfterPay
					);
				}
				return newVal;
			},
			immediate: true,
			deep: true
		}
	},
	computed: {
		messageAskInformation() {
			const message = this.isGestionnaryOfPaie ? this.FormMSG(404, 'Need to delete') : this.FormMSG(405, 'Ask to delete');
			return message;
		}
	},
	data() {
		const setWorked = (hour, minute) => {
			if (hour !== undefined && minute !== undefined) {
				return `${hour.toString().padStart('2', '0')}` + ':' + `${minute.toString().padStart('2', '0')}`;
			}
		};
		return {
			setWorked,
			isCollapsed: false,
			rendCurrency,
			rendKgCo2,
			classStatus,
			validated: null,
			setMargin: false,
			isAskToDelteItem: false
		};
	},
	methods: {
		isActiveInfo(item) {
			const { VALIDATED_MANAGER, VALIDATED_LVL_ONE, REJECTED_LVL_ONE, REJECTED_LVL_TWO, DEFAULT_VALUE } = ROW_ASK_TO_DELETE;
			let newMessages = [];
			if (item) {
				const { askToDelete, hasChangesAfterPay } = item;
				const mapStatus = {
					[DEFAULT_VALUE]: null,
					[VALIDATED_MANAGER]: [399, 'Ask delete by manager'],
					[VALIDATED_LVL_ONE]: [400, 'Ask delete by level one'],
					[REJECTED_LVL_ONE]: [401, 'Rejected by level one'],
					[REJECTED_LVL_TWO]: [402, 'Rejected by lvl two']
				}[askToDelete];
				if (mapStatus) {
					newMessages = [this.FormMSG(...mapStatus)];
				}
				if (hasChangesAfterPay) {
					newMessages = [...newMessages, this.FormMSG(403, 'This item item is changed after to send from paie')];
				}
				return { messages: newMessages, active: !!newMessages.length };
			}
			return { messages: newMessages, active: false };
		},
		isAlreadySendToPaie(item) {
			const { SENT_TO_PAY } = INTERNAL_STATUS;
			if (item) {
				const { internalStatus } = item;
				return internalStatus === SENT_TO_PAY;
			}
			return false;
		},
		activeBorderAnimation(item) {
			if (item) {
				const { hasChangesAfterPay } = item;
				return hasChangesAfterPay;
			}
			return false;
		},
		handleShowContent() {
			this.isCollapsed = !this.isCollapsed;
		},
		isColorLight(color) {
			const hex = color.replace('#', '');
			const red = parseInt(hex.substring(0, 0 + 2), 16);
			const green = parseInt(hex.substring(2, 2 + 2), 16);
			const blue = parseInt(hex.substring(4, 4 + 2), 16);
			const brightness = (red * 299 + green * 587 + blue * 114) / 1000;
			return brightness > 155;
		},
		setCategory(item) {
			if (item !== undefined) {
				let category = item.costCenter;
				let shotActivity = item.activity.short;
				if (category.length === 0) {
					return 'None';
				} else {
					return category.replaceAll('$', shotActivity);
				}
			}
			return 'None';
		},
		setLocationName(item) {
			if (item !== null || item !== undefined) {
				if (+item.location.id === 0) {
					return 'Off';
				}
				return item.location.setName;
			}
			return 'None';
		},
		setActivity(item) {
			if (item !== undefined) {
				let activity = item.activity.name;
				let short = item.activity.short;
				if (activity.length !== 0) {
					if (short.length !== 0) {
						return activity + ' (' + short + ')';
					}
					return activity;
				}
				return 'None';
			}
		},
		getUserFullName(showDetailData) {
			if (showDetailData.user !== undefined) {
				return `${showDetailData.user.name} ${showDetailData.user.firstName}`;
			}
		},
		getUserFunctionName(showDetailData) {
			if (showDetailData.user !== undefined) {
				return showDetailData.user.functionName;
			}
		},
		getProjectFlagToText(item) {
			if (item.projectFlagItem !== undefined || item.projectFlagItem !== null) {
				return `${item.projectFlagItem.name} ${'(' + item.projectFlagItem.short + ')'}`;
			}
		},
		encoder(data) {
			if (data.encoder !== null) {
				let firstName = data.encoder !== undefined ? data.encoder.firstName : '';
				let name = data.encoder !== undefined ? data.encoder.name : '';
				return `${firstName} ${name}`;
			}
		},
		setTotal(item) {
			if (item !== null || item !== undefined) {
				const hideSalary = item.hideSalary;
				let total = +item.quantity * +item.amount;
				const amountTotal = rendCurrency(total);
				return this.rendAmountCurrency(hideSalary, amountTotal);
			}
			return 0;
		},
		getQuantity(item) {
			if (item) {
				const { quantity, hideSalary } = item;
				if (this.isProduction) {
					return quantity.toString().padStart('2', '0');
				} else {
					if (!hideSalary) {
						return quantity.toString().padStart('2', '0');
					}
					return `Xx`;
				}
			}
			return '00';
		},
		rendAmountCurrency(hideSalary, amount) {
			if (!hideSalary) {
				return amount;
			} else {
				if (this.isProduction) {
					return amount;
				}
				const unite = `${amount}`.replaceAll('K', '').split(' ')[1];
				return `Xx.xx ${unite}`;
			}
		},
		formatCurrency(mapInfo) {
			const { amount, hideSalary } = mapInfo;
			const _amount = rendCurrency(amount);
			return this.rendAmountCurrency(hideSalary, _amount);
		},
		convertTextDate(value) {
			const langCurrent = this.currentLang;
			let result = moment(value).utc().locale(`${langCurrent}`).format('DD MMMM YYYY');
			return result;
		},
		setTextInfoBullWorkFlowCellInfo(value) {
			if (!_.isNil(value)) {
				switch (value) {
					case 0:
						return this.FormMSG(342, 'Not submitted');
					case 1:
						return this.FormMSG(376, 'Transmitted Manager');
					case 2:
						return this.FormMSG(377, 'Validated Charged / Dir. by Prod.');
					case 8:
						return this.FormMSG(388, 'Validated Production Admin.');
					case 4:
						return this.FormMSG(340, 'Refused Production Manager');
					case 16:
						return this.FormMSG(341, 'Refused Production Admin');
					default:
						return this.FormMSG(375, 'Not found');
				}
			}
		},
		getLabelStatus(item) {
			if (!_.isNil(item)) {
				const value = +item.validated;
				if (value === this.codePaie) {
					return this.FormMSG(374, 'Validated payroll manager');
				} else {
					const text = this.setTextInfoBullWorkFlowCellInfo(value);
					return text;
				}
			}
		}
	}
};
</script>
<style lang="scss">
$isLight: #ea4e2c;
$backgroundColrIsLight: #fcede9;
.h-text-color {
	color: rgba(6, 38, 62, 0.84);
}
.name-title {
	font-size: 14px;
	font-weight: 500;
	color: #06263e;
}
.column-flag-1 {
	font-size: 0.875rem !important;
	font-weight: 600;
	color: #06263e;
	width: 16%;
}
.column-flag-2,
.column-flag-3,
.column-flag-4,
.column-flag-5,
.column-flag-6 {
	font-size: 0.875rem !important;
	font-weight: 600;
	color: #06263e;
	width: 14%;
	text-align: center;
}
.column-flag-7 {
	font-weight: 600;
	font-size: 0.875rem !important;
	color: #06263e;
	width: 14%;
	text-align: right;
}
.set-border-info-cell {
	background-color: #f5f7f9;
	padding: 16px;
	border-radius: 8px;
}
.divider {
	display: flex;
	align-items: center;
	justify-content: center;
}
.border-item {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 10px;
	width: 100%;
	border-bottom: 4px solid #e2e6eb;
}
.gap-1 {
	gap: 2px;
	cursor: pointer;
}
.gap-4 {
	gap: 4px;
}
.default-info-size {
	width: 30px;
}
.row-item {
	cursor: pointer !important;
	&:hover {
		background-color: hsla(206, 96%, 90%, 0.5) !important;
	}
}
.ask-to-delete {
	color: $isLight;
	font-weight: 700;
}

@mixin default-option {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 28px;
	height: 24px;
	border-radius: 50%;
}

@mixin sonar-mixin-after($color) {
	background-color: $color;
	animation: sonar 1.5s infinite;
}

@mixin light-animation($color, $backgroundColor) {
	@include default-option;
	background-color: $backgroundColor;
	border: 1px solid $color;
	animation: pulse 1.5s infinite;
}
.border-animation {
	@include light-animation($isLight, $backgroundColrIsLight);
	&:after {
		@include default-option;
		@include sonar-mixin-after($isLight);
	}
}

@keyframes sonar {
	0% {
		transform: scale(0.6);
		opacity: 1;
	}
	100% {
		transform: scale(1.2);
		opacity: 0;
	}
}

@keyframes pulse {
	0% {
		transform: scale(1);
	}
	20% {
		transform: scale(0.9);
	}
	50% {
		transform: scale(1);
	}
	80% {
		transform: scale(0.6);
	}
	100% {
		transform: scale(1);
	}
}

.border-send-to-paie {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 30px;
	height: 30px;
	&:hover {
		border-radius: 4px;
		border: 2px solid #9404d1;
	}
}
</style>
