<template>
	<b-modal
		v-model="isOpen"
		:title="FormMSG(14, 'Advanced edit')"
		header-class="header-class-modal-doc-package"
		size="xxl"
		hide-header-close
		no-close-on-backdrop
		no-close-on-esc
		@cancel="closeModal"
		dialog-class="dialog-custom"
		modal-class="mui-animation"
		:fade="false"
	>
		<div class="form container-layout">
			<b-row>
				<b-col>
					<fieldset class="my-3 border-groove-blue-streak" :class="`${$screen.width <= 576 ? 'card-inside' : 'scheduler-border'}`">
						<legend :class="`${$screen.width <= 576 ? 'card-inside' : 'scheduler-border'}`" class="text-color-blue-streak">
							{{ FormMSG(132, 'Selection') }}
						</legend>
						<div class="pt-3 pb-3">
							<b-row>
								<b-col>
									<div class="fs-14 fw-700">
										{{ getFullName() }} {{ formatedDate() }}
										<span v-if="getLocation().id > 0">{{ getLocation().text }}</span>
									</div>
								</b-col>
								<b-col>
									<div class="fs-14 fw-700 ask-to-delete">
										{{ isAskToDelteItem ? messageAskInformation : '' }}
									</div>
								</b-col>
							</b-row>
						</div>
					</fieldset>
				</b-col>
			</b-row>

			<b-row class="mt-2">
				<b-col>
					<fieldset class="my-3 border-groove-blue-streak" :class="`${$screen.width <= 576 ? 'card-inside' : 'scheduler-border'}`">
						<legend :class="`${$screen.width <= 576 ? 'card-inside' : 'scheduler-border'}`" class="text-color-blue-streak">
							{{ FormMSG(145, 'Work detail') }}
						</legend>
						<div class="pt-3 pb-3">
							<b-row>
								<b-col>
									<b-table
										v-if="$screen.width >= 992"
										selected-variant="primary"
										hover
										selectable
										select-mode="single"
										responsive="sm"
										ref="documentPackageTable"
										:items="dataList"
										style="text-align: left"
										:fields="tableFields"
										bordered
										striped
										small
										head-variant="dark"
										:empty-text="FormMSG(250, 'No data found')"
										show-empty
									>
										<template #cell(rubric)="{ item, index }">
											<div class="d-flex flex-row justify-content-start align-items-center">
												<div
													v-if="item.isEdit === false"
													v-b-tooltip.hover.top.html="FormMSG(505, 'The element has been modified')"
													:class="{ [generateAnimation(item)]: !isMultipleEdit }"
												></div>
												<div v-if="item.isEdit === false">&nbsp;&nbsp;&nbsp;</div>
												<div v-if="item.isEdit === false">
													<div v-if="typeof item.rubric === 'number'">
														{{ item.flagItemName }}
													</div>
													<div v-if="typeof item.rubric === 'object'">
														{{ item.rubric ? item.rubric.short : '' }} {{ item.rubric ? item.rubric.value : '' }}H -
														{{ item.rubric ? item.rubric.name : '' }}
													</div>
												</div>
												<div v-if="item.isEdit === true">
													<b-form-group class="mb-0">
														<div class="d-flex flex-row align-items-center">
															<b-button
																v-b-tooltip.hover.top.html="`${showNameRubricSelected(index)}`"
																size="sm"
																class="btn bg-transparent border-0"
															>
																<component
																	:is="getLucideIcon(ICONS.INFO.name)"
																	:color="ICONS.INFO.color"
																	:size="16"
																	:stroke-width="2.25"
																/>
															</b-button>
															<treeselect
																v-model="$v.dataList.$each.$iter[index].rubric.$model"
																:multiple="false"
																:options="flagItemsOptions"
																:placeholder="FormMSG(87, 'Please select')"
																open-direction="bottom"
																:clearable="true"
																:class="{ 'is-invalid-treeselect': $v.dataList.$each.$iter[index].rubric.$invalid }"
																@select="handleOptionSelected($event, index)"
															>
																<div slot="value-label" slot-scope="{ node }" :title="getNameRubric(node)">
																	<div>{{ getNameRubric(node) }}</div>
																</div>
																<div slot="option-label" slot-scope="{ node }">
																	<div class="treeselect-label p-2" :title="getNameRubric(node)">
																		<div class="d-flex flex-row align-items-center">
																			<div>
																				<b-button
																					v-b-tooltip.hover.top.html="`${showTooltipTextRubric(node)}`"
																					size="sm"
																					class="btn bg-transparent border-0"
																				>
																					<component
																						:is="getLucideIcon(ICONS.INFO.name)"
																						:color="ICONS.INFO.color"
																						:size="16"
																						:stroke-width="2.25"
																					/>
																				</b-button>
																			</div>
																			<div>{{ getNameRubric(node) }}</div>
																		</div>
																	</div>
																</div>
															</treeselect>
														</div>
														<div v-if="$v.dataList.$each.$iter[index].rubric.$invalid" class="invalid-feedback margin-l-30">
															{{ FormMSG(205, 'Rubric is required') }}
														</div>
														<div v-else-if="rowLineInvalid(index)">&nbsp;</div>
													</b-form-group>
												</div>
											</div>
										</template>
										<template #cell(amount)="{ item, index }">
											<div v-if="isManagerSalaryAccess(item)" class="d-flex flex-row align-items-center justify-content-end gap-1">
												<div :class="{ 'bg-solve-amount': isSolveAmount(index) }">{{ getCurrency(item) }}</div>
												<div v-if="isMultipleEdit && !item.isEqualAmount">
													<b-button
														size="sm"
														class="btn bg-transparent border-0 mb-1"
														v-b-tooltip.hover.right.html="isExistEqualAmountToolTip(item.isEqualAmount)"
													>
														<alert-triangle :size="16" color="orange" :stroke-width="2.25" />
													</b-button>
												</div>
											</div>
											<div v-else>
												<div v-if="item.isEdit === false" class="d-flex flex-row align-items-center justify-content-end gap-1">
													<div :class="{ 'bg-solve-amount': isSolveAmount(index) }">{{ getCurrency(item) }}</div>
													<div v-if="isMultipleEdit && !item.isEqualAmount">
														<b-button
															size="sm"
															class="btn bg-transparent border-0 mb-1"
															v-b-tooltip.hover.right.html="isExistEqualAmountToolTip(item.isEqualAmount)"
														>
															<alert-triangle :size="16" color="orange" :stroke-width="2.25" />
														</b-button>
													</div>
												</div>
												<div v-if="item.isEdit === true">
													<b-form-group class="mb-0">
														<b-input-group>
															<b-form-input
																v-model="$v.dataList.$each.$iter[index].amount.$model"
																:class="{
																	'is-invalid': isRegissor ? false : $v.dataList.$each.$iter[index].amount.$invalid
																}"
															/>
															<b-input-group-append>
																<b-input-group-text>
																	<currency-svg color="#06263E" opacity="0.85" width="15" height="15" />
																</b-input-group-text>
															</b-input-group-append>
														</b-input-group>
														<div
															v-if="isRegissor === false && $v.dataList.$each.$iter[index].amount.$invalid"
															class="invalid-feedback"
														>
															{{ FormMSG(202, 'Amount is required') }}
														</div>
														<div v-else-if="rowLineInvalid(index)">&nbsp;</div>
													</b-form-group>
												</div>
											</div>
										</template>
										<template #cell(quantity)="{ item, index }">
											<div class="d-flex flex-row align-items-center justify-content-center">
												<div v-if="isManagerSalaryAccess(item)">
													{{ getQuantity(item) }}
													<b-button
														v-if="isMultipleEdit && !item.isEqualQuantity"
														size="sm"
														class="btn bg-transparent border-0 mb-1"
														v-b-tooltip.hover.right.html="isExistEqualQuantityToolTip(item.isEqualQuantity)"
													>
														<alert-triangle :size="16" color="orange" :stroke-width="2.25" />
													</b-button>
												</div>
												<div v-else>
													<div v-if="item.isEdit === false">
														{{ getQuantity(item) }}
														<b-button
															v-if="isMultipleEdit && !item.isEqualQuantity"
															size="sm"
															class="btn bg-transparent border-0 mb-1"
															v-b-tooltip.hover.right.html="isExistEqualQuantityToolTip(item.isEqualQuantity)"
														>
															<alert-triangle :size="16" color="orange" :stroke-width="2.25" />
														</b-button>
													</div>
													<div v-if="item.isEdit === true">
														<b-form-group class="mb-0">
															<b-input-group>
																<b-form-input
																	type="number"
																	:min="1"
																	:step="1"
																	v-model="$v.dataList.$each.$iter[index].$model.quantity"
																/>
															</b-input-group>
														</b-form-group>
														<div v-if="rowLineInvalid(index)">&nbsp;</div>
													</div>
												</div>
											</div>
										</template>
										<template #cell(locations)="{ item, index }">
											<div>
												<div v-if="item.isEdit === false">
													<div>{{ getLocationName(item, index) }}</div>
												</div>
												<div v-if="item.isEdit === true">
													<b-form-group class="mb-0">
														<v-select
															:id="`id-${index}`"
															v-model="$v.dataList.$each.$iter[index].locations.$model"
															:options="locationList"
															:placeholder="FormMSG(452, 'Select a decor')"
															label="setName"
															:clearable="false"
															:class="{ 'is-invalid': $v.dataList.$each.$iter[index].locations.$invalid }"
															:searchable="true"
														>
														</v-select>
														<div v-if="$v.dataList.$each.$iter[index].locations.$invalid" class="invalid-feedback">
															{{ FormMSG(55, 'Decor is required') }}
														</div>
														<div v-else-if="rowLineInvalid(index)">&nbsp;</div>
													</b-form-group>
												</div>
											</div>
										</template>
										<template #cell(activity)="{ item, index }">
											<div>
												<div v-if="item.isEdit === false" :class="{ 'bg-solve-text': isSolveText(item.activity) }">
													<div v-if="item.activity !== null" class="d-flex flex-row align-items-center gap-1">
														<div>{{ item.activity.short }} - {{ item.activity.name }}</div>
														<div v-if="isMultipleEdit && !item.isEqualActivity">
															<b-button
																size="sm"
																class="btn bg-transparent border-0 mb-1"
																v-b-tooltip.hover.right.html="isExistEqualActivitiesToolTip(item.isEqualActivity)"
															>
																<alert-triangle :size="16" color="orange" :stroke-width="2.25" />
															</b-button>
														</div>
													</div>
												</div>
												<div v-if="item.isEdit === true">
													<b-form-group class="mb-0">
														<v-select
															v-model="$v.dataList.$each.$iter[index].activity.$model"
															:options="activityOptions"
															:placeholder="FormMSG(98, 'Select a activity ...')"
															:clearable="true"
															:class="{
																'is-invalid': isRegissor
																	? false
																	: $v.dataList.$each.$iter[index].activity.$invalid || dataList[index].activity === null
															}"
															:searchable="true"
														>
															<template #selected-option="{ name, short }">
																<div>
																	{{ `${short ? short : ''} - ${name}` }}
																</div>
															</template>
															<template #option="{ name, short }">
																<div>
																	{{ `${short ? short : ''} - ${name}` }}
																</div>
															</template>
														</v-select>
														<div
															v-if="
																isRegissor
																	? false
																	: $v.dataList.$each.$iter[index].activity.$invalid || dataList[index].activity === null
															"
															class="invalid-feedback"
														>
															{{ FormMSG(82, 'Activity is required') }}
														</div>
														<div v-else-if="rowLineInvalid(index)">&nbsp;</div>
													</b-form-group>
												</div>
											</div>
										</template>
										<template #cell(category)="{ item, index }">
											<div>
												<div v-if="item.isEdit === false" class="d-flex flex-row align-items-center gap-1">
													<div :class="{ 'bg-solve-text': isSolveText(setTextCategoriesWithActivities(item.labelCategory, index)) }">
														{{ setTextCategoriesWithActivities(item.labelCategory, index) }}
													</div>
													<div v-if="isMultipleEdit && !item.isEqualCategories">
														<b-button
															size="sm"
															class="btn bg-transparent border-0 mb-1"
															v-b-tooltip.hover.right.html="isExistEqualCategoriesToolTip(item.isEqualCategories)"
														>
															<alert-triangle :size="16" color="orange" :stroke-width="2.25" />
														</b-button>
													</div>
												</div>
												<div v-if="item.isEdit === true">
													<div class="d-flex flex-row tax-item">
														<div class="w-70 category-input">
															<treeselect
																v-model="$v.dataList.$each.$iter[index].category.$model"
																:multiple="false"
																:options="mapCategories"
																:default-expand-level="1"
																:disable-branch-nodes="true"
																:placeholder="FormMSG(52, 'Select a category ...')"
																open-direction="bottom"
																:clearable="true"
																:class="{
																	'is-invalid-treeselect': isRegissor
																		? false
																		: dataList[index].category === 0 ||
																		  dataList[index].category === null ||
																		  $v.dataList.$each.$iter[index].category.$invalid
																}"
																@select="handleSelectedCategory($event, index)"
															>
																<div slot="value-label" slot-scope="{ node }" :title="node.label">
																	<div>{{ setTextCategoriesWithActivities(node.label, index) }}</div>
																</div>
																<div slot="option-label" slot-scope="{ node }">
																	<div class="treeselect-label" :title="node.label">{{ node.label }}</div>
																</div>
															</treeselect>
														</div>
														<div class="d-flex flex-wrap mt-0 w-30">
															<button
																class="btn-category w-100"
																v-if="showAllMapCategorie"
																@click.stop.prevent="handleShowMadCategories"
															>
																<span class="content-btn-category">{{ FormMSG(35, 'By trimestre') }}</span>
															</button>
															<button class="btn-category w-100" v-else @click="handleShowMadCategories">
																<span class="content-btn-category">{{ FormMSG(34, 'Show all') }}</span>
															</button>
														</div>
													</div>
													<div
														v-if="
															isRegissor
																? false
																: dataList[index].category === 0 ||
																  dataList[index].category === null ||
																  $v.dataList.$each.$iter[index].category.$invalid
														"
														class="invalid-feedback"
													>
														{{ FormMSG(204, 'Category is required') }}
													</div>
													<div v-else-if="rowLineInvalid(index)">&nbsp;</div>
												</div>
											</div>
										</template>
										<template #cell(edit)="{ item, index }">
											<div>
												<div v-if="item.isEdit === false" class="pt-1">
													<b-button
														:disabled="isAuthorizeEdit"
														variant="custom-transparent"
														class="text-color-blue-streak"
														@click="handleClickToEdit(index)"
													>
														<component :is="getLucideIcon('Edit')" :size="18" />
													</b-button>
												</div>
												<div v-if="item.isEdit === true">
													<div v-if="isRegissor === true" class="d-flex flex-row align-items-center gap-1 pt-1">
														<b-button
															variant="custom-transparent"
															@click="handleClickEditClose(item, index)"
															v-b-tooltip.hover
															:title="FormMSG(182, 'Close')"
														>
															<div class="text-success">
																<component :is="getLucideIcon('Undo')" color="#225CBD" :size="18" />
															</div>
														</b-button>
														<b-button
															variant="custom-transparent"
															:disabled="isSave($v.dataList.$each.$iter[index])"
															@click="handleClickEdit(index)"
															v-b-tooltip.hover
															:title="FormMSG(183, 'Save')"
														>
															<div class="text-success">
																<component :is="getLucideIcon('Save')" :size="18" />
															</div>
														</b-button>
													</div>
													<div v-else-if="isRegissor === false">
														<div
															v-if="$v.dataList.$each.$iter[index].$invalid === false"
															class="d-flex flex-row align-items-center gap-1 pt-1"
														>
															<b-button
																variant="custom-transparent"
																@click="handleClickEditClose(item, index)"
																v-b-tooltip.hover
																:title="FormMSG(182, 'Close')"
															>
																<div class="text-success">
																	<component :is="getLucideIcon('Undo')" color="#225CBD" :size="18" />
																</div>
															</b-button>
															<b-button
																variant="custom-transparent"
																:disabled="isSave($v.dataList.$each.$iter[index])"
																@click="handleClickEdit(index)"
																v-b-tooltip.hover
																:title="FormMSG(183, 'Save')"
															>
																<div class="text-success">
																	<component :is="getLucideIcon('Save')" :size="18" />
																</div>
															</b-button>
														</div>
														<div
															v-if="$v.dataList.$each.$iter[index].$invalid === true"
															class="d-flex flex-row align-items-center gap-1 pt-1"
														>
															<b-button
																variant="custom-transparent"
																@click="handleClickEditClose(item, index)"
																v-b-tooltip.hover
																:title="FormMSG(182, 'Close')"
															>
																<div class="text-success">
																	<component :is="getLucideIcon('Undo')" color="#225CBD" :size="18" />
																</div>
															</b-button>
															<b-button
																variant="custom-transparent"
																disabled
																v-b-tooltip.hover
																:title="FormMSG(181, 'Some fields are mandatory')"
															>
																<div class="text-success">
																	<component :is="getLucideIcon('Save')" :size="18" />
																</div>
															</b-button>
														</div>
													</div>
													<div v-if="rowLineInvalid(index)">&nbsp;</div>
												</div>
											</div>
										</template>
										<template #cell(remove)="{ item, index }">
											<div>
												<div class="d-flex flex-row align-items-center justify-content-center">
													<b-button
														:disabled="isAskToDelteItemRow(item)"
														variant="custom-transparent"
														:class="{
															'border-refuse': iconRemoveType(item).isRefused
														}"
														size="sm"
														@click="handleClickRemove(index, false)"
														v-b-tooltip.hover.left.html="handleContentRemoveTooltip(item)"
														v-if="iconRemoveType(item).accept === false"
													>
														<div v-if="actionTypeRowRemove(item).isRemove">
															<component
																:is="getLucideIcon(iconRemoveType(item).icon)"
																:color="iconRemoveType(item).color"
																:size="18"
															/>
														</div>
														<div v-if="iconRemoveType(item).accept">
															<component :is="getLucideIcon(ICONS.LIST_TODO.name)" :color="ICONS.LIST_TODO.color" :size="18" />
														</div>
														<div v-if="actionTypeRowRemove(item).isUndo">
															<component :is="getLucideIcon(ICONS.UNDO_2.name)" :color="ICONS.UNDO_2.color" :size="18" />
														</div>
													</b-button>
													<b-dropdown
														no-caret
														dropleft
														offset="25"
														variant="none"
														style="border-right: 0px !important; padding-top: 0px !important"
														boundary="window"
														v-else
													>
														<template #button-content>
															<more-vertical />
														</template>
														<b-dropdown-item-button @click="handleClickRemove(index, false)">
															<b-button
																:disabled="isAskToDelteItemRow(item)"
																variant="d-flex flex-row align-items-center custom-transparent"
																size="sm"
																v-b-tooltip.hover.left.html="handleContentRemoveTooltip(item, true)"
															>
																<div v-if="actionTypeRowRemove(item).isRemove">
																	<component
																		:is="getLucideIcon(iconRemoveType(item).icon)"
																		:color="iconRemoveType(item).color"
																		:size="18"
																	/>
																</div>
																<div v-if="actionTypeRowRemove(item).isUndo">
																	<component :is="getLucideIcon(ICONS.UNDO_2.name)" :color="ICONS.UNDO_2.color" :size="18" />
																</div>
															</b-button>
															&nbsp;<b>{{ iconRemoveType(item).text }}</b>
														</b-dropdown-item-button>
														<b-dropdown-item-button v-if="iconRemoveType(item).accept" @click="handleClickRemove(index, true)">
															<b-button
																:disabled="isAskToDelteItemRow(item)"
																variant="d-flex flex-row align-items-center custom-transparent"
																size="sm"
																v-b-tooltip.hover.left.html="handleContentRemoveTooltip(item, false)"
															>
																<div v-if="iconRemoveType(item).accept">
																	<component
																		:is="getLucideIcon(ICONS.X_CLEAR.name)"
																		:color="ICONS.X_CLEAR.color"
																		:size="18"
																	/>
																</div>
															</b-button>
															&nbsp;<b>{{ FormMSG(507, 'Refused') }}</b>
														</b-dropdown-item-button>
													</b-dropdown>
												</div>
												<div v-if="rowLineInvalid(index)">&nbsp;</div>
											</div>
										</template>
									</b-table>
								</b-col>
							</b-row>
							<b-row>
								<b-col cols="3" offset="9">
									<b-button :disabled="isAuthorizeNewRubric" variant="primary" block size="sm" @click="handleClickAddRubric">
										<component :is="getLucideIcon('PlusCircle')" :size="16" /> {{ FormMSG(73, 'Add a rubric') }}
									</b-button>
								</b-col>
							</b-row>
						</div>
					</fieldset>
				</b-col>
			</b-row>
		</div>

		<template #modal-footer>
			<div class="w-100 d-flex justify-content-end align-items-center gap-1">
				<div class="d-flex justify-content-center align-items-center" style="margin-top: 5px">
					<div class="text-default p-2">
						{{ `${haveAnEdit ? FormMSG(423, 'In the process of being modified') : ''}` }}
					</div>
					{{ ' ' }}
					<div class="bars-5" v-if="haveAnEdit"></div>
				</div>
				<b-button size="md" variant="custom-outline-gray" style="margin-top: 5px" class="w-138-px mr-3" @click="closeModal" block>
					{{ FormMSG(100, 'Close') }}
				</b-button>
			</div>
		</template>
		<b-modal
			v-model="showInfoEdit"
			:title="FormMSG(371, 'Information')"
			header-class="header-class-modal-doc-package"
			hide-header-close
			no-close-on-backdrop
			no-close-on-esc
			@cancel="closeModalShowInfo"
			@hidden="closeModalShowInfo"
			dialog-class="dialog-custom-info"
			modal-class="mui-animation"
			:fade="false"
		>
			<span class="d-flex align-items-center justify-content-center w-100">{{
				FormMSG(372, "Are you sure you haven't finished your modification ?")
			}}</span>
			<template #modal-footer>
				<div class="d-flex justify-content-end align-items-center gap-1">
					<b-button size="sm" variant="custom-outline-gray" @click="closeModalShowInfo"> {{ FormMSG(373, 'No') }} </b-button>
					<b-button size="sm" variant="primary" @click="closeAllModal"> {{ FormMSG(374, 'Yes') }} </b-button>
				</div>
			</template>
		</b-modal>
	</b-modal>
</template>

<script>
import languageMessages from '@/mixins/languageMessages';
import globalMixin from '@/mixins/global.mixin';
import CurrencySvg from '@/components/Svg/Currency';
import { getProjectFlagItems } from '@/cruds/fimalac.crud';
import { validationMixin } from 'vuelidate';
import { required, minValue, decimal } from 'vuelidate/lib/validators';
import { getBudgetHierarchicalCategoryJson } from '@/cruds/budget.crud';
import { removeAttributeTree } from '@/shared/utils';
import Treeselect from '@riophae/vue-treeselect';
import { AlertTriangle } from 'lucide-vue';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';
import _ from 'lodash';
import { rendCurrency } from '~helpers';
import { getUsersAndFlagsValue } from '@/cruds/flag.crud';
import { updateMultipleTsdayDetails, addUpdTsDayDetails, delTsdayDetails } from '@/cruds/timesheets-efc.crud';
import { TYPE_ASK_TO_DELETE, PLANNING_LEVEL, INTERNAL_STATUS, ROW_ASK_TO_DELETE } from '@/shared/constants';
import { MoreVertical } from 'lucide-vue';
import moment from 'moment';

export default {
	name: 'UserAdvancedEdit',

	mixins: [languageMessages, globalMixin, validationMixin],

	components: {
		AlertTriangle,
		Treeselect,
		CurrencySvg,
		MoreVertical,
		DepartmentSelector: () => ({
			component: import('@/components/DepartmentsSelection')
		})
	},

	props: {
		open: { type: Boolean, default: false, required: false },
		editDataMultiple: {
			type: Array,
			required: false,
			default: () => []
		},
		isMultipleEdit: {
			type: Boolean,
			default: false,
			required: () => false
		},
		isGestionnaryOfPaie: {
			type: Boolean,
			default: false,
			required: () => false
		},
		externalData: { type: Object, default: () => {}, required: false },
		isRegissor: { type: Boolean, default: false, required: false },
		locationList: {
			type: Array,
			required: false,
			default: () => []
		},
		trimestre: {
			type: String,
			required: false,
			default: ''
		},
		currentValidationLevel: {
			type: Number,
			requried: false
		}
	},

	data() {
		return {
			userSelected: null,
			dataList: [],
			newRubricsIndex: [],
			newRubricsCategory: {},
			loadingSubmit: false,
			categories: [],
			flagItemsOptions: [],
			activityOptions: [],
			showAllMapCategorie: false,
			globalDataList: [],
			persitTsDayId: null,
			askToDelete: true,
			content_title: '',
			showInfoEdit: false,
			isConvertedAsk: false,
			itemToAskDelete: [],
			textNewRubric: '',
			activeChangeAction: false,
			validated: null,
			newGlobalTsDayID: null,
			newGlobalTsDayDetailID: null,
			isSendToPaie: false,
			isExistSendToPaie: false
		};
	},

	validations: {
		dataList: {
			$each: {
				rubric: { required },
				amount: { required, minValue: minValue(1), decimal },
				activity: { required },
				category: { required },
				locations: { required }
			}
		}
	},

	watch: {
		isOpen: {
			handler(newVal) {
				if (!newVal) {
					this.isConvertedAsk = false;
				}
				return newVal;
			},
			immediate: true,
			deep: true
		},
		externalData: {
			handler(newVal) {
				this.dataList = [];
				const { SENT_TO_PAY } = INTERNAL_STATUS;
				if (!this.isMultipleEdit && !_.isNil(newVal) && newVal && newVal.tsDay) {
					const { internalStatus, askToDeletes: status } = newVal.tsDay;
					this.isSendToPaie = internalStatus === SENT_TO_PAY;
					this.isConvertedAsk = this.changeToBooleanStatusAskToDelete(status);
					this.newGlobalTsDayID = [+newVal.tsDay.id];
					this.newGlobalTsDayDetailID = [...newVal.tsDay.tsDayDetails.map(({ id }) => +id)];
					this.userSelected = newVal;
					for (let i = 0; i < newVal.tsDay.tsDayDetails.length; i++) {
						const element = newVal.tsDay.tsDayDetails[i];
						if (element.type !== 1) {
							const itemGenerated = this.generateItemDefault(element);
							const newItem = { ...itemGenerated, dataInput: [itemGenerated] };
							this.dataList.push(newItem);
							this.globalDataList.push(newItem);
						}
					}
				}
			},
			immediate: true,
			deep: true
		},
		editDataMultiple: {
			handler(newVal) {
				const { SENT_TO_PAY } = INTERNAL_STATUS;
				this.isSendToPaie = false;
				const newTsDays = newVal.map(({ tsDay }) => tsDay).filter(Boolean);
				this.isExistSendToPaie = newTsDays.some(({ internalStatus }) => [SENT_TO_PAY].includes(internalStatus));
				const data = newVal.map((item) => item.tsDay.tsDayDetails).flat(Infinity);
				this.newGlobalTsDayID = newVal.map(({ tsDay }) => +tsDay.id);
				const tsDayDetailsID = newVal
					.map(({ tsDay }) => tsDay.tsDayDetails)
					.flat()
					.map(({ id }) => +id);
				this.newGlobalTsDayDetailID = tsDayDetailsID;
				if (this.isMultipleEdit) {
					let arrays = [];
					for (let i = 0; i < data.length; i++) {
						const element = data[i];
						if (element.type !== 1) {
							let _data = this.generateItemDefault(element);
							arrays.push(_data);
						}
					}
					const groupList = arrays
						.reduce((accumulator, current) => {
							let existFlag = accumulator.find(
								(item) =>
									item.flagItemName === current.flagItemName &&
									item.labelCategory === current.labelCategory &&
									item.activityName === current.activityName &&
									JSON.stringify(item.locations) === JSON.stringify(current.locations) &&
									item.hideSalary === current.hideSalary
							);
							const createItem = (data) => ({
								id: data.id,
								type: data.type,
								flagId: data.flagId,
								rubric: data.rubric,
								flagItemFlagId: data.flagItemFlagId,
								flagItemValue: data.flagItemValue,
								value: data.value,
								amount: data.amount,
								category: data.category,
								typeOne: data.typeOne,
								paycode: data.paycode,
								activity: data.activity,
								labelCategory: data.labelCategory,
								flagItemName: data.flagItemName,
								activityName: data.activityName,
								locations: data.locations,
								quantity: data.quantity,
								projectFlagItem: data.projectFlagItem,
								askToDelete: data.askToDelete,
								dataInput: [data],
								isEdit: false,
								hideSalary: data.hideSalary,
								hasChangesAfterPay: data.hasChangesAfterPay
							});
							if (existFlag) {
								existFlag.dataInput.push(current);
							} else {
								accumulator.push(createItem(current));
							}
							return accumulator;
						}, [])
						.map((item) => ({
							...item,
							isEqualCategories: this.checkEqualCategories(item),
							isEqualActivity: this.checkEqualActivities(item),
							isEqualAmount: this.checkEqualAmount(item),
							isEqualQuantity: this.checkEqualQuantity(item)
						}))
						.map((item) => {
							const { dataInput } = item;
							const isExistAmoutZero = dataInput.some(({ amount }) => amount === 0);
							if (isExistAmoutZero) {
								return {
									...item,
									amount: 0
								};
							}
							return item;
						});
					const sortEDItems = (data) => {
						const filterData = data.sort((a, b) => {
							if (a.flagItemName > b.flagItemName) {
								return 1;
							}
							if (a.flagItemName < b.flagItemName) {
								return -1;
							}
							return 0;
						});
						return { filterData };
					};
					const { filterData } = sortEDItems(groupList);
					this.dataList = filterData;
					this.globalDataList = filterData;
				}

				return newVal;
			},
			immediate: true,
			deep: true
		}
	},

	computed: {
		isAskToDelteItem() {
			if (this.isGestionnaryOfPaie === false && this.isConvertedAsk) {
				if (this.isMultipleEdit) {
					return false;
				}
				return true;
			}
			return false;
		},
		messageAskInformation() {
			const message = this.isGestionnaryOfPaie ? this.FormMSG(514, 'Need to delete') : this.FormMSG(515, 'Ask to delete');
			return message;
		},
		isAuthorizeEdit() {
			if (this.isGestionnaryOfPaie) return true;
			return false;
		},
		isAuthorizeNewRubric() {
			const { ADM_PROD } = PLANNING_LEVEL;
			if (this.isGestionnaryOfPaie || this.currentValidationLevel === ADM_PROD) return true;
			return false;
		},
		haveAnEdit() {
			return !this.dataList.every((item) => item.isEdit === false);
		},
		isOpen: {
			get() {
				return this.open;
			},
			set(val) {
				return val;
			}
		},
		mapCategories() {
			if (this.showAllMapCategorie === false) {
				const setUniqueMapCategories = (data) => {
					let uniqueCombinations = new Set();
					let uniqueData = data.filter((item) => {
						let combination = `${item.id}`;
						if (!uniqueCombinations.has(combination)) {
							uniqueCombinations.add(combination);
							return true;
						}
						return false;
					});
					return uniqueData;
				};
				const recursiveCategories = (data, targetValue, path = [], itemsId = new Set()) => {
					const paths = [];
					for (const item of data) {
						const currentPath = [...path, item];
						if (item.label.toUpperCase().includes(targetValue.toUpperCase()) && !itemsId.has(item.id)) {
							paths.push(currentPath);
							itemsId.add(item.id);
						}
						if (item.children) {
							const childPaths = recursiveCategories(item.children, targetValue, currentPath, itemsId);
							if (childPaths.length > 0) {
								paths.push(...childPaths);
							}
						}
					}
					return paths;
				};
				let globalCategories = [...recursiveCategories(this.categories, this.trimestre).flat(Infinity)]; //.slice(0, -1);
				let result = [...setUniqueMapCategories(globalCategories)];
				let _options = result.map((item) => {
					if (item.children) {
						return { ...item, children: null };
					} else {
						if (item.label.toUpperCase().includes(this.trimestre.toUpperCase())) {
							return item;
						}
					}
				});
				let categories = [{ id: 0, label: this.FormMSG(459, 'Select categories'), value: 0, isDisabled: true }, ..._options];
				return categories;
			} else {
				let categories = this.categories.filter((item) => item.label !== 'UNDEFINED -UNDEFINED ');
				return categories.map((x) => ({ ...x, id: x.id }));
			}
		},
		tableFields() {
			let result = [
				{
					key: 'rubric',
					label: this.FormMSG(59, 'Rubric'),
					class: 'text-left align-middle w-30',
					sortable: true
				},
				{
					key: 'category',
					label: this.FormMSG(51, 'Category'),
					class: 'text-left align-middle w-25 form',
					sortable: true
				},
				{
					key: 'activity',
					label: this.FormMSG(50, 'Activity'),
					class: 'text-left align-middle w-20',
					sortable: true
				},
				{
					key: 'locations',
					label: this.FormMSG(53, 'Decors'),
					class: 'text-left align-middle w-13',
					sortable: true
				},
				{
					key: 'amount',
					label: this.FormMSG(49, 'Amount'),
					class: 'text-left align-middle w-10',
					sortable: true
				},
				{
					key: 'quantity',
					label: this.FormMSG(54, 'Quantity'),
					class: 'text-left align-middle w-10',
					sortable: true
				},
				{
					key: 'edit',
					label: this.FormMSG(58, 'Edit'),
					class: this.isRegissor === false ? 'text-center align-middle w-10' : 'text-center align-middle w-125-px',
					sortable: false
				},
				{
					key: 'remove',
					label: this.FormMSG(61, 'Remove'),
					class: this.isRegissor === false ? 'text-center align-middle w-10' : 'text-center align-middle w-125-px',
					sortable: false
				}
			];

			return result;
		}
	},

	async created() {
		await this.getCategories(-2, 1);
		await this.getProjectFlagItems();
		await this.getActivityFlagItems();
	},

	methods: {
		generateAnimation(item) {
			if (item && item.hasChangesAfterPay) {
				return 'rounded-sonar-light-animation';
			}
			return '';
		},
		changeToBooleanStatusAskToDelete(status) {
			const { DEFAULT_ASK } = TYPE_ASK_TO_DELETE;
			if (status !== DEFAULT_ASK) {
				return true;
			}
			return false;
		},
		isAskToDelteItemRow(item) {
			const { FOR_LVL_ONE } = TYPE_ASK_TO_DELETE;
			const { ADM_PROD } = PLANNING_LEVEL;
			if (this.editDataMultiple.length !== 0) {
				return this.isGestionnaryOfPaie ? false : this.isExistSendToPaie;
			} else {
				if (this.isGestionnaryOfPaie) {
					const { askToDelete } = item;
					const status = ![FOR_LVL_ONE].includes(askToDelete);
					return status;
				}
				if (this.currentValidationLevel === ADM_PROD) {
					return true;
				}
				return false;
			}
		},
		isSave(item) {
			if (item) {
				const { $model } = item;
				const { rubric, category, activity, locations, amount } = $model;
				if (this.isRegissor) {
					if (rubric && locations) {
						return false;
					}
					return true;
				} else {
					if (rubric && category && activity && locations && amount && amount !== 0) {
						return false;
					}
					return true;
				}
			}
			return true;
		},
		generateItemDefault(element) {
			return {
				id: +element.id,
				type: +element.type,
				flagId: +element.flagId,
				rubric: +element.projectFlagItem.id,
				flagItemFlagId: +element.projectFlagItem.flagId,
				flagItemValue: +element.projectFlagItem.value,
				value: +element.value,
				amount: +element.amount,
				category: +element.category,
				typeOne: +element.typeOne,
				paycode: +element.paycode,
				activity: +element.activity.id > 0 ? element.activity : null,
				labelCategory: element.costCenter,
				flagItemName: element.projectFlagItem.short + ' - ' + element.projectFlagItem.name,
				activityName: +element.activity.id > 0 ? `${element.activity.short} - ${element.activity.name}` : '',
				locations: element.location,
				quantity: +element.quantity,
				projectFlagItem: element.projectFlagItem,
				askToDelete: element.askToDelete,
				isEdit: false,
				hideSalary: this.isRegissor ? element.hideSalary : false,
				hasChangesAfterPay: element.hasChangesAfterPay
			};
		},
		rowLineInvalid(index) {
			const newItem = this.$v.dataList.$each.$iter[index];
			const isSave = this.isSave(newItem);
			return isSave;
		},
		formatedDate() {
			const userSelected = this.userSelected;
			const langCurrent = this.currentLang;
			if (userSelected) {
				const { tsDay } = userSelected;
				const { date: defaultDate } = tsDay;
				return moment(defaultDate).utc().locale(`${langCurrent}`).format('dddd DD MMMM YYYY');
			}
			return moment().utc().locale(`${langCurrent}`).format('dddd DD MMMM YYYY');
		},
		getFullName() {
			const userSelected = this.userSelected;
			if (userSelected && !this.isMultipleEdit) {
				const { fullName } = userSelected;
				return `${fullName} - `;
			}
		},
		getLocation() {
			const userSelected = this.userSelected;
			if (userSelected) {
				const { locations } = userSelected;
				const { id: locationID, setName: locationName } = locations;
				return {
					id: +locationID,
					text: `(${locationName})`
				};
			} else {
				return {
					id: 0,
					text: 'Off'
				};
			}
		},
		checkDisableSaveItem(item) {
			if (item) {
				const { category, activity } = item;
				if (_.isNil(category) || +category === 0 || _.isNil(activity)) {
					return true;
				}
				return false;
			}
			return false;
		},
		setNameRubric(short, name) {
			const text = `${short ? short : ''} - ${name}`;
			if (text.length > 30) {
				return text.substr(0, 27) + ' ...';
			}
			return text;
		},
		showTooltipTextRubric(node) {
			const item = node.raw;
			if (item) {
				const { short, name } = item;
				const text = `${short ? short : ''} - ${name}`;
				return text;
			}
		},
		showNameRubricSelected(index) {
			const data = this.dataList[index];
			if (data) {
				const { flagItemName } = data;
				const text = flagItemName;
				return text || this.FormMSG(400, 'None');
			}
			return this.FormMSG(400, 'None');
		},
		getNameRubric(node) {
			const item = node.raw;
			if (item) {
				const { short, name } = item;
				const text = this.setNameRubric(short, name);
				return text;
			}
		},
		isExistEqualActivitiesToolTip(isEqual) {
			return isEqual
				? this.FormMSG(457, 'On this line, the activities are the same')
				: this.FormMSG(458, 'On this line, the activities are not the same');
		},
		isExistEqualQuantityToolTip(isEqual) {
			return isEqual ? this.FormMSG(4887, 'On this line, the quantity are the same') : this.FormMSG(4888, 'On this line, the quantity are not the same');
		},
		isExistEqualCategoriesToolTip(isEqual) {
			return isEqual
				? this.FormMSG(455, 'On this line, the categories are the same')
				: this.FormMSG(456, 'On this line, the categories are not the same');
		},
		isExistEqualAmountToolTip(isEqual) {
			return isEqual ? this.FormMSG(453, 'On this line, the amounts are the same') : this.FormMSG(454, 'On this line, the amounts are not the same');
		},
		checkEqualActivities(item) {
			if (item) {
				if (item.dataInput.length === 1) {
					return false;
				} else {
					let initActivities = item.activityName;
					let checkAllActivities = item.dataInput.every((item) => item.activityName === initActivities);
					return checkAllActivities;
				}
			}
			return false;
		},
		checkEqualCategories(item) {
			if (item) {
				if (item.dataInput.length === 1) {
					return false;
				} else {
					let initCategories = item.category;
					let checkAllCategories = item.dataInput.every((item) => item.category === initCategories);
					return checkAllCategories;
				}
			}
			return false;
		},
		checkEqualAmount(item) {
			if (item) {
				if (item.dataInput.length === 1) {
					return false;
				} else {
					let initAmount = +item.amount;
					let checkAllAmount = item.dataInput.every((item) => item.amount === initAmount);
					return checkAllAmount;
				}
			}
			return false;
		},
		checkEqualQuantity(item) {
			if (item) {
				if (item.dataInput.length === 1) {
					return false;
				} else {
					let initQtt = +item.quantity;
					let checkAllQtt = item.dataInput.every((item) => item.quantity === initQtt);
					return checkAllQtt;
				}
			}
			return false;
		},
		handleShowMadCategories() {
			this.showAllMapCategorie = !this.showAllMapCategorie;
		},
		getQuantity(item) {
			if (!_.isNil(item)) {
				const response = this.getCurrency(item).toString();
				const checkAmount = response.includes('x');
				if (checkAmount) {
					return response.split('.')[0];
				}
				return item.quantity.toString();
			}
		},
		getLocationName(item, index) {
			if (!_.isNil(item)) {
				if (item.locations && item.locations.setName !== undefined && item.locations.setName.length !== 0) {
					return item.locations.setName;
				}
				{
					let id = item.locations;
					let _locations = this.locationList.find((item) => +item.id === +id);
					if (_locations !== undefined && this.dataList[index] && this.dataList[index].locations) {
						this.dataList[index].locations = { ..._locations };
						return _locations.setName;
					}
					return 'Off';
				}
			}
		},
		setTextCategoriesWithActivities(text, index) {
			if (this.dataList[index] !== undefined && this.dataList[index].activity !== null) {
				let short = this.dataList[index].activity.short;
				let textExact = text.toString().replace('$', short);
				return textExact;
			}
			return text;
		},
		handleSelectedCategory(payload, index) {
			this.dataList[index].labelCategory = payload.label;
		},
		isManagerSalaryAccess(item) {
			if (item) {
				const { hideSalary } = item;
				return this.isRegissor ? hideSalary : false;
			}
			return false;
		},
		rendAmountCurrency(hideSalary, amount) {
			if (!hideSalary) {
				return amount;
			} else {
				if (!this.isRegissor) {
					return amount;
				}
				const unite = `${amount}`.replaceAll('K', '').split(' ')[1];
				return `Xx.xx ${unite}`;
			}
		},
		isSolveAmount(index) {
			const newItem = this.dataList[index];
			if (this.isRegissor) {
				return false;
			} else {
				if (newItem && newItem.dataInput) {
					const { dataInput } = newItem;
					if (dataInput) {
						const isNeedToSolve = dataInput.some(({ amount }) => amount === 0);
						return isNeedToSolve;
					}
					return false;
				}
				return false;
			}
		},
		isSolveText(text) {
			if (this.isRegissor) {
				return false;
			} else {
				if (text && !_.isNil(text)) {
					return false;
				}
				return true;
			}
		},
		getCurrency(item) {
			const { amount, hideSalary } = item;
			const amountTotal = rendCurrency(parseFloat(amount), 3);
			return this.rendAmountCurrency(hideSalary, amountTotal);
		},
		async handleOptionSelected(payload, index) {
			if (!this.isMultipleEdit) {
				const { userId: userID } = this.userSelected;
				const usersId = [userID];
				const flagsId = [+payload.id];
				const result = await getUsersAndFlagsValue(usersId, flagsId);
				const { managerSalaryAccess, projectFlagItem } = result[0];
				const { groupingCategory, amount: amountFlag } = projectFlagItem[0];
				let isHideSalary = false;
				const checkIsHideSalaryType = managerSalaryAccess.find(
					({ managerID, salaryType }) => +managerID === +usersId[0] && +salaryType === +groupingCategory
				);
				if (!_.isNil(checkIsHideSalaryType)) {
					isHideSalary = true;
				}
				this.dataList[index].rubric = +payload.id;
				this.dataList[index].amount = +amountFlag;
				this.dataList[index].flagItemFlagId = +payload.flagId;
				this.dataList[index].flagItemValue = +payload.value;
				this.dataList[index].flagItemName = payload.short + ' - ' + payload.name;
				this.dataList[index].hideSalary = isHideSalary;
			} else {
				this.dataList[index].rubric = +payload.id;
				this.dataList[index].flagItemFlagId = +payload.flagId;
				this.dataList[index].flagItemValue = +payload.value;
				this.dataList[index].flagItemName = payload.short + ' - ' + payload.name;
			}
		},

		async getActivityFlagItems() {
			const result = await getProjectFlagItems([1]);
			this.activityOptions = [...result.projectFlagItems].map((item) => ({ ...item, label: item.name }));
		},
		async getProjectFlagItems() {
			const result = await getProjectFlagItems([4]);

			this.flagItemsOptions = [...result.projectFlagItems].map((item) => ({ ...item, label: item.name }));
		},
		async getCategories() {
			await getBudgetHierarchicalCategoryJson(-2, true, null, -1, false).then((result) => {
				this.categories = removeAttributeTree(result, 'children', null);
			});
		},
		handleClickToEdit(index) {
			this.dataList[index].isEdit = true;
			if (+this.dataList[index].locations.id === 0) {
				this.dataList[index].locations = { ...this.dataList[index].locations, setName: 'Off' };
			}
		},
		handleClickEditClose(value, index) {
			let _item = this.globalDataList.find((_item) => +_item.id === +value.id);
			if (_.isNil(_item)) {
				this.handleClickRemove(index, false);
			} else {
				this.dataList = this.dataList.map((item) => {
					if (_item && +item.id === +_item.id) {
						return { ..._item, isEdit: false };
					} else {
						return item;
					}
				});
			}
		},
		handleClickEdit(index) {
			const isAuth = this.isRegissor ? true : this.$v.dataList.$each.$iter[index].$invalid === false;
			if (isAuth) {
				const action = async () => {
					const newItem = this.dataList[index];
					const { activity, category, quantity, amount, locations, id: newID } = newItem;
					const mapActivity = () => {
						// initialize map activity
						const item = activity;
						if (item) {
							const { id: activityID, short, name, flagId, value } = activity;
							const activityName = +activityID > 0 ? `${short} - ${name}` : '';
							return {
								activityName,
								flagIdOne: flagId,
								valueOne: value
							};
						} else {
							return {
								activityName: null,
								flagIdOne: null,
								valueOne: null
							};
						}
					};
					const locationId = +locations.id;
					const newInputItem = {
						tsDayId: 0,
						activity,
						category,
						quantity: +quantity,
						location: locations,
						locationId,
						amount: +amount,
						flagId: +newItem.flagItemFlagId,
						...mapActivity(),
						value: newItem.flagItemValue
					};
					const newTsDayDetailID = [...new Set(newItem.dataInput.map(({ id }) => +id))];
					if (this.newRubricsIndex.includes(index)) {
						this.dataList[index].isEdit = false;
						let result = false;
						this.activeChangeAction = true;
						result = await addUpdTsDayDetails([], this.newGlobalTsDayID, newInputItem).then((res) => {
							const newRes = !!Object.keys(res).length;
							if (newRes) {
								const { id: geneareteNewID } = res;
								this.dataList[index] = { ...this.dataList[index], id: +geneareteNewID };
								this.dataList = this.dataList.map((item) => {
									if (+item.id === +geneareteNewID) {
										return { ...item, id: +geneareteNewID };
									}
									return item;
								});
								return newRes;
							}
							return false;
						});
						if (result) {
							this.createToastForMobile(this.FormMSG(110, 'Success'), this.FormMSG(111, 'Update successfully!'));
						} else {
							this.$toast({
								message: this.FormMSG(111, 'Error to update'),
								title: this.FormMSG(112, 'Error'),
								variant: 'danger'
							});
						}
					} else {
						this.dataList[index].isEdit = false;
						let id = +this.dataList[index].locations.id;
						let isTrouve = this.locationList.find((item) => +item.id === +id);
						if (isTrouve !== undefined) {
							this.dataList[index].locations = { ...isTrouve };
							let _item = this.dataList[index];
							this.globalDataList = this.globalDataList.map((item) => {
								if (+item.id === +_item.id) {
									return { ..._item, isEdit: false };
								}
								return item;
							});
						} else {
							this.dataList[index] = { ...this.dataList[index], id: '0' };
							let _item = this.dataList[index];
							this.globalDataList = this.globalDataList.map((item) => {
								if (+item.id === +_item.id) {
									return { ..._item, isEdit: false };
								}
								return item;
							});
						}
						let result = false;
						if (this.isMultipleEdit === false) {
							this.activeChangeAction = true;
							result = await addUpdTsDayDetails(newTsDayDetailID, this.newGlobalTsDayID, newInputItem);
						} else {
							this.activeChangeAction = true;
							result = await updateMultipleTsdayDetails(newTsDayDetailID, newInputItem);
						}

						if (result) {
							const newDataInput = newItem.dataInput.map((item) => ({ ...item, amount: +amount }));
							const generateNewItem = {
								...newItem,
								amount: +amount,
								dataInput: newDataInput
							};
							this.dataList = this.dataList.map((item, newIndex) => {
								if (newIndex === index) return generateNewItem;
								return item;
							});
							this.createToastForMobile(this.FormMSG(110, 'Success'), this.FormMSG(111, 'Update successfully!'));
						}
					}
				};
				this.confirmModal(action, this.FormMSG(112, 'Are you sure ?'));
			}
		},
		async handleClickRemove(index, isBoolean) {
			const { FOR_MANAGER, FOR_LVL_ONE } = TYPE_ASK_TO_DELETE;
			const { MANAGER } = PLANNING_LEVEL;
			const decreases = isBoolean ? 2 : 0;
			const item = this.dataList[index];
			const { isUndo } = this.actionTypeRowRemove(item);
			if (+this.dataList[index].id === 0) {
				this.textNewRubric = this.FormMSG(460, ' of new rubric, because the item is not yet saved');
			} else {
				this.textNewRubric = '';
			}
			const action = async () => {
				if (this.newRubricsIndex.includes(index)) {
					this.newRubricsIndex.splice(index, 1);
					this.dataList.splice(index, 1);
				} else {
					const { isUndo } = await this.deleteItemRow(item, isBoolean);
					if (this.isSendToPaie && !this.isGestionnaryOfPaie) {
						if (isUndo) {
							const newAskToDelete = this.currentValidationLevel === MANAGER ? FOR_MANAGER - 1 - decreases : FOR_LVL_ONE - 1 - decreases;
							const newItem = {
								...this.dataList[index],
								askToDelete: newAskToDelete
							};
							this.dataList = this.dataList.map((item) => {
								if (newItem.id === item.id) return newItem;
								return item;
							});
						} else {
							const newAskToDelete = this.currentValidationLevel === MANAGER ? FOR_MANAGER - decreases : FOR_LVL_ONE - decreases;
							const newItem = {
								...this.dataList[index],
								askToDelete: newAskToDelete
							};
							this.dataList = this.dataList.map((item) => {
								if (newItem.id === item.id) return newItem;
								return item;
							});
						}
					} else {
						let item = this.dataList[index];
						this.dataList.splice(index, 1);
						if (this.isGestionnaryOfPaie === false) {
							this.itemToAskDelete = [...this.itemToAskDelete, item];
						}
					}
				}
				if (this.dataList.length === 0) {
					this.emitEventClose();
					this.textNewRubric = '';
				}
			};
			let newMessage = '';
			if (!isBoolean) {
				if (this.isSendToPaie && !this.isGestionnaryOfPaie) {
					newMessage = isUndo
						? this.FormMSG(510, 'Please confirm you want to undo the item')
						: this.FormMSG(511, 'Please confirm you want to change to ask to delete the item');
				} else {
					newMessage = this.FormMSG(11302, 'Please confirm you want to remove the item');
				}
			} else {
				newMessage = this.FormMSG(506, 'Are you sure to refuse the item ?');
			}
			this.confirmModal(action, newMessage + this.textNewRubric);
		},
		handleClickAddRubric() {
			const newGenerateDataInput = this.dataList.filter(({ isEdit }) => !isEdit)[0];
			const { dataInput } = newGenerateDataInput;
			const newDataInput = dataInput ? dataInput : [newGenerateDataInput];
			const newRubric = {
				id: 0,
				rubric: null,
				amount: 0,
				activity: null,
				activityName: '',
				labelCategory: '',
				locations: null,
				paycode: null,
				category: null,
				quantity: 1,
				isEdit: true,
				labelCategory: '',
				projectFlagItem: null,
				hideSalary: false,
				dataInput: newDataInput
			};

			this.dataList.push(newRubric);
			this.newRubricsIndex.push(this.dataList.length - 1);
		},
		actionTypeRowRemove(item) {
			const { DEFAULT_VALUE, VALIDATED_MANAGER, VALIDATED_LVL_ONE, REJECTED_LVL_ONE, REJECTED_LVL_TWO } = ROW_ASK_TO_DELETE;
			const { MANAGER, CHARG_PROD } = PLANNING_LEVEL;
			const currentValidationLevel = this.currentValidationLevel;
			if (item) {
				if (this.isSendToPaie) {
					const { askToDelete: status } = item;
					const isNoAction = status === DEFAULT_VALUE;
					const mapAuthLevel = (level) => {
						const cond = {
							[MANAGER]: [VALIDATED_MANAGER],
							[CHARG_PROD]: [VALIDATED_LVL_ONE]
						};
						const isAuthRemove = cond[level] ? cond[level].includes(status) : null;
						const option = {
							isRemove: isAuthRemove ? !isAuthRemove : true,
							isUndo: isAuthRemove ? isAuthRemove : false
						};
						return option;
					};
					if (this.isGestionnaryOfPaie) {
						const isAuthRemove = [VALIDATED_MANAGER, VALIDATED_LVL_ONE, REJECTED_LVL_ONE, REJECTED_LVL_TWO].includes(status); // if action charg prod active
						const option = {
							isRemove: isNoAction ? true : isAuthRemove,
							isUndo: false
						};
						return option;
					}
					return mapAuthLevel(currentValidationLevel);
				}
				return {
					isRemove: true,
					isUndo: false
				};
			}
			return { isRemove: true, isUndo: false };
		},
		iconRemoveType(item) {
			if (item) {
				const currentValidationLevel = this.currentValidationLevel;
				const { MANAGER, CHARG_PROD } = PLANNING_LEVEL;
				const { VALIDATED_MANAGER, REJECTED_LVL_ONE, REJECTED_LVL_TWO } = ROW_ASK_TO_DELETE;
				const { askToDelete, id } = item;
				const isAskFromManager = [VALIDATED_MANAGER].includes(askToDelete);
				const isRefused = [REJECTED_LVL_ONE, REJECTED_LVL_TWO].includes(askToDelete);
				const defaultOptions = {
					icon: this.ICONS.TRASH.name,
					color: this.ICONS.TRASH.color,
					accept: false,
					text: this.FormMSG(508, 'Remove'),
					isRefused
				};
				if (isRefused) {
					return {
						icon: this.ICONS.ALERT_TRIANGLE.name,
						color: this.ICONS.ALERT_TRIANGLE.color,
						accept: false,
						text: this.FormMSG(508, 'Remove'),
						isRefused
					};
				}
				if (this.isGestionnaryOfPaie) {
					return defaultOptions;
				} else {
					if (this.isSendToPaie) {
						const options =
							currentValidationLevel === MANAGER
								? { icon: this.ICONS.X_CIRCLE.name, color: this.ICONS.X_CIRCLE.color, accept: false, text: this.FormMSG(508, 'Remove') }
								: currentValidationLevel === CHARG_PROD
								? {
										icon: isAskFromManager ? this.ICONS.CHECK_CIRCLE.name : this.ICONS.X_CIRCLE.name,
										color: isAskFromManager ? this.ICONS.CHECK_CIRCLE.color : this.ICONS.X_CIRCLE.color,
										accept: isAskFromManager,
										text: isAskFromManager ? this.FormMSG(509, 'Valid') : this.FormMSG(508, 'Remove'),
										isRefused
								  }
								: defaultOptions;
						return !id ? defaultOptions : options;
					}
					return defaultOptions;
				}
			}
			return {
				icon: this.ICONS.TRASH.name,
				color: this.ICONS.TRASH.color,
				accept: false,
				text: this.FormMSG(508, 'Remove'),
				isRefused
			};
		},
		handleContentRemoveTooltip(item, boolean) {
			const isRefused = this.iconRemoveType(item).isRefused;
			if (isRefused) {
				return this.FormMSG(512, 'Item was refused, please click to ask to delete');
			}
			if (boolean) {
				const { isUndo } = this.actionTypeRowRemove(item);
				const currentValidationLevel = this.currentValidationLevel;
				const { MANAGER, CHARG_PROD, ADM_PROD } = PLANNING_LEVEL;
				const { VALIDATED_MANAGER } = ROW_ASK_TO_DELETE;
				const { askToDelete } = item;
				const isAskFromManager = [VALIDATED_MANAGER].includes(askToDelete);
				const messages = {
					[MANAGER]: [501, 'Remove to set Ask for delete'],
					[CHARG_PROD]: isAskFromManager ? [504, 'Valid ask for delete by Manager'] : [501, 'Remove to set Ask for delete'],
					[ADM_PROD]: [501, 'Remove to set Ask for delete']
				};
				if (isUndo) {
					return this.FormMSG(500, 'Undo');
				}
				return this.isSendToPaie && !this.isGestionnaryOfPaie ? this.FormMSG(...messages[currentValidationLevel]) : this.FormMSG(502, 'Remove');
			}
			return this.isGestionnaryOfPaie ? this.FormMSG(518, 'Delete') : this.FormMSG(513, 'Change to ask to delete');
		},
		async deleteItemRow(item, isBoolean) {
			const id = +item.id;
			this.activeChangeAction = true;
			const result = await delTsdayDetails(id, isBoolean);
			const option = this.actionTypeRowRemove(item);
			if (result) {
				const message =
					this.isSendToPaie && !this.isGestionnaryOfPaie
						? this.FormMSG(503, 'item selected change to ask for delete')
						: this.FormMSG(114, 'Data remove successfully!');
				this.createToastForMobile(this.FormMSG(110, 'Success'), message);
				this.textNewRubric = '';
				return option;
			} else {
				this.$toast({
					message: this.FormMSG(369, 'Error to delete'),
					title: this.FormMSG(370, 'Error'),
					variant: 'danger'
				});
				return option;
			}
		},
		prepareInputDataToSend() {
			let result = [];

			this.dataList.map((option) => {
				if (this.isRegissor === false) {
					result.push({
						tsDayId: +this.externalData.tsDay.id,
						flagIdOne: +option.activity.flagId,
						valueOne: +option.activity.value,
						flagId: +option.flagItemFlagId,
						value: +option.flagItemValue,
						amount: +option.amount,
						category: +option.category,
						quantity: +option.quantity,
						typeOne: +option.typeOne,
						locationId: +option.locations.id
					});
				} else if (this.isRegissor === true) {
					result.push({
						tsDayId: +this.externalData.tsDay.id,
						flagId: +option.flagItemFlagId,
						value: +option.flagItemValue,
						paycode: option.paycode === NaN ? null : +option.paycode,
						flagIdOne: option.activity !== null ? +option.activity.flagId : null,
						valueOne: option.activity !== null ? +option.activity.value : null,
						amount: +option.amount,
						category: +option.category,
						quantity: +option.quantity,
						typeOne: +option.typeOne,
						locationId: +option.locations.id
					});
				}
			});

			return result;
		},
		closeModalShowInfo() {
			this.showInfoEdit = false;
		},
		closeAllModal() {
			this.showInfoEdit = false;
			this.emitEventClose();
		},
		closeModal() {
			if (this.haveAnEdit) {
				this.showInfoEdit = true;
				return true;
			} else {
				return this.emitEventClose();
			}
		},
		emitEventClose() {
			this.dataList = this.dataList.map((item) => ({ ...item, isEdit: false }));
			const isAllClose = this.dataList.every(({ isEdit }) => !isEdit);
			if (isAllClose) {
				this.$emit('user-advanced-edit:close', { activeChangeAction: this.activeChangeAction });
				this.itemToAskDelete = [];
				this.resetData();
			}
		},
		resetData() {
			this.userSelected = null;
			this.newRubricsIndex = [];
			this.loadingSubmit = false;
			this.activeChangeAction = false;
		}
	}
};
</script>
<style lang="scss">
.btn-category {
	display: flex;
	align-items: center;
	justify-content: center;
	margin-left: -5px;
	border-radius: 0px 4px 4px 0px !important;
	border: none;
	background-color: #cad0d7;
	color: #4d4e4f;
	font-weight: 700;
}
.content-btn-category {
	padding-left: 10px;
}
.w-70 {
	width: 70% !important;
}
.w-30 {
	width: 30% !important;
}
.w-25 {
	width: 25% !important;
}
.w-13 {
	width: 13% !important;
}
.w-10 {
	width: 10% !important;
}
.dialog-custom {
	max-width: 90% !important;
}
.gap-1 {
	gap: 10px;
}
.text-default {
	color: rgba(6, 38, 62, 0.84);
	font-weight: 900;
	font-size: 0.95rem;
}
.ask-to-delete {
	color: #ea4e2c;
	font-weight: 700;
}
.dialog-custom-info {
	max-width: 25% !important;
}
.bars-5 {
	width: 16px;
	height: 6px;
	aspect-ratio: 0.75;
	--c: no-repeat linear-gradient(rgba(6, 38, 62, 0.84) 0 0);
	background: var(--c) 0% 100%, var(--c) 50% 100%, var(--c) 100% 100%;
	background-size: 20% 65%;
	animation: b5 1s infinite linear;
}
@keyframes b5 {
	20% {
		background-position: 0% 50%, 50% 100%, 100% 100%;
	}
	40% {
		background-position: 0% 0%, 50% 50%, 100% 100%;
	}
	60% {
		background-position: 0% 100%, 50% 0%, 100% 50%;
	}
	80% {
		background-position: 0% 100%, 50% 100%, 100% 0%;
	}
}
.is-invalid-treeselect {
	border: 1px solid #ea4e2c;
	border-radius: 5px;
}
@mixin text-position {
	display: flex;
	border: 1.8px solid #ea4e2c;
	width: 100%;
	align-items: center;
	padding: 4px;
	border-radius: 4px;
	height: 30px;
}
.bg-solve-amount {
	@include text-position();
	justify-content: end;
}
.bg-solve-text {
	@include text-position();
	justify-content: start;
}
.margin-l-30 {
	margin-left: 30px;
}
.text-ellipsis {
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
	width: auto;
}

$isLight: #ea4e2c;
$backgroundColrIsLight: #fcede9;
$isNotLight: #e7f2ff;
$backgroundColrIsNotLight: #6c98e2;

@mixin default-option {
	width: 10px;
	height: 10px;
	border-radius: 50%;
}

@mixin sonar-mixin-after($color) {
	background-color: $color;
	animation: sonar 1.5s infinite;
}

@mixin light-animation($color, $backgroundColor) {
	@include default-option;
	background-color: $backgroundColor;
	border: 1px solid $color;
	animation: pulse 1.5s infinite;
}
.rounded-sonar-light-animation {
	@include light-animation($isLight, $backgroundColrIsLight);
	&:after {
		@include default-option;
		@include sonar-mixin-after($isLight);
	}
}

@keyframes sonar {
	0% {
		transform: scale(0.6);
		opacity: 1;
	}
	100% {
		transform: scale(1.2);
		opacity: 0;
	}
}

@keyframes pulse {
	0% {
		transform: scale(1);
	}
	20% {
		transform: scale(0.9);
	}
	50% {
		transform: scale(1);
	}
	80% {
		transform: scale(0.6);
	}
	100% {
		transform: scale(1);
	}
}
tr {
	height: 40px;
}
.border-refuse {
	display: flex;
	width: 30px;
	height: 25px;
	border: 3px solid #ea4e2c !important;
	border-radius: 5px;
	align-items: center;
	justify-content: center;
	&:hover {
		border: 3px solid #ea4e2c !important;
	}
}
</style>
