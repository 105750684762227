var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: {
        title:
          _vm.FormMSG(1, "Information") + ": " + _vm.formattedDate(_vm.date),
        size: "md",
        "hide-header-close": "",
        "no-close-on-backdrop": "",
        "no-close-on-esc": "",
        "modal-class": "mui-animation",
        fade: false,
      },
      on: {
        cancel: function ($event) {
          $event.preventDefault()
          return _vm.emitEventClose.apply(null, arguments)
        },
        hidden: _vm.emitEventClose,
      },
      scopedSlots: _vm._u([
        {
          key: "modal-footer",
          fn: function () {
            return [
              _c(
                "div",
                {
                  staticClass:
                    "w-100 d-flex justify-content-end align-items-center",
                },
                [
                  _c(
                    "b-button",
                    {
                      staticClass: "d-flex w-auto w-138-px",
                      attrs: { size: "sm", variant: "light", block: "" },
                      on: { click: _vm.emitEventClose },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "d-flex justify-content-end align-items-center",
                        },
                        [
                          _c("div", { staticStyle: { "margin-top": "1px" } }, [
                            _vm._v(_vm._s(_vm.FormMSG(2, "Close"))),
                          ]),
                        ]
                      ),
                    ]
                  ),
                ],
                1
              ),
            ]
          },
          proxy: true,
        },
      ]),
      model: {
        value: _vm.open,
        callback: function ($$v) {
          _vm.open = $$v
        },
        expression: "open",
      },
    },
    [
      _c(
        "div",
        { staticClass: "d-flex flex-column gap-1" },
        [
          _c(
            "b-alert",
            { attrs: { show: "", variant: "warning", dismissible: "" } },
            [
              _vm._v(
                _vm._s(
                  _vm.FormMSG(
                    3,
                    "Some days are incomplete and need to be finalized"
                  )
                )
              ),
            ]
          ),
          _vm._l(_vm.groupByData, function (item, index) {
            return _vm.groupByData.length !== 0
              ? _c(
                  "div",
                  {
                    key: index,
                    staticClass:
                      "d-flex flex-row align-items-center w-100 border-rd p-2",
                  },
                  [
                    _c("div", { staticClass: "w-50" }, [
                      _c("label", [_vm._v(_vm._s(item.fullName))]),
                    ]),
                    _c(
                      "div",
                      { staticClass: "d-flex flex-column border-lf w-50" },
                      _vm._l(item.data, function (children, index) {
                        return _c(
                          "div",
                          { key: index, staticClass: "text-1" },
                          [
                            _vm._v(
                              _vm._s(_vm.formatDatePlanning(children.date))
                            ),
                          ]
                        )
                      }),
                      0
                    ),
                  ]
                )
              : _vm._e()
          }),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }